import axios from "axios";

class SireneDataService {

    extractDataFromRecord(record){
        const r = {
            siren : record.fields.siren,
            siret : record.fields.siret,
            nom : "",
            nomCommercial : (record?.fields?.denominationusuelleetablissement) ? record.fields.denominationusuelleetablissement  : "",
            ville: record.fields.libellecommuneetablissement,
            activite: record.fields.soussectionunitelegale,
            adresse: record.fields.adresseetablissement,
            codePostal: record.fields.codepostaletablissement,

        }
        if (record.fields?.categoriejuridiqueunitelegale === "1000") {
            r.nom += record?.fields.l1_adressage_unitelegale
        } else {
            if (record?.fields.denominationunitelegale) {
                r.nom += record?.fields.denominationunitelegale
            }
        }
        return r;
    }

    getInstance() {
        const token = "8aefe97d-99fa-316b-9c11-b9dcfca278fd";  // votre jeton d'accès entre guillemets
        const instance = axios.create({
            baseURL: 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&rows=30&q=',
            timeout: 5000,
          });
          return instance;
    }

   
    async getEtablissementsBySiren(query) {
        
        
        return this.getInstance().get(encodeURI(query))
       }
    async getSieges(query) {
        
        
        return this.getInstance().get(encodeURI(query + '  AND etablissementsiege:oui AND etatadministratifetablissement:actif'))
       }


}


export default new SireneDataService();