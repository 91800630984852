import React, { useState } from "react";

import {  useOutletContext, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CommandeProvider from "../../contexts/CommandeProvider";
import BonDeCommande from "../../components/bonDeCommande";

export default function CommandeEdit() {

    const {client,brasserie,catalogue,contact,incomplet} = useOutletContext()
    const { idCommande } = useParams();
   

    return <><Helmet title={"Commande : "+idCommande} />
        <CommandeProvider initialState={{
                    client: client,
                    catalogue: catalogue,
                    commande: null,
                    filters: { conditionnements: {}, catalogues: {} },
                    loading: false,
                    error: null,
                    brasserie: brasserie
                }}>
                    <BonDeCommande id={idCommande} />
                </CommandeProvider>
    </>
   
}
