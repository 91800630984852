// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDXDLg9rFDl3F93LjruVWxvSv4JaxnEoKY",
  authDomain: "lpla-crm.firebaseapp.com",
  databaseURL: "https://lpla-crm-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lpla-crm",
  storageBucket: "lpla-crm.appspot.com",
  messagingSenderId: "340254346632",
  appId: "1:340254346632:web:afce7d06465cd0b28e3e3d",
  measurementId: "G-JFZFD14ZX1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
const analytics = getAnalytics(app);