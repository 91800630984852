
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsTreemap from 'highcharts/modules/treemap';
import HighchartsMore from 'highcharts/highcharts-more';
import _ from "lodash";
import { fi, it } from 'date-fns/locale';

HighchartsSunburst(Highcharts);
HighchartsMore(Highcharts);
HighchartsTreemap(Highcharts);



export function createDataSeries(data, brasserie, produits, clients) {
    const zeroLevel = [{
        name: brasserie.nom,
        id: brasserie.id,
        color: "#fff",
    }]
    const firstLevel = []

    const groupedTournees = [ "nontron", "thiviers", "lanouaille","brantome","limoges","dordogne"]

    console.log("brasserie tournees", brasserie.tournees)
    console.log("brasserie catalogues", brasserie.catalogues)
    console.log("brasserie conditionnements", brasserie.conditionnements)
    console.log("clients", clients)

    const isTheTourneeGrouped = (tournee) => {
        if (groupedTournees.includes(tournee)) {
            return true
        }
        return false
    }



    //fill first level with tournees. tournees is a collection of tournee. the key is the id of the tournee and the nom will be the name of the tournee
    for (const [key, value] of Object.entries(brasserie.tournees)) {
        if (isTheTourneeGrouped(key)) {
            
        } else {

            firstLevel.push({
                name: value.nom,
                id: key,
                parent: brasserie.id,
            })
        }
    }
    //add a fake tournee for clients without tournee
    firstLevel.push({
        name: "Sans tournee",
        id: "Sans tournee",
        parent: brasserie.id,
    })
    //add a fake tournee for grouped tournees if there is a grouped tournee
    if (groupedTournees.length > 0) {
        firstLevel.push({
            name: "Tournees groupées",
            id: "Tournees groupées",
            parent: brasserie.id,
        })
    }



    const secondLevel = []
    //fill second level with catalogues. catalogues is a collection of catalogue. the key is the id of the catalogue and the nom will be the name of the catalogue. All catalogues need to be added to each tournee
    for (const [key, value] of Object.entries(brasserie.catalogues)) {
        firstLevel.forEach((tournee) => {
            secondLevel.push({
                name: value.nom,
                id: key + "" + tournee.id,
                parent: tournee.id,
            })
        })
    }


    const thirdLevel = []
    console.log("data", data)
    //fill third levels with sells data from "data". 
    //data is a collection of sell. 
    //we can retrieve the client's tournee with clients[sell.clientId].tourneeId. 
    //then we can retrieve the catalogue with the catalogue's name set in sell.catalogue
    //but first we should clean the data from sells where quantity is 0
    data = data.filter((item) => item.quantite > 0)

    //we can now fill the third level
    for (let index = 0; index < data.length; index++) {
        const item = data[index];
        const client = clients.filter((client) => client.id === item.clientId)[0]
        const clientTournee = (client.tournee) ? (isTheTourneeGrouped(client.tournee) ? "Tournees groupées" : client.tournee) : "Expedition"
        var tournee

        //manage if the client has no tournee
        if (clientTournee === "Expedition") {
            tournee = {
                nom: "Expedition",
                id: "Expedition"
            }
        } else {
            if (isTheTourneeGrouped(clientTournee)) {
                tournee = {
                    nom: "Tournees groupées",
                    id: "Tournees groupées"
                }
            } else {

                tournee = brasserie.tournees[clientTournee]
            }
        }

        const CA = item.CA
        const quantite = item.quantite
        const volume = item.volume
        const marge = item.marge
        const catalogueName = item.catalogue

        const conditionnementName = item.conditionnementName

        if (!firstLevel.find((tournee) => tournee.id === clientTournee) && !isTheTourneeGrouped(clientTournee)) {
            firstLevel.push({
                name: tournee.nom,
                id: clientTournee,
                parent: brasserie.id
            })
            secondLevel.push({
                name: catalogueName,
                id: catalogueName + "" + clientTournee,
                parent: clientTournee
            })
            thirdLevel.push({
                name: conditionnementName,
                id: conditionnementName + "" + catalogueName + "" + clientTournee,
                parent: catalogueName + "" + clientTournee,
                value: volume
            })
        } else {
            if (!secondLevel.find((catalogue) => catalogue.id === catalogueName + "" + clientTournee)) {
                secondLevel.push({
                    name: catalogueName,
                    id: catalogueName + "" + clientTournee,
                    parent: clientTournee
                })
                thirdLevel.push({
                    name: conditionnementName,
                    id: conditionnementName + "" + catalogueName + "" + clientTournee,
                    parent: catalogueName + "" + clientTournee,
                    value: volume
                })
            } else {
                const thirdLevelItem = thirdLevel.find((conditionnement) => (conditionnement.id === conditionnementName + "" + catalogueName + "" + clientTournee && conditionnement.parent === catalogueName + "" + clientTournee))
                if (!thirdLevelItem) {
                    thirdLevel.push({
                        name: conditionnementName,
                        id: conditionnementName + "" + catalogueName + "" + clientTournee,
                        parent: catalogueName + "" + clientTournee,
                        value: volume
                    })
                } else {
                    thirdLevelItem.value += volume
                }
            }
        }
    }

    return _.concat(zeroLevel, firstLevel.sort((a, b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)), secondLevel, thirdLevel)

}

export function getOptions(data, brasserie, produits, clients) {
    const series = createDataSeries(data, brasserie, produits, clients)
    console.log("series", series)
    return {
        chart: {
            type: 'treemap',
            height: '100%',
            outerWidth: '100%',
            innerWidth: '100%',
        },
        title: {
            text: 'Répartition du stock'
        },
        series: [{
            type: 'treemap',
            data: series,
            name: 'Root',
            allowDrillToNode: true,
            cursor: 'pointer',
            dataLabels: {
                format: '{point.name}',
                filter: {
                    property: 'innerArcLength',
                    operator: '>',
                    value: 16
                },
                rotationMode: 'circular'
            },
            levels: [{
                level: 1,
                levelIsConstant: false,
             /*    dataLabels: {
                    filter: {
                        property: 'outerArcLength',
                        operator: '>',
                        value: 64
                    }
                } */
            }, {
                level: 2,
                colorByPoint: true
            },
            {
                level: 3,
                colorVariation: {
                    key: 'brightness',
                    to: -0.5
                }
            }, {
                level: 4,
                colorVariation: {
                    key: 'brightness',
                    to: 0.5
                }
            }]

        }],
        tooltip: {
            headerFormat: '',
            valueDecimals: 2,
            pointFormat: '{point.name}: <b>{point.value}l</b>'
        }

    }
}

export function VenteParTourneeCatalogue({ brasserie, produits, data, clients }) {

    /* const chart = getOptions(data, brasserie, produits, clients)
    return <div><pre></pre></div> */

    const chart = <HighchartsReact highcharts={Highcharts} options={getOptions(data, brasserie, produits, clients)} allowChartUpdate={false} />
    return chart
}