import React from "react";
import { Link, useOutletContext, useParams } from "react-router-dom";
import _ from "lodash";
import productService from "../../services/product.service";
import { format, fromUnixTime } from 'date-fns'
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";


export default function Catalogue(props) {

    console.log('catalogue page', props)

    const { client, brasserie, catalogue } = useOutletContext()
    const params = useParams()

   /*  const catalogues = _.mapValues(brasserie.catalogues, () => { return [] })

    for (let index = 0; index < catalogue.length; index++) {
        const produit = catalogue[index];
        for (const stock in produit.stocks) {

            const catalogueName = produit.stocks[stock].catalogue
            if (Object.hasOwnProperty.call(catalogues, catalogueName)) {

                catalogues[catalogueName].push(produit)

            } else {
                catalogues[catalogueName] = [produit]
            }
        }
    } */
    if (catalogue) {
        const catalogues = catalogue.getProductListOrgannizedByCatalogueName()
        console.log('catalogues', catalogues)
        return <>
            <Helmet title="Catalogue" />
            <Row>
                <Col sm={12}>
                    {Object.keys(catalogues).map(catalogueName => {
                        if (catalogues[catalogueName].length > 0) {
                            const products = _.uniq(catalogues[catalogueName]).sort((a, b) => {
                                return a.abv - b.abv
                            })
                            return <div key={catalogueName} >
                                <h2 className="border-bottom pb-2 mb-4">{brasserie.catalogues[catalogueName].nom} <small className="h6 text-muted"> - {brasserie.catalogues[catalogueName].description}</small></h2>
                                <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 row-cols-xxl-4 g-3 mb-5">
                                    {products.map((item, index) => {
                                        if (item.stocks && Object.keys(item.stocks).length > 0) {
                                            return <div className="col d-flex align-items-stretch " key={"card-" + index}>
                                                <Link to={"/client/" + params.clientUUID + "/catalogue/" + item.id} className="text-decoration-none text-dark d-flex align-items-stretch">
                                                    <div className="card shadow-sm">
                                                        {(item.bio) ? <div className="ribbon"><span>Bio</span></div> : ''}
                                                        <img className="card-img-top" width="100%" src={item.image} alt={item.nom} />
                                                        <div className="card-body  d-flex flex-column">
                                                            <figure className="mb-0">
                                                                <blockquote className="blockquote ">
                                                                    <h5 className="card-text">{item.nom} - {item.abv}%</h5>
                                                                </blockquote>
                                                                <figcaption className="blockquote-footer">
                                                                    {item.style}
                                                                </figcaption>
                                                            </figure>
                                                            <p className="small text-muted flex-grow-1">
                                                                {item.description}
                                                            </p>
                                                            <ul className="list-group list-group-flush ">
                                                                {Object.keys(brasserie.conditionnements).map((conditionnementId) => {
                                                                    if (item.stocks[conditionnementId]) {
                                                                        const stock = item.stocks[conditionnementId]
                                                                        if (stock.catalogue === catalogueName) {
                                                                            const conditionnement = brasserie.conditionnements[conditionnementId];
                                                                            let displayStock = (stock.stock)
                                                                            let prixAffiche
                                                                            if (client.pricing === "") {
                                                                                prixAffiche = "Tarifs à venir"
                                                                            } else {
                                                                                prixAffiche = productService.formatPrice(stock.prix, brasserie.pricings[client.pricing].coefs[stock.catalogue][conditionnementId]) + '€ HTDI'
                                                                            }

                                                                            if (stock.visibilite) {
                                                                                return <li key={"stock-" + conditionnementId} className="list-group-item d-flex justify-content-between align-items-start px-0">
                                                                                    <div className="ms-0 me-auto">
                                                                                        <div className="fw-bold small">{conditionnement.nom}</div>
                                                                                        {(prixAffiche) ? prixAffiche : 'Nous consulter'}
                                                                                    </div>
                                                                                    {((stock.stock < 120 && brasserie.conditionnements[conditionnementId].volume < 1) || (stock.stock < 2 && brasserie.conditionnements[conditionnementId].volume > 1)) ? <span className="badge bg-danger rounded-pill">{displayStock}</span> : <span className="badge bg-success ">{displayStock}</span>}
                                                                                </li>
                                                                            }
                                                                        }
                                                                    }
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        }
                                    })
                                    }
                                </div>
                            </div>
                        }
                    })}
                </Col>
            </Row>
        </>
    }
}