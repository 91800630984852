import http from "../http-common";

class BrasserieDataService {

  getById(brasserie) {
    return http.get('/brasseries/'+brasserie.id);
  }
  getByHostName(hostName) {
    return http.get('/brasseries?hostname='+hostName);
  }
  
  updatePricings(brasserie,pricings) {
    return http.put('/brasseries/'+brasserie.id+'/pricings',{pricings:pricings})
  }

  updateCatalogues(brasserie,catalogues) {
    return http.put('/brasseries/'+brasserie.id+'/catalogues',{catalogues:catalogues})
  }

  updateConditionnements(brasserie,conditionnements) {
    return http.put('/brasseries/'+brasserie.id+'/conditionnements',{conditionnements:conditionnements})
  }

  updateTournees(brasserie,tournees) {
    return http.put('/brasseries/'+brasserie.id+'/tournees',{tournees:tournees})
  }

  addInventaire(brasserie,inventaire){
    return http.post('/brasseries/'+brasserie.id+'/inventaires',{inventaire:inventaire})
  }

  getNotifications(brasserie) {
    return http.get('/brasseries/'+brasserie.id+'/notifications')
  }
}

export default new BrasserieDataService();