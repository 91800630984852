
import React from "react";
import { useState } from 'react';
import { useLoaderData, useParams } from "react-router-dom";
import brasserieService from "../services/brasserie.service";
import clientService from "../services/client.service";
import _ from "lodash";

export const loader = async ({ params }) => {
  try {
    const resp = await clientService.sendCatalogueTo(params.email)
    const client = resp.data

    const respBrasserie = await brasserieService.getByHostName(window.location.hostname)
    let brasserie
    if (respBrasserie.data === null) {
      throw new Error()
    } else {
       brasserie = respBrasserie.data;
       
    }

    return { client: client,brasserie:brasserie }
  } catch(error) {
    console.log(error)
    throw new Response(null, {
      status: 500,
      statusText: "Internal Server Error",
    });
  }
}

export default function DemandeAcces(props) {

  //navigation hooks
  const params = useParams()

  //contexts

  const { client,brasserie } = useLoaderData()

  //form states
  const [email, setEmail] = useState(params.email || null);

  console.log(client.statut)
  switch (parseInt(client.statut)) {
    case clientService.STATUT_CLIENT:
      const contact = client.contacts[_.findIndex(client.contacts, ['mail', email])]

      return <div className="row">
      <section className="text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-10 col-md-10 mx-auto">
            <h1 className="fw-light">Bonjour {_.capitalize(contact.prenom)}, le lien vers votre catalogue a été envoyé à : {email} !</h1>
            <p className="lead text-muted">
              Pensez à vérifier vos spams ! Si vous ne receviez rien d'ici quelques minutes, n'hésitez pas à nous <a href={"mailto:"+brasserie.mail}>contacter</a>
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Antoine : 06 48 64 18 88</li>
              <li className="list-group-item">Céline (si vous n'arrivez à joindre ni Miri, ni Antoine) : 06 49 81 58 13</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
      
      
    case clientService.STATUT_ARCHIVE:
      return <div className="row">
      <section className="text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-10 col-md-10 mx-auto">
            <h1 className="fw-light">Nous avons bien reçu votre demande. Merci !</h1>
            <p className="lead text-muted">
              Nous reviendrons vers dans les plus brefs délais (48h max hors weekends). Si jamais vous n'entendiez pas parler de nous, n'hésitez pas à nous passer un coup de fil.
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Antoine : 06 48 64 18 88</li>
              <li className="list-group-item">Céline (si vous n'arrivez pas à joindre Antoine) : 06 49 81 58 13</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
      
  
    default:
      return <div className="row">
      <section className="text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-8 col-md-8 mx-auto">
            <h1 className="fw-light">Nous avons bien reçu votre demande. Merci !</h1>
            <p className="lead text-muted">
              Nous reviendrons vers dans les plus brefs délais (48h max hors weekends). Si jamais vous n'entendiez pas parler de nous, n'hésitez pas à nous passer un coup de fil.
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Antoine : 06 48 64 18 88</li>
              <li className="list-group-item">Céline (si vous n'arrivez pas à joindre Antoine) : 06 49 81 58 13</li>
            </ul>
          </div>
        </div>
      </section>
    </div>

  }
   
  



}
