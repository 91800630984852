import React from "react";

import { fromUnixTime } from 'date-fns'
import { format } from 'date-fns'
import _ from 'lodash'

import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import Typography from '@mui/material/Typography';
import { useCommande } from "../../contexts/CommandeProvider";



export default function Historique() {


    const { commande } = useCommande();

    _.orderBy(commande.events, item => item.timestamp, ['asc']);

    return <div className="row">
        <div className="col-md-12 col-sm-12 col-lg-12">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 bcommande-bottom">
                <h1 className="h2">Historique</h1>
            </div>
            <Timeline position="left">
                {commande.events && commande.events.map((event, index) => {
                    return <TimelineItem key={index}>
                        <TimelineOppositeContent display={"none"}></TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot />
                            {index === commande.events.length - 1 ? "" : <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent>
                            <Typography variant="h6" component="span">{_.capitalize(event.nom)}</Typography>
                            <Typography>
                                {format(fromUnixTime((event.timestamp>9999999999?Math.round(event.timestamp/1000):event.timestamp)), 'dd/MM/yyyy')}
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                })}

            </Timeline>
        </div>

    </div>

}