import React, { useEffect, useState } from "react";
import { useLoaderData, useOutletContext, useParams } from "react-router-dom";
import _ from "lodash";
import productService from "../../services/product.service";
import { format, fromUnixTime } from 'date-fns'
import { textAlign } from "@mui/system";

export const loader = async ({ params }) => {
    try {


    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function Produit(props) {

    console.log('produit page')

    const { client, brasserie, catalogue } = useOutletContext()
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setError] = useState(null);
    const [product, setProduct] = useState(null);

    // fetching id from URL dynamically //
    const params = useParams();
    const productId = params.produitId

    async function getProducts() {
        setIsLoading(true)
        try {
            const respProducts = await productService.getById(brasserie.id, productId);
            const product = respProducts.data;


            console.log('product', product)
            setProduct(product)
        }
        catch (error) {
            setError(error.message)
        }
        finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getProducts()
    }, [productId])



    return <>
        {isLoading && <p>Loading...</p>}
        {isError && !isLoading && <p>Oops! An Error has Occurred, Try Again</p>}
        {!isError && !isLoading && product && <main>
            <div className="position-relative overflow-hidden p-3 p-md-5 my-3 text-center bg-light">
                <div className="col-md-6 col-lg-5 p-lg-5 mx-auto my-5">

                    {(product.bio) ? <div className="ribbon"><span>Bio</span></div> : ''}

                    <h1 className="display-4 fw-normal">{product.nom}</h1>
                    <p className="lead fw-normal">{product.description}</p>
                    <a className="btn btn-outline-secondary" href="#">Commander</a>
                </div>
                <div className="product-device d-none d-md-block"><img className="card-img-top" width="100%" src={product.image} alt={product.nom} /></div>
                <div className="product-device product-device-2 d-none d-md-block"><img className="card-img-top" width="100%" src={product.image} alt={product.nom} /></div>
            </div>

            <div className="row d-flex my-3 ps-md-3 gx-3">



                <div className=" col-12 col-lg-4 bg-light  p-3 pt-md-5 px-md-5 text-center overflow-hidden">
                    <div className="my-3 p-3">
                        <h2 className="display-6">Anecdotes</h2>
                        <p className="lead">Pour vos clients.</p>
                    </div>
                    <div className="text-bg-dark shadow-sm mx-auto d-flex py-3 px-4" style={{ width: "80%", minHeight: "300px", borderRadius: '21px' }}>
                        <figure className="align-self-center">
                            <blockquote class="blockquote">
                                <p style={{ textAlign: "justify" }}> {product?.anecdote}</p>
                            </blockquote>
                            <figcaption class="blockquote-footer">
                                Le mot de <cite title="Source Title">Céline</cite>
                            </figcaption>
                        </figure>

                    </div>
                </div>
                <div className="col-12 col-lg-4 text-bg-dark  p-3 pt-md-5 px-md-5 text-center overflow-hidden">
                    <div className="my-3 py-3">
                        <h2 className="display-6">Fiche Technique</h2>
                        <p className="lead">Sous l'étiquette.</p>
                    </div>
                    <div className="text-bg-light shadow-sm mx-auto p-2" style={{ width: "80%", minHeight: "300px", borderRadius: '21px' }}>
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                    <button class="accordion-button collapsed bg-light text-reset" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        Data
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body bg-light text-start">
                                        <ul>
                                            <li>Alcool : {product.abv}% vol</li>
                                            <li>Style : {product.style}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                    <button class="accordion-button collapsed bg-light text-reset" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        Ingrédients
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div class="accordion-body bg-light text-start">
                                        <ul>
                                            <li>Malts : {product.malts}</li>
                                            <li>Houblons : {product.houblons}</li>
                                            <li>Levure : {product.levures}</li>
                                            <li>Autre : {product.autres}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-4 bg-light  p-3 pt-md-5 px-md-5 text-center overflow-hidden">
                    <div className="my-3 p-3">
                        <h2 className="display-6">Stock</h2>
                        <p className="lead">Voilà ce qu'on a.</p>
                    </div>
                    <div className="bg-dark shadow-sm mx-auto p-2" style={{ width: "80%", minHeight: "300px", borderRadius: '21px' }}>
                        <ul className="list-group list-group-flush text-start">
                            {Object.keys(brasserie.conditionnements).map((conditionnementId) => {
                                if (product.stocks[conditionnementId]) {
                                    const stock = product.stocks[conditionnementId]

                                    const conditionnement = brasserie.conditionnements[conditionnementId];
                                    let displayStock = (stock.stock)
                                    let prixAffiche
                                    if (client.pricing === "") {
                                        prixAffiche = "Tarifs à venir"
                                    } else {
                                        prixAffiche = productService.formatPrice(stock.prix, brasserie.pricings[client.pricing].coefs[stock.catalogue][conditionnementId]) + '€ HTDI'
                                    }

                                    if (stock.visibilite) {
                                        return <li key={"stock-" + conditionnementId} className="text-bg-dark list-group-item d-flex justify-content-between align-items-start mx-0">
                                            <div className="ms-0 me-auto">
                                                <div className="fw-bold small">{conditionnement.nom}</div>
                                                {(prixAffiche) ? prixAffiche : 'Nous consulter'}
                                            </div>
                                            {((stock.stock < 120 && brasserie.conditionnements[conditionnementId].volume < 1) || (stock.stock < 2 && brasserie.conditionnements[conditionnementId].volume > 1)) ? <span className="badge bg-danger rounded-pill">{displayStock}</span> : <span className="badge bg-success ">{displayStock}</span>}
                                        </li>
                                    }

                                }
                            })}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="row  my-3 ps-md-3 gx-3">
                <div className="col-12 col-lg-4 d-flex align-items-stretch">
                    <div class="mb-wrap example5">
                        <blockquote>
                            <p>{product.degustation}</p>
                        </blockquote>
                        <div class="mb-attribution">
                            <p class="mb-author">Antoine Guiral</p>
                            <cite>Co-fondateur, Artisan Brasseur</cite>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-8">
                    <div className="row  my-3 ps-md-3 g-3">
                        <div className="col-12 col-md-6">
                            <img className="img-thumbnail" src={product.image} alt={product.nom} />
                        </div>
                        <div className="col-12 col-md-6">
                            <img className="img-thumbnail" src={product.etiquetteFace} alt={product.nom} />
                        </div>
                        <div className="col-12">
                            <img className="img-thumbnail" src={product.etiquette} alt={product.nom} />
                        </div>
                    </div>
                </div>
            </div>
        </main>}

    </>


}