import React, { useCallback, useContext, useRef, useState } from "react";

import _ from 'lodash'
import { useSnackbar } from 'notistack';
import commandeService from "../../../services/commande.service";
import { Alert, Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";

import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import Webcam from "react-webcam";
import { useCommande, useCommandeDispatch } from "../../../contexts/CommandeProvider";
import { SessionContext } from "../../../contexts/SessionContext";
import clientService from "../../../services/client.service";
import { useNavigate } from "react-router-dom";
import { Commande } from "../../../model/Commande.model";
import SignatureCanvas from 'react-signature-canvas'

export default function PhotoLivraison ()   {

    const { commande, client, changementStatutLoading } = useCommande();
    const dispatch = useCommandeDispatch()
    const [show, setShow] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin
    const [showPhotoLivraison, setShowPhotoLivraison] = useState(false)
    const [progresspercent, setProgresspercent] = useState(0)
    const videoConstraints = {
        width: 1280,
        height: 720,
        facingMode: "environment"
    };
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);

    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
    }, [webcamRef, setImgSrc]);

    const handleUpload = (e) => {
        e.preventDefault()
        commande.photoLivraison = imgSrc
        const event = {
            timestamp: new Date().getTime(),
            nom: 'photo',
            source: 'Admin',
            context: 'commande'
        };
        commande.addEvent(event)
        commande.update().then(resp => {
            if (resp.data.id) {
                
                clientService.addEvent(commande.brasserie, commande.client, event)

                enqueueSnackbar('La photo est ajoutée.', { variant: 'success' })
            } else {
                enqueueSnackbar('Un truc n\'a pas marché :(.', { variant: 'error' })
            }
            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })

        }).catch(e => {
            console.log(e)
            enqueueSnackbar(e.response.data.error, { variant: 'error' })

            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })
        })

    }

    return <>
        <div className="d-flex mb-3">
            <Button variant="warning" className="flex-fill" onClick={(e) => setShow(!show)} disabled={changementStatutLoading}>{!changementStatutLoading ? "Prendre une photo de la palette" : <Spinner />}</Button>


            <Modal show={show} onHide={(e) => setShow(false)} className={"modal-colored modal-warning"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une photo de la commande</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center m-3">
                    <p className="mb-2 text-white">
                        Photo de la commande avant expédition
                    </p>
                    <p></p>
                    <div >
                        {!imgSrc && <><Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            width="100%"
                        />
                            <Button onClick={capture}>Prendre la photo</Button>
                        </>}
                        {imgSrc && <img src={imgSrc} alt="img" />}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={(e) => setShow(false)}>Fermer</Button>
                    <Button variant="light" onClick={(e) => handleUpload(e)}>Ajouter la photo</Button>
                </Modal.Footer>

            </Modal>
        </div>
    </>
}