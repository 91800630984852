
import _ from "lodash";
import React from "react";
import { Badge } from "react-bootstrap";
import commandeService from "../services/commande.service";

export default function OrderStatut(props) {

   const statut = props.statut
   let color = "secondary"

   switch (statut) {
    case commandeService.STATUT_OUVERTE:
    case commandeService.STATUT_MODIFIEE:
       color = "primary"
       break;
        
    case commandeService.STATUT_VALIDEE:
        color = "success"
        break;
        
    case commandeService.STATUT_ANNULEE:
        color = "danger"
        break;
        
    case commandeService.STATUT_EXPEDIEE:
        color = "warning"
        break;
        
    case commandeService.STATUT_FACTUREE:
        color = "info"
        break;
        

    default:
        break;
   }
   return <Badge bg={color}>{_.capitalize(commandeService.STATUTS[statut])}</Badge>


   
}