import { Card, Spinner } from "react-bootstrap";

export default function Loader () {
    return (
        <Card className="flex-fill w-100"><div
      className="position-absolute top-50 start-50 translate-middle"><Spinner
        animation="border"
        variant="primary"
      /></div></Card>
    )
}