
import { useState } from 'react';
import { Button, Pagination } from 'react-bootstrap';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
    standardFontDataUrl: 'standard_fonts/',
};

export default function PDFViewer({ fileUrl }) {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);


    function onDocumentLoadSuccess({ numPages: nextNumPages }) {
        setNumPages(nextNumPages);
    } function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    return (
        <>
         <div className='mt-2 d-flex justify-content-center'>
               
               <Pagination className="me-2 mb-0">
                   <Pagination.Prev disabled={pageNumber <= 1}
                       onClick={previousPage} />
                   <Pagination.Item>Page {pageNumber || (numPages ? 1 : '--')}</Pagination.Item>
                   <Pagination.Next disabled={pageNumber >= numPages}
                       onClick={nextPage} />
               </Pagination>
               <Button variant="primary" size="sm" as="a" href={fileUrl} target="_blank">
                   Télécharger la facture
               </Button>
              
           </div>
         <div className='mt-2 d-flex justify-content-center'>
            <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                <Page pageNumber={pageNumber} />
            </Document>
            </div>
           
        </>
       
    );
}