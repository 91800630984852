
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { Outlet, Link, useLoaderData, useParams, redirect, NavLink } from "react-router-dom";
import { SessionContext } from "../contexts/SessionContext";
import transporteurs from "../data/transporteurs";
import useLocalStorage from "../hooks/useLocalStorage";
import brasserieService from "../services/brasserie.service";
import catalogueService from "../services/catalogue.service";
import clientService from "../services/client.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import commandeService from "../services/commande.service";
import { Catalogue } from "../model/Catalogue.model";


export const loader = async ({ params }) => {
  try {
    let brasserie;
    let client;
    const resp = await clientService.getByContactUUID(params.clientUUID)
    if (resp.data === null) {
      return redirect("/");
    } else {
      client = resp.data;
      client.transporteur = transporteurs.getBestTransporteurForDepartement(Math.trunc(client.livraison.codePostal / 1000))
    }
    const respBrasserie = await brasserieService.getByHostName(window.location.hostname)
    if (respBrasserie.data === null) {
      throw new Error()
    } else {
      brasserie = respBrasserie.data;

    }
    const produits = await catalogueService.get(brasserie);



    const contact = client.contacts[_.findIndex(client.contacts, ['uuid', params.clientUUID])]


    const catalogue = new Catalogue(brasserie)
    if (produits && client ) {
        catalogue.setRawProducts(produits)
        catalogue.setClient(client)
    }

    
    return {
      client: client,
      brasserie: brasserie,
      catalogue: catalogue,
      contact: contact,
      incomplet: clientService.isClientIncomplet(client, contact)
    }

  } catch (error) {
    console.log(error)
    throw new Response(null, {
      status: 500,
      statusText: "Internal Server Error",
    });
  }
}

function ClientLayout() {


  const { client, brasserie, catalogue, contact, incomplet } = useLoaderData()
  const { clientUUID } = useParams()

  const defaultAlertsStatus =  {
    "beta": true,
    "prospects":true,
    "creationCommande":true,
  }
  const [alerts, setAlerts] = useState(_.assign(defaultAlertsStatus,JSON.parse(localStorage.getItem('alerts'))));
  const { session, updateSession } = useContext(SessionContext)
  if (!session.isAdmin) {
    session.brasserie = brasserie
    session.client = client
    session.contact = contact
    updateSession(session)
  }


  const handleDismissAlertClick = (alertKey) => {
    const newAlerts = _.cloneDeep(alerts)
    newAlerts[alertKey] = false
    localStorage.setItem('alerts', JSON.stringify(newAlerts));
    setAlerts(newAlerts)
  }
  const activeStyle = { "borderBottom": '2px solid white', color: "white" }

  if (client) {
   // const commandesOuvertes = client?.commandes.filter(c => c.statut < commandeService.STATUT_VALIDEE).length
    return (
      <>
        <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
          <div className="container-md">
            <Link to={"/client/" + clientUUID + "/fiche-client"} className="navbar-brand">Le Père l'Amer - Pro </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <NavLink to={"/client/" + clientUUID + "/catalogue"} className="nav-link" style={({ isActive }) => isActive ? activeStyle : undefined} >
                    Catalogue
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/client/" + clientUUID + "/bon-de-commande"} className={incomplet.contact || incomplet.livraison || incomplet.facturation || client.pricing === "" ? "nav-link disabled" : "nav-link"} style={({ isActive }) => isActive ? activeStyle : undefined}>
                    Bon de Commande  
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/client/" + clientUUID + "/commandes"} className={incomplet.contact || incomplet.livraison || incomplet.facturation || client.pricing === "" ? "nav-link disabled" : "nav-link"} style={({ isActive }) => isActive ? activeStyle : undefined}>
                    Commandes {client?.notifications?.commandesOuvertes > 0 && <span className=" badge bg-danger">{client.notifications.commandesOuvertes}</span>}
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to={"/client/" + clientUUID + "/fiche-client"} className="nav-link" style={({ isActive }) => isActive ? activeStyle : undefined}>
                    Fiche client
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div key="show-pricing" className="container-md py-4 flex-shrink-0">
          {!!alerts['beta'] && <>
            <Alert variant={"primary"} dismissible onClose={() => handleDismissAlertClick('beta')}>
              <div className="alert-message">
                <strong>Bienvenue sur la beta de notre catalogue en ligne.</strong> Il vous permet de consulter nos produits et de passer vos commandes.
                <br /> Il est tout nouveau et développé en interne, il peut donc y avoir quelques bugs que nous nous efforcerons de corriger au plus vite !
                <br />Si vous avez le temps, n'hésitez pas à nous les remonter :) Merci d'avance !
              </div>
            </Alert>
          </>}
          {!!alerts['creationCommande'] && <>
            <Alert variant={"success"} dismissible onClose={() => handleDismissAlertClick('creationCommande')}>
              <div className="alert-message">
                <strong>Petite astuce</strong> La création d'une commande n'est pas une validation de commande.
                <br /> Vous pouvez commencer à remplir une commande, la créer pour la sauvegarder et la modifier ou la finir plus tard. 
                <br /> Nous vous contacterons avant de la valider. Cela permet entre autre de mettre du stock de coté pour vous !
              </div>
            </Alert>
          </>}
          {(client.pricing === "" || !client.pricing) && !!alerts['prospects'] 
            && <>
            <Alert variant={"warning"} dismissible onClose={() => handleDismissAlertClick('prospects')}>
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message"> 
              Nous devons encore paramétrer votre fiche client pour que vous puissiez avoir accès aux tarifs. N'hésitez pas à nous contacter si cela vous semble trop long !
              </div>
            </Alert>

            </>}
          {(incomplet.contact || incomplet.livraison || incomplet.facturation)
            &&
            <Alert variant={"warning"} >
              <div className="alert-icon">
                <FontAwesomeIcon icon={faBell} fixedWidth />
              </div>
              <div className="alert-message">
                Complétez votre fiche client pour passer une commande.  <Link reloadDocument to={"/client/" + clientUUID + "/fiche-client?" + _.map(incomplet, (value, key) => `${key}=${value}`).join('&')} className="alert-link"><u>Moins de deux minutes.</u> </Link>
              </div>
            </Alert>}

          <Outlet context={{ client: client, brasserie: brasserie, catalogue: catalogue, contact: contact, incomplet: incomplet }}></Outlet>
        </div>


        <footer className="text-muted py-5 mt-auto">
          <div className="container">
            <p className="float-end mb-1">
              /o/ LPLA \o\
            </p>
            <p className="mb-1">Le Père l'Amer - SAS LPLA, 16 rue bertrand de born, 24800 Thiviers</p>
            <p className="mb-0">Question/Commande - <a href="mailto:pro@leperelamer.fr">pro@leperelamer.fr</a></p>
            <p className="mb-0">Bug/suggestion - <a href="mailto:antoine@leperelamer.fr">antoine@leperelamer.fr</a></p>
          </div>
        </footer>
      </>

    );
  } else {
    console.log('pas client')
  }

}

export default ClientLayout;
