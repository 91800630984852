import _ from "lodash";
import http from "../http-common";

class ClientDataService {

  freshUUID(){
    return new Date().valueOf().toString(36) + Math.random().toString(36).substring(4)
  } 

  STATUT_SUSPECT = 0
  STATUT_PROSPECT_FROID = 1
  STATUT_PROSPECT_TIEDE = 2
  STATUT_PROSPECT_CHAUD = 3
  STATUT_CLIENT = 4
  STATUT_ARCHIVE = 5
  STATUTS = [
    {
      key: "suspect",
      nom: "suspect",
      color: "dark"
    }, {
      key: "prospect_froid",
      nom: "froid",
      color: "secondary"
    }, {
      key: "prospect_tiede",
      nom: "tiède",
      color: "info"
    }, {
      key: "prospect_chaud",
      nom: "chaud",
      color: "primary"
    }, {
      key: "client",
      nom: "client",
      color: "success"
    }, {
      key: "archive",
      nom: "archivé",
      color: "danger"
    }]

    LIVRAISON_FOURGON = 0
    LIVRAISON_FOURGON_UTILITAIRE = 1
    LIVRAISON_POIDS_LOURD_FOURGON = 2
    LIVRAISON_SEMI_REMORQUE = 3
    LIVRAISONS = [
      {
        key : 'fourgon',
        nom : "fourgon",
        url : "/img/livraisons/0.png"
      },
      {
        key : 'fourgon_utilitaire',
        nom : "fourgon utilitaire",
        url : "/img/livraisons/1.png"
      },
      {
        key : 'poids_lourd_fourgon',
        nom : "poids lourd fourgon",
        url : "/img/livraisons/2.png"
      },
      {
        key : 'semi_remorque',
        nom : "semi_remorque",
        url : "/img/livraisons/3.png"
      }
    ]

  DEFAULT_CLIENT = {
    brasserieId: "",
    siren: "",
    facturation: {
      nom: "",
      adresse: "",
      codePostal: "",
      ville: "",
      siret: "",
      tva: ""
    },
    livraison: {
      nom: "",
      adresse: "",
      codePostal: "",
      ville: "",
      horaires: "",
      siret: "",
      accessibilite: "",
      ea: ""
    },
    catalogues: [],
    pricing: '',
    statut: this.STATUT_SUSPECT,
    archive: false,
    tournee: "",
    events: [],
    commentaires: [],
    contacts: [],
    source: "admin"
  }

  DEFAULT_CONTACT = {
    mail: "",
    nom: "",
    prenom: "",
    telephone: "",
    horaires: "",
    uuid:this.freshUUID()
  }

  MANDATORY_FACTURATION = {
    nom: "",
    adresse: "",
    codePostal: "",
    ville: ""
  }

  MANDATORY_LIVRAISON = {
    nom: "",
    adresse: "",
    codePostal: "",
    ville: "",
    horaires: "",
    accessibilite: ""
  }

  

  /**
   * A partir de la fiche client 
   * et eventuellement d'un contact
   * Retourne un object avec les parties du client à compléter et un récapitulatif sur la clé "short"
   * 
   * @param {*} client 
   * @param {*} contact 
   * @returns 
   */
  isClientIncomplet(client, contact) {
    if (contact === null) {
      contact = client.contacts[0]
    }
    const isObjEmpty = obj => obj === null || Object.keys(obj).length === 0;
    const isItemEmpty = (item) => {
      return item === undefined
        || item === ""
        || (Array.isArray(item) && item.every(isItemEmpty))
        || (typeof item === "object" && isObjEmpty(item));
    }

    const isSomethingNeedTobeCompleted = (object) => {
      for (const key in object) {
        if (Object.hasOwnProperty.call(object, key)) {
          const element = object[key];
          if (isItemEmpty(element)) return true
        }
      }
      return false
    }

    const livraison = _.cloneDeep(client.livraison)
    for (const key in livraison) {
      if (!Object.hasOwnProperty.call(this.MANDATORY_LIVRAISON, key)) {
        _.unset(livraison,key)
        
      }
    }
    const facturation = _.cloneDeep(client.facturation)
    for (const key in facturation) {
      if (!Object.hasOwnProperty.call(this.MANDATORY_FACTURATION, key)) {
        _.unset(facturation,key)
        
      }
    }
    const isContactIncomplete = isSomethingNeedTobeCompleted(contact)
    const isLivraisonIncomplete = isSomethingNeedTobeCompleted(livraison)
    const isFacturationIncomplete = isSomethingNeedTobeCompleted(facturation)

    console.log({
      contact: isContactIncomplete,
      livraison: isLivraisonIncomplete,
      facturation: isFacturationIncomplete,
      short: isContactIncomplete + isLivraisonIncomplete + isFacturationIncomplete
    })
    return {
      contact: isContactIncomplete,
      livraison: isLivraisonIncomplete,
      facturation: isFacturationIncomplete,
      short: isContactIncomplete + isLivraisonIncomplete + isFacturationIncomplete
    }
  }

  cleanClient(client) {
    const newClient = _.cloneDeep(client);
    delete newClient.events
    delete newClient.commentaires
    delete newClient.commandes
    return newClient
  }

  /**
   * 
   * @param {*} email 
   * @returns null if no client, client object if found
   */
  getByContactEmail(email) {
    return http.get(`/clients/demande-acces`, { params: { email: email } });
  }
  /**
   * 
   * @param {*} email 
   * @returns client or null
   */
  sendCatalogueTo(email) {
    return http.get(`/clients/envoyer-catalogue`, { params: { email: email } });
  }


  /**
  * 
  * @param {*} brasserie 
  * @returns null if no client, clients object if found
  */
  getAll(brasserieId) {
    return http.get('/brasseries/' + brasserieId + '/clients');
  }

   /**
  * 
  * @param {*} brasserie 
  * @returns null if no client, clients object if found
  */
   getAllLight(brasserieId) {
    return http.get('/brasseries/' + brasserieId + '/clients/light');
  }

  /**
 * 
 * @param {*} brasserie 
 * @returns null if no client, clients object if found
 */
  getById(brasserieId, clientId) {
    return http.get('/brasseries/' + brasserieId + '/clients/' + clientId);
  }

  /**
* 
* @param {*} brasserie 
* @returns null if no client, clients object if found
*/
  updateClientPartial(brasserie, client, key) {

    return http.put('/brasseries/' + brasserie.id + '/clients/' + client.id + '?field=' + key, { client: this.cleanClient(client) });
  }

  addEvent(brasserie, client, event) {
    return http.post('/brasseries/' + brasserie.id + '/clients/' + client.id + '/events', { event: event });
  }
  addCommentaire(brasserie, client, commentaire) {
    return http.post('/brasseries/' + brasserie.id + '/clients/' + client.id + '/commentaires', { commentaire: commentaire });
  }
  addContact(brasserie, client, contact) {
    return http.post('/brasseries/' + brasserie.id + '/clients/' + client.id + '/contacts', { contact: contact });
  }
  editContact(brasserie, client, contact) {
    return http.put('/brasseries/' + brasserie.id + '/clients/' + client.id + '/contacts', { contact: contact });
  }
  deleteContact(brasserie, client, contact) {
    return http.delete('/brasseries/' + brasserie.id + '/clients/' + client.id + '/contacts', { data: { contact: contact } });
  }

  /**
   * 
   * @param {*} urlId 
   * @returns null if no client, client object if found
   */
  async getByContactUUID(UUID) {
    return http.get(`/clients/` + UUID);
  }

  createv2(idBrasserie, client) {
    console.log(client)
    return http.post('/brasseries/' + idBrasserie + '/clients', { client: client })
  }

  sendOrder(order) {
    return http.post("/order", { order: order })
  }

  //get events for all clients
  getEvents(brasserieId) {
    return http.get('/brasseries/' + brasserieId + '/clients/events');
  }
}

export default new ClientDataService();