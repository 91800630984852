const gauthier = {
    gazoil:25,
    name:"gauthier",
    color:"green",

    palette : {
        "01" : 124,
        "02" : 116,
        "03" : 125,
        "04" : 165,
        "05" : 150,
        "06" : 171,
        "07" : 138,
        "08" : 125,
        "09" : 116,
        "10" : 116,
        "11" : 123,
        "12" : 139,
        "13" : 127,
        "14" : 120,
        "15" : 116,
        "16" : 91,
        "17" : 108,
        "18" : 121,
        "19" : 127,
        "20" : null,
        "21" : 137,
        "22" : 120,
        "23" : 116,
        "24" : 113,
        "25" : 119,
        "26" : 138,
        "27" : 117,
        "28" : 124,
        "29" : 137,
        "30" : 121,
        "31" : 113,
        "32" : 110,
        "33" : 110,
        "34" : 121,
        "35" : 122,
        "36" : 125,
        "37" : 111,
        "38" : 131,
        "39" : 135,
        "40" : 110,
        "41" : 109,
        "42" : 127,
        "43" : 125,
        "44" : 114,
        "45" : 111,
        "46" : 111,
        "47" : 104,
        "48" : 142,
        "49" : 114,
        "50" : 120,
        "51" : 128,
        "52" : 119,
        "53" : 120,
        "54" : 143,
        "55" : 172,
        "56" : 123,
        "57" : 149,
        "58" : 135,
        "59" : 133,
        "60" : 145,
        "61" : 119,
        "62" : 145,
        "63" : 114,
        "64" : 113,
        "65" : 113,
        "66" : 124,
        "67" : 149,
        "68" : 153,
        "69" : 133,
        "70" : 153,
        "71" : 159,
        "72" : 133,
        "73" : 160,
        "74" : 160,
        "75" : 137,
        "76" : 138,
        "77" : 137,
        "78" : 137,
        "79" : 124,
        "80" : 120,
        "81" : 131,
        "82" : 112,
        "83" : 142,
        "84" : 134,
        "85" : 120,
        "86" : 120,
        "87" : 113,
        "88" : 139,
        "89" : 137,
        "90" : 153,
        "91" : 137,
        "92" : 137,
        "93" : 137,
        "94" : 137,
        "95" : 137,
        "96" : null,
        "97" : null,
        "98" : null,
        "99" : null
    }
}


export default gauthier;