import React, { useCallback, useContext, useRef, useState } from "react";

import _ from 'lodash'
import { useSnackbar } from 'notistack';
import commandeService from "../../../services/commande.service";
import { Alert, Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";

import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import Webcam from "react-webcam";
import { useCommande, useCommandeDispatch } from "../../../contexts/CommandeProvider";
import { SessionContext } from "../../../contexts/SessionContext";
import clientService from "../../../services/client.service";
import { useNavigate } from "react-router-dom";
import { Commande } from "../../../model/Commande.model";
import SignatureCanvas from 'react-signature-canvas'

export default function Creation ()  {

    const [show, setShow] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { commande } = useCommande();
    const dispatch = useCommandeDispatch()
    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin



    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({ type: 'changementStatutLoading' })
       
        commande.statut = Commande.STATUT_OUVERTE
        const event = {
            timestamp: new Date().getTime(),
            nom: 'création',
            source: (isAdmin) ? 'Admin' : session.contact.prenom + ' ' + session.contact.nom.charAt(0),
            context: 'commande'
        };
        commande.addEvent(event)
        commande.create().then(resp => {
            if (resp.data.id) {
               
                clientService.addEvent(commande.brasserie, commande.client, event)
                
                enqueueSnackbar('La commande est sauvegardée.', { variant: 'success' })
                if (isAdmin) {
                    commande.id = resp.data.id

                } else {
                    navigate('/client/' + session.contact.uuid + '/merci')
                }
            } else {
                enqueueSnackbar('Un truc n\'a pas marché :(.', { variant: 'error' })
            }

            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })

            setShow(false)
        }).catch(e => {
            console.log(e)
            enqueueSnackbar(e.response.data.error, { variant: 'error' })

            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })
        })
    }

    return <>
        <div className="d-flex mb-3">
            <button className="btn btn-primary flex-fill" onClick={(e) => setShow(!show)}>
                Créer la commande
            </button>

            <Modal show={show} onHide={(e) => setShow(false)} className={"modal-colored modal-primary"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Créer une commande</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center m-3">
                    <p className="mb-2 text-white">
                        Vous êtes sur le point de créer une commande : <strong>merci</strong> <i className="fas fa-heart"></i>
                    </p>
                    <p className="mb-4 text-white">
                        Tant que nous ne la validons pas, vous pouvez la modifier. Pensez à vérifier que vous avez séléctionné le bon mode de livraison (vous pouvez le changer dans le menu situé au dessus de la liste des produits).
                    </p>
                    <Alert variant="primary" className="alert-outline" >
                        <div className="alert-message">
                            {commande.port.tournee && <span className="">Cette commande sera livrée lors de la prochaine tournée.</span>}
                            {commande.port.expedition && <p className="">Les expéditions par transporteur se font le mardi après <strong>validation</strong> de votre commande. Pensez à optimiser votre palette : 11 fûts par étage (2 étages max) ou 19 cartons de 12x33cl (6 étages max).</p>}
                            {commande.port.retrait && <p className="">Vous pouvez venir retirer votre commande à la brasserie après avoir avoir convenu d'un rendez-vous avec nous par mail (pro@leperelamer.fr) ou par téléphone (0648641888). En cas d'urgence, merci de nous contacter.</p>}
                            {commande.port.offert && <p className="">Dans le cas d'un enlèvement de palette par votre transporteur, merci de nous contacter pour l'organisation.</p>}
                        </div>
                    </Alert>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={(e) => setShow(false)}>Fermer</Button>
                    <Button variant="light" onClick={(e) => handleSubmit(e)}>Créer la commande</Button>
                </Modal.Footer>

            </Modal>
        </div>
    </>
}