import React from "react";

import { Link } from "react-router-dom";
import _ from "lodash"
import ColoredProductListCell from "../../../components/ColoredProductListCell";


export default function ListProducts(props) {

    let totaux = {'total':0}

    return <>
        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 className="h2">Liste des produits</h1>
            <div className="btn-toolbar mb-2 mb-md-0">
                <div className="btn-group me-2">
                    {
                        Object.keys(props.brasserie.pricings).map((pricing,k)=> {
                            return  <Link key={k} to={"/admin/" + props.brasserie.id + "/mercurial/"+pricing} className="btn btn-sm btn-outline-primary">
                            {props.brasserie.pricings[pricing].nom}
                        </Link>
                        })
                    }
                    <Link to={"/admin/" + props.brasserie.id + "/produits/creer"} className="btn btn-sm btn-outline-primary">
                        Créer
                    </Link>
                </div>
            </div>
        </div>
        <table className="table">
            <thead>
                <tr>
                    <th scope="col" className="d-none d-sm-table-cell"></th>
                    <th scope="col">Nom</th>
                    <th scope="col">Volume (hl)</th>
                    {Object.keys(props.brasserie.conditionnements).map((conditionnementKey) => {
                       
                            totaux[conditionnementKey]={'unite':0,'volume':0}
                        return <th scope="col" key={conditionnementKey}>{props.brasserie.conditionnements[conditionnementKey].nom}</th>
                    })}
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    (props.products.length > 0 &&
                        props.products.map((product, index) => {

                           

                            return <tr key={index}>
                                <td>
                                    <img src={product.image} alt={product.nom} width="30" />
                                </td>
                                <td>
                                    {product.nom} 
                                </td>
                                <td>
                                { //calcul volume par produit
                                        Math.round(Object.keys(props.brasserie.conditionnements).reduce((acc, conditionnementKey) => {
                                            if (_.has(product.stocks, [conditionnementKey])) {
                                                return acc + product.stocks[conditionnementKey].stock * props.brasserie.conditionnements[conditionnementKey].volume
                                            } else {
                                                return acc
                                            }
                                        }, 0)*100) / 10000
                                    }
                                </td>
                                {
                                    Object.keys(props.brasserie.conditionnements).map((conditionnementKey, index) => {
                                        if (_.has(product.stocks, [conditionnementKey])) {
                                            totaux[conditionnementKey].unite+=product.stocks[conditionnementKey].stock
                                            totaux[conditionnementKey].volume+=product.stocks[conditionnementKey].stock*props.brasserie.conditionnements[conditionnementKey].volume
                                            totaux.total+=product.stocks[conditionnementKey].stock*props.brasserie.conditionnements[conditionnementKey].volume
                                            return <ColoredProductListCell key={index} stock={product.stocks[conditionnementKey]} />

                                        } else {
                                            return <td key={conditionnementKey} className="table-light"></td>
                                        }
                                    })
                                }
                                <td>
                                    <Link to={"/admin/" + props.brasserie.id + "/produits/" + product.id} className="btn btn-primary">
                                        Modifier
                                    </Link>
                                </td>
                            </tr>
                        })
                    )
                }
                {
                    <tr>
                    <td>
                    </td>
                    <td>
                       Totaux
                    </td>
                    <td>
                        {
                            Math.round(Object.keys(props.brasserie.conditionnements).reduce((acc, conditionnementKey) => {
                                return acc + totaux[conditionnementKey].volume
                            }, 0)*100) / 10000
                        }
                    </td>
                    {
                        Object.keys(props.brasserie.conditionnements).map((conditionnementKey, index) => {
                            return <td key={index}>{totaux[conditionnementKey].unite} ({Math.round(totaux[conditionnementKey].volume)/100}hl)</td>
                        })
                    }
                    <td>
                        {Math.round(totaux.total)/100}hl
                    </td>
                </tr>
                }
            </tbody>
        </table>
    </>



}