import { useState } from "react";
import _ from "lodash";
import { useSnackbar } from "notistack";
import clientService from "../../services/client.service";


import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import eventService from "../../services/event.service";
import Modal from 'react-bootstrap/Modal';
import { ContactForm } from "../forms/ContactForm";
import { FacturationForm } from "../forms/FacturationForm";
import { LivraisonForm } from "../forms/LivraisonForm";

const AddCommunication = ({ brasserie, client, communication, ...props }) => {

    const { enqueueSnackbar } = useSnackbar();

    const handleAddCommunication = (e, type) => {
        const newClient = _.cloneDeep(client);
        const event = {
            timestamp: Date.now(),
            nom: 'communication',
            source: 'admin',
            context: type
        };
        if (newClient.events) {
            newClient.events.unshift(event)
        } else {
            newClient.events = [event]
        }
        clientService.addEvent(brasserie, newClient, event).then((resp) => {
            if (resp.status === 200) {
                enqueueSnackbar('Evènement ajouté.', { variant: 'success' })
                if(props.setClient){
                    props.setClient(newClient)
                }
            } else {
                enqueueSnackbar('Erreur lors de l\'ajout de l\'évènement', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });

    }

    return <div className="btn btn-sm btn-outline-primary" onClick={(e) => handleAddCommunication(e, communication.type)}>
        <i className={"fa-solid " + communication.icon}></i>
    </div>
};

const AddCommentaire = ({ brasserie, client, ...props }) => {

    const [dateRappel, setDateRappel] = useState(null)
    const { enqueueSnackbar } = useSnackbar();
    const [showAddCommentaire, setShowAddCommentaire] = useState(false)

    const handleSubmitNewComment = (e) => {
        e.preventDefault()
        if (e.target[0].value !== "") {
            const newClient = _.cloneDeep(client);
            const commentaire = {
                timestamp: Date.now(),
                commentaire: e.target[0].value,
                archive: false
            };
            if (newClient.commentaires) {
                newClient.commentaires.unshift(commentaire)
            } else {
                newClient.commentaires = [commentaire]
            }

            clientService.addCommentaire(brasserie, newClient, commentaire).then(resp => {
                if (resp.status === 200) {
                    enqueueSnackbar('Commentaire enregistré.', { variant: 'success' })
                    if (props.setClient) {
                        props.setClient(newClient)
                    }

                    e.target.reset()
                    setShowAddCommentaire(false)

                    if(dateRappel){
                        const newEvent = _.cloneDeep(eventService.EVENT)
                    newEvent.data = client
                    newEvent.start = dateRappel
                    newEvent.allDay = true
                    newEvent.title = client.facturation.nom + '(' + client.livraison.nom + ')'
                    newEvent.type = eventService.TYPES[eventService.TYPE_CONTACT]
                    newEvent.desc = commentaire.commentaire

                    setDateRappel(null)
                    eventService.addEvent(brasserie, newEvent).then(resp => {
                        if (resp.status === 200) {
                            enqueueSnackbar('Rappel enregistré.', { variant: 'success' })
                        } else {
                            enqueueSnackbar('Erreur lors de création du rappel.', { variant: 'error' })
                        }
                    }).catch(error => {
                        enqueueSnackbar(error.message, { variant: 'error' })
                    });
                    }           
                } else {
                    enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
                }
            }).catch(error => {
                enqueueSnackbar(error.message, { variant: 'error' })
            });
        }
    }

    return <>
        <div className="btn btn-sm btn-outline-primary" onClick={(e) => setShowAddCommentaire(!showAddCommentaire)}>
            <i className="fa-solid fa-comment-dots"></i>
        </div>
        <Modal show={showAddCommentaire} onHide={(e) => setShowAddCommentaire(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un commentaire pour {client.livraison.nom}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className="d-flex flex-column mb-2" onSubmit={(e) => handleSubmitNewComment(e)}>
                    <div className="mb-3 flex-fill">
                        <label className="form-label" htmlFor="commentaire">Nouveau commentaire</label>
                        <div className="input-group has-validation">
                            <textarea className="form-control" id="commentaire" />
                        </div>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Créer un rappel"
                            value={dateRappel}
                            onChange={(newDate) => {
                                setDateRappel(newDate);
                            }}
                            renderInput={(params) => <TextField size="small" {...params} />}
                        />
                    </LocalizationProvider>
                    <button className="btn btn-primary flex-fill mt-2" type="submit">Ajouter</button>
                </form>
            </Modal.Body>
        </Modal>
    </>
}

const AddContact = ({ brasserie, client, setClient, ...props }) => {

    const [showAddContact, setShowAddContact] = useState(false)

    return <>
        <div className="btn btn-sm btn-outline-primary" onClick={(e) => setShowAddContact(!showAddContact)}>
        <i className="fa-solid fa-user-plus"></i>
        </div>
        <Modal show={showAddContact} onHide={(e) => setShowAddContact(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Ajouter un contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <ContactForm source={"admin"} brasserie={brasserie} client={client} setClient={setClient} closeModal={setShowAddContact}/>

            </Modal.Body>

        </Modal>

    </>
}

const EditContact = ({ brasserie, client, setClient, contact, ...props }) => {

    const [showAddContact, setShowAddContact] = useState(false)

   

    return <>
        <div className="btn btn-sm btn-outline-primary" onClick={(e) => setShowAddContact(!showAddContact)}>
        <i className="fa-solid fa-user-pen"></i>
        </div>
        <Modal show={showAddContact} onHide={(e) => setShowAddContact(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Editer un contact</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <ContactForm source={"admin"} brasserie={brasserie} client={client} setClient={setClient} contact={contact} closeModal={setShowAddContact}/>

            </Modal.Body>

        </Modal>

    </>
}

const DeleteContact = ({ brasserie, client, setClient, contact, ...props }) => {
    
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = () => {

        const indexContact = _.findIndex(client.contacts, ['mail', contact.mail]) 
        clientService.deleteContact(brasserie,client,contact).then(resp => {
            if (resp.status === 200) {
              enqueueSnackbar('Le contact a été supprimé', { variant: 'success' })
              const event = {
                timestamp: Date.now(),
                nom: 'suppression',
                source: "admin",
                context: 'contact'
              };
              clientService.addEvent(brasserie, client, event)
              const newClient = _.cloneDeep(client)
              newClient.contacts.splice(indexContact,1)
              _.pull(newClient.mails,contact.mail)
              setClient(newClient)
            } else {
              enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
          }).catch(error => {
            console.log(error)
            enqueueSnackbar(error.response.data.error.data.error, { variant: 'error' })
          });

    }

    return <>
        <div className="btn btn-sm btn-outline-danger" onClick={handleClick}>
        <i className="fa-solid fa-user-xmark"></i>
        </div>
    </>
}

const EditFacturation = ({ brasserie, client, setClient, ...props }) => {

    const [show, setShow] = useState(false)
    return <>
        <div className="btn btn-sm btn-outline-primary" onClick={(e) => setShow(!show)}>
        <i className="fa-solid fa-file-pen"></i>
        </div>
        <Modal show={show} onHide={(e) => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Editer les données de facturation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <FacturationForm source={"admin"} brasserie={brasserie} client={client} setClient={setClient} closeModal={setShow}/>
            </Modal.Body>
        </Modal>
    </>
}

const EditLivraison = ({ brasserie, client, setClient, ...props }) => {

    const [show, setShow] = useState(false)
    return <>
        <div className="btn btn-sm btn-outline-primary" onClick={(e) => setShow(!show)}>
        <i className="fa-solid fa-file-pen"></i>
        </div>
        <Modal show={show} onHide={(e) => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Editer les données de livraison</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <LivraisonForm source={"admin"} brasserie={brasserie} client={client} setClient={setClient} closeModal={setShow}/>
            </Modal.Body>
        </Modal>
    </>
}
export { AddCommunication, AddCommentaire, AddContact, EditContact, DeleteContact,EditFacturation,EditLivraison }