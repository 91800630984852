import React from "react";

import _ from 'lodash';
import LigneProduitTableQuantite from "./LigneProduitTableQuantite";
import { useCommande } from "../../contexts/CommandeProvider";


export default function TableQuantites(props) {

    const {commande,brasserie,catalogue,filters} = useCommande()


    const getStockTypeFromConditionnementId = (conditionnementId) => {
        return brasserie.conditionnements[conditionnementId].type
    }

   
    if (catalogue) {
        const catalogues = catalogue.getProducts()
        return <>
            {Object.keys(catalogues).map((conditionnementOrderKey) => {
                if (
                    catalogues[conditionnementOrderKey].length === 0 //si le catalogue est vide pour ce conditionnement
                    || !!!filters.conditionnements[getStockTypeFromConditionnementId(conditionnementOrderKey)] //si le conditionnement n'est pas dans les filtres
                    || !filters.conditionnements[getStockTypeFromConditionnementId(conditionnementOrderKey)].visible //si le conditionnement n'est pas visible
                    ) {
                    return null
                } else {
                    return <div key={conditionnementOrderKey}>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h2 className="h4">{brasserie.conditionnements[conditionnementOrderKey].multiple + ' x ' + brasserie.conditionnements[conditionnementOrderKey].nom}</h2>
                        </div>
                        <table className="table">
                            <thead>
                                <tr className="table-secondary">
                                    <th scope="col" className="d-none d-sm-table-cell"></th>
                                    <th scope="col">Nom</th>
                                    <th scope="col" className="d-none d-lg-table-cell">Styles</th>
                                    <th scope="col" className="d-none d-md-table-cell">Alcool</th>
                                    <th scope="col">Prix</th>
                                    <th scope="col">DDM</th>
                                    <th scope="col" className="d-none d-md-table-cell">Stock</th>
                                    <th scope="col">Quantité</th>
                                </tr>
                            </thead>
                            <tbody>{Object.keys(catalogues[conditionnementOrderKey]).map((catalogueOrderKey) => {
                                return catalogues[conditionnementOrderKey][catalogueOrderKey].map((article) => {
                                    if (article.stocks) {
                                        return Object.keys(article.stocks).map((conditionnementId) => {
                                            if (conditionnementId === conditionnementOrderKey) {
                                                const stock = article.stocks[conditionnementId]
                                                /**
                                                 * @todo virer le test sur sotck > 0 et ajouter un pastille dans l'admin sur le menu produit si il y a des produits à checker
                                                 */
                                                if (stock.visibilite && stock.stock > 0
                                                    && filters.conditionnements[getStockTypeFromConditionnementId(conditionnementId)]?.visible
                                                    && filters.catalogues[stock.catalogue]?.visible) {

                                                    return <LigneProduitTableQuantite
                                                        key={article.id + conditionnementId}
                                                        conditionnementId={conditionnementId}
                                                        stock={stock}
                                                        article={article}
                                                        quantity={(commande.quantities[article.id] && commande.quantities[article.id][conditionnementId]) ? commande.quantities[article.id][conditionnementId].quantite : 0}
                                                         />
                                                } 
                                            }
                                        })
                                    }
                                })
                            })}</tbody>
                        </table>
                    </ div>
                }

            })

            }

        </>
    }
}