
import React, { Component, useRef, useState } from "react";
import { Link, useLoaderData, useOutletContext, useParams } from "react-router-dom";

import _ from "lodash"
import { useSnackbar } from 'notistack';
import { matchSorter } from "match-sorter";
import * as math from "mathjs"
import { getUnixTime } from 'date-fns'

import { Typeahead } from 'react-bootstrap-typeahead';



import productService from "../../services/product.service";
import brasserieService from "../../services/brasserie.service";


export const loader = async ({ params }) => {
    try {
        let products;

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        products = respProducts.data;

        return { products: products }

    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function InventairesNew(props) {

    const { brasserie } = useOutletContext();
    const { products } = useLoaderData();

    const typeaheadRef = useRef();
    const { enqueueSnackbar } = useSnackbar();

    const getProduitsActifs = () => {
        const produitsActifs = [];
        for (var i = 0; i < products.length; i++) {
            const product = products[i];
            if (product?.archive) {

            } else {
                if (product.lots && Object.keys(product.lots).length > 0) {
                    for (const numeroDeLot in product.lots) {
                        const lot = product.lots[numeroDeLot];
                        const articles = Object.keys(lot.stocks).map((conditionnementId) => {
                            const newArticle = _.cloneDeep(product);
                            delete newArticle.stocks;
                            delete newArticle.lots
                            console.log("details",product)
                            console.log( { ...product.lots[numeroDeLot]})
                            newArticle.lot = { ...product.lots[numeroDeLot].stocks[conditionnementId], conditionnement: { ...brasserie.conditionnements[conditionnementId],id:conditionnementId}, numero: numeroDeLot }
                            return newArticle
                        })
                        produitsActifs.push(...articles);
                    }
                }
            }
        }
        return produitsActifs
    }

    const initInventaire = (articles) => {
        let quantities = {}

        articles.map((article) => {
            if (!quantities[article.id]) {
                quantities[article.id] = {}
            }
            if (!quantities[article.id][article.lot.numero]) {
                quantities[article.id][article.lot.numero] = {}
            }
            if (!quantities[article.id][article.lot.numero][article.lot.conditionnement.id]) {
                quantities[article.id][article.lot.numero][article.lot.conditionnement.id] = {
                    stockTheorique: article.lot.stock,
                    stockReel: 0,
                    entrees: []
                }
            }
        })
        return quantities;
    }

    const [quantite, setQuantite] = useState("")
    const [isInventairePartiel, setIsInventairePartiel] = useState(false)
    const [isInventaireIncomplet, setIsInventaireIncomplet] = useState(false)
    const [articlesAInventorier, setArticlesAInventorier] = useState(getProduitsActifs())
    const [inventaire, setInventaire] = useState(initInventaire(articlesAInventorier))
    const [article, setArticle] = useState([])
    const [APIError, setAPIError] = useState(null)

    const articleFiltres = (option, props) => {
        const searchTerm = props.text
        if (!searchTerm || !searchTerm.length) {
            return option
        }

        const terms = searchTerm.split(' ')
        if (!terms) {
            return option
        }

        // reduceRight will mean sorting is done by score for the _first_ entered word.
        const filteredArticles = terms.reduceRight(
            (results, term) => matchSorter(results, term, { keys: ['nom', 'stock.conditionnement.nom', 'lot.numero'] }),
            [option],
        )
        return filteredArticles[0]
    }
    const handleChangeNouvelleEntree = (e) => {
        const quantite = e.target.value;
        setQuantite(quantite)
    }

    const handleRaccourciArticle = (e, articleId,numeroDeLot, conditionnementId) => {
        setArticle([articlesAInventorier[_.findIndex(articlesAInventorier, { id: articleId, lot: { numero:numeroDeLot, conditionnement: { id: conditionnementId } } })]])
    }

    const handleChangeArticleSelection = (article) => {
        setArticle(article)
        typeaheadRef.current.clear();
    }

    const handleSubmitNouvelleEntree = (e, article) => {
        e.preventDefault()
        let quantite = e.target[0].value;
        if (quantite.charAt(0) === "=") {
            quantite = math.evaluate(_.trimStart(quantite, '='))
        }

        const newInventaire = _.cloneDeep(inventaire);

        newInventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].entrees.push(parseInt(quantite));
        newInventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].stockReel += parseInt(quantite);

        setInventaire(newInventaire)
        setQuantite("")
        setArticle([])
    }

    const validerInventaire = (inventaire) => {
        const ecarts = {}
        const stocksReels = {}
        for (const articleId in inventaire) {
            ecarts[articleId] = {global:{}}
            stocksReels[articleId] = {global:{}}
            const product = products[_.findIndex(products, { id: articleId })]

            for (const numeroDeLot in inventaire[articleId]){
                ecarts[articleId][numeroDeLot] = {}
                stocksReels[articleId][numeroDeLot] = {}

                for (const conditionnementId in inventaire[articleId][numeroDeLot]) {
                    let Q = 0;
                    let stockTheorique = product.lots[numeroDeLot].stocks[conditionnementId].stock
                   
                   
                    if (inventaire[articleId][numeroDeLot][conditionnementId].entrees.length <= 0) {
    
                    } else {
                        for (let index = 0; index < inventaire[articleId][numeroDeLot][conditionnementId].entrees.length; index++) {
                            Q += inventaire[articleId][numeroDeLot][conditionnementId].entrees[index];
    
                        }
                        if(!_.has(ecarts[articleId].global,conditionnementId)){
                            ecarts[articleId].global[conditionnementId] = Q - stockTheorique
                        }else{
                            ecarts[articleId].global[conditionnementId] += Q - stockTheorique
                        }
                        if(!_.has(stocksReels[articleId].global,conditionnementId)){
                            stocksReels[articleId].global[conditionnementId] = Q
                        }else{
                            stocksReels[articleId].global[conditionnementId] += Q
                        }
                        ecarts[articleId][numeroDeLot][conditionnementId] = Q - stockTheorique
                        stocksReels[articleId][numeroDeLot][conditionnementId] = Q
                    }
                }
                if (Object.keys(ecarts[articleId][numeroDeLot]).length === 0) {
                    delete ecarts[articleId][numeroDeLot]
                }
                if (Object.keys(stocksReels[articleId][numeroDeLot]).length === 0) {
                    delete stocksReels[articleId][numeroDeLot]
                }

            }

            if (Object.keys(ecarts[articleId]).length === 0) {
                delete ecarts[articleId]
            }
            if (Object.keys(stocksReels[articleId]).length === 0) {
                delete stocksReels[articleId]
            }
        }
        const inventaireData = {
            created: getUnixTime(new Date()),
            stocksReels: stocksReels,
            ecarts: ecarts
        }

        brasserieService.addInventaire(brasserie, inventaireData).then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Les stocks ont été mis à jour.', { variant: 'success' })
            } else {

                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            if (error.response.data.error.name === "InventaireError") {
                setAPIError(error.response.data.error.data)
                enqueueSnackbar("Le stock théorique de " + products[_.findIndex(products, { id: error.response.data.error.data.productId })].nom + " est négatif", { variant: 'error' })
            } else {
                enqueueSnackbar(error.message, { variant: 'error' })
            }

        });
    }

    const handleValiderInventaire = () => {
        for (const articleId in inventaire) {
            for (const numeroDeLot in inventaire[articleId]) {
                for (const conditionnementId in inventaire[articleId][numeroDeLot]) {
                    if (inventaire[articleId][numeroDeLot][conditionnementId].entrees.length <= 0) {
                        setIsInventaireIncomplet(true)
                        return
                    } else {

                    }
                }
            }
        }
        validerInventaire(inventaire)
    }


    const handleValiderInventairePartiel = () => {
        const newInventaire = _.cloneDeep(inventaire)
        for (const articleId in inventaire) {
            for (const numeroDeLot in inventaire[articleId]) {
                for (const conditionnementId in inventaire[articleId][numeroDeLot]) {
                    if (inventaire[articleId][numeroDeLot][conditionnementId].entrees.length === 0) {
                        delete newInventaire[articleId][numeroDeLot][conditionnementId]
                    }
                }
            }
        }
        setInventaire(newInventaire)
        setIsInventaireIncomplet(false)
    }

    const handleValiderInventaireAvecMiseAZero = () => {
        const newInventaire = _.cloneDeep(inventaire)
        for (const articleId in inventaire) {
            for (const numeroDeLot in inventaire[articleId]) {
                for (const conditionnementId in inventaire[articleId][numeroDeLot]) {
                    if (inventaire[articleId][numeroDeLot][conditionnementId].entrees.length <= 0) {
                        newInventaire[articleId][numeroDeLot][conditionnementId].entrees.push(0)
                    }
                }
            }
        }
        setInventaire(newInventaire)
        setIsInventaireIncomplet(false)
    }


    return (
        <div className="row">
            <div className="col-sm-12 my-3">
                {APIError && <div className="alert alert-danger" role="alert">
                    Une commande de {APIError.order.client.facturation.nom} pose problème. Elle contient trop de {products[_.findIndex(products, { id: APIError.productId })].nom} en {brasserie.conditionnements[APIError.conditionnementId].nom}. <Link to={"/admin/" + brasserie.id + "/commandes/" + APIError.order.id} target="_blank" className="alert-link">Modifier la commande</Link> et revalider l'inventaire.
                </div>}
                <Typeahead
                    id="basic-typeahead-single"
                    labelKey={option => `${option.nom} ${option.lot.conditionnement.nom}`}
                    onChange={handleChangeArticleSelection}
                    options={articlesAInventorier}
                    placeholder="Choisir un article"
                    filterBy={articleFiltres}
                    ref={typeaheadRef}
                    renderMenuItemChildren={(option) => (
                        <>
                            <img
                                alt={option.nom}
                                src={option.image}
                                style={{
                                    height: '24px',
                                    marginRight: '10px',
                                    width: '24px',
                                }}
                            />
                            <span>{option.nom} - {option.lot.conditionnement.nom} - N° : {option.lot.numero}</span>
                        </>
                    )}
                />

            </div>
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-8">
                    {article.length > 0 &&
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <img src={article[0].image} className="img-thumbnail rounded mx-auto d-block img-fluid" alt={article[0].nom} />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-6">
                                <h2>{article[0].nom}</h2>
                                <h4 className="text-muted"> {article[0].lot.conditionnement.nom}</h4>
                                <ul className="list-group mt-4 mb-2">
                                    <li className="list-group-item d-flex justify-content-between bg-light">
                                        <div>
                                            <strong className="my-0">Stock théorique :</strong>
                                        </div>
                                        <strong>{article[0].lot.stock} unités</strong>
                                    </li>
                                    {inventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].entrees.map((entree, index) => {
                                        return <li key={index} className="list-group-item d-flex justify-content-between lh-sm">
                                            <div>
                                                <h6 className="my-0">Entrée :</h6>
                                            </div>
                                            <span className="text-muted">{entree} unités</span>
                                        </li>
                                    })}
                                    {inventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].entrees.length > 0
                                        && <>
                                            <li className="list-group-item d-flex justify-content-between bg-light">
                                                <div>
                                                    <strong className="my-0">Stock réel :</strong>
                                                </div>
                                                <strong>{inventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].stockReel}  unités</strong>
                                            </li>
                                            <li className={parseInt(article[0].lot.stock) === parseInt(inventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].stockReel) ? "list-group-item d-flex justify-content-between bg-success text-dark bg-opacity-25" : "list-group-item d-flex justify-content-between bg-danger text-dark bg-opacity-25"}>
                                                <div>
                                                    <strong className="my-0">Ecart :</strong>
                                                </div>
                                                <strong>{inventaire[article[0].id][article[0].lot.numero][article[0].lot.conditionnement.id].stockReel - article[0].lot.stock}  unités</strong>
                                            </li>

                                        </>
                                    }
                                </ul>
                                <hr />
                                <form onSubmit={(e) => handleSubmitNouvelleEntree(e, article)} className="d-grid gap-2">
                                    <input id="nouvelleEntree" type="text" className="form-control" placeholder="Quantité (327) ou formule (=12*19+8)" value={quantite} onChange={(e) => handleChangeNouvelleEntree(e)} />
                                    <button className="btn btn-primary " type="sumbit">Ajouter une entrée</button>
                                </form>
                            </div>
                        </div>
                    }

                </div>
                <div className="col-sm-12 col-md-12 col-lg-4">
                    <div className="d-flex mb-2 justify-content-between">
                        <h2 className="me-auto">Inventaire </h2>
                        <button className="btn btn-primary btn-sm py-0" onClick={(e) => handleValiderInventaire(e)}>Valider</button>
                    </div>
                    {isInventaireIncomplet
                        && <div className="alert alert-warning" role="alert">
                            <h4 className="alert-heading">Inventaire incomplet!</h4>
                            <p>Certains articles n'ont aucune entrée. Vous pouvez valider un inventaire partiel (aucune entrée ne sera créer pour les articles qui manquent à l'appel) ou créer une entrée à 0 pour tous ces articles . </p>
                            <hr />
                            <div className="hstack">
                                <button type="button" className="btn btn-warning me-auto" onClick={(e) => handleValiderInventairePartiel(e)}>Inventaire partiel</button>
                                <button type="button" className="btn btn-warning" onClick={(e) => handleValiderInventaireAvecMiseAZero(e)}>Mettre à 0</button>
                            </div>

                        </div>

                    }

                    <style dangerouslySetInnerHTML={{
                        __html: [
                            '.accordion-button:after {',
                            '  margin-left: 3px;',
                            '}'
                        ].join('\n')
                    }}>
                    </style>
                    <div className="accordion" id="recapitulatifInventaire">
                        {
                            Object.keys(inventaire).map((articleId) => {

                                return Object.keys(inventaire[articleId]).map((numeroDeLot) => {
                                    return Object.keys(inventaire[articleId][numeroDeLot]).map((conditionnementId) => {
                                        return (
                                            <div className="accordion-item" key={articleId + numeroDeLot + conditionnementId}>
                                                <h2 className="accordion-header" id={articleId + numeroDeLot + conditionnementId}>
                                                    <button className="accordion-button collapsed justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target={"#id" + articleId + numeroDeLot + conditionnementId} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne" onClick={(e) => handleRaccourciArticle(e, articleId,numeroDeLot, conditionnementId)}>

                                                        <div className="flex-shrink-1 me-auto">
                                                            <span>{products[_.findIndex(products, { id: articleId })].nom} - {brasserie.conditionnements[conditionnementId].nom}</span> 
                                                            <hr className="my-1"/>
                                                            <span className="text-muted">Lot : {numeroDeLot}</span>
                                                        </div>
                                                        {(inventaire[articleId][numeroDeLot][conditionnementId].entrees.length > 0) ? <span className="badge text-bg-primary">{inventaire[articleId][numeroDeLot][conditionnementId].stockReel}</span> : <span className="badge text-bg-warning">A faire</span>}

                                                    </button>
                                                </h2>
                                                <div id={"id" + articleId + numeroDeLot + conditionnementId} className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" data-bs-parent="#recapitulatifInventaire">
                                                    {inventaire[articleId][numeroDeLot][conditionnementId].entrees.length > 0 && <div className="accordion-body">
                                                        <ul>
                                                            {inventaire[articleId][numeroDeLot][conditionnementId].entrees.map((entree, index) => {
                                                                return <li key={index}>Entrée : {entree} unités</li>
                                                            })}

                                                        </ul>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                })
                            })
                        }
                    </div>
                </div>
            </div>
        </div>


    )

}