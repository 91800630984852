
import React, { Component, useState } from "react";
import { useLoaderData, useOutletContext, useParams } from "react-router-dom";

import _ from "lodash"

import EditProduct from "./Produit/edit";
import ListProducts from "../../features/admin/produits/list";

import LinearProgress from '@mui/material/LinearProgress';

import productService from "../../services/product.service";
import { useBrasserie } from "../../hooks/UseBrasserie";


export const loader = async ({ params }) => {
    try {
        let products;

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        products = respProducts.data;

        //order products by name
        products = _.orderBy(products, ['nom'], ['asc']);

        return { products: products }

    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function Produits(props) {

    const { productId } = useParams();
    const { brasserie } = useOutletContext();
    const { products } = useLoaderData();

    if (true) {
        console.log('productId',productId)
        return (
            <div className="row">
                <div className="col-sm-12">

                    {productId === 'new' && <EditProduct  />}
                    {productId && productId !== 'new' && <EditProduct product={products[_.findIndex(products, ['id', productId])]} />}
                    {!productId && <ListProducts brasserie={brasserie} products={products} />}
                </div>
            </div>

        )
    }else{
        return <LinearProgress />
    }
   
}