
import React, { useContext } from "react";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { Commande } from "../../model/Commande.model";
import { SessionContext } from "../../contexts/SessionContext";


export default function Recapitulatif({ commande, brasserie, client, catalogue }) {

    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin


    const renderPopover = (props) => (
        <Popover id="popover-contained" {...props}>
            <Popover.Header as="h3">Frais de transport</Popover.Header>
            <Popover.Body>
                Ils peuvent être financés par la marge entre votre prix d'achat et les coûts de fabrication.
                <hr />Afin d'être le plus avantageux pour vous, et parce que nos marges sont différentes sur les fûts et les bouteilles, nous calculons le FRANCO en fonction de la composition de votre commande.
                <hr />Dans la même logique, nous vous proposons une participation partielle aux frais de port à partir de la moitié du FRANCO.
            </Popover.Body>
        </Popover>
    );

    const catalogues = catalogue.getProducts();
    return (
        <>
            <ul className="list-group mb-3">
                {Object.keys(catalogues).map((conditionnementOrderKey) => {
                    if (catalogues[conditionnementOrderKey].length === 0) {
                        return null
                    } else {
                        let totalConditionnement = 0
                        let totalPricePerConditionnement = 0
                        const listToRender = Object.keys(catalogues[conditionnementOrderKey]).map((catalogueOrderKey) => {
                            
                            return catalogues[conditionnementOrderKey][catalogueOrderKey].map((article) => {
                                if (article.stocks) {
                                    return Object.keys(article.stocks).map((conditionnementId) => {
                                        if (conditionnementId === conditionnementOrderKey && commande.quantities[article.id] && commande.quantities[article.id][conditionnementId]) {
                                            const stock = article.stocks[conditionnementId]
                                            const Qorder = commande.quantities[article.id][conditionnementId].quantite
                                            totalConditionnement += Qorder
                                            const lots = commande.quantities[article.id][conditionnementId].lots
                                            if (Qorder > 0) {
                                                const P = commande.tarifs[article.id][conditionnementId].prix
                                                const totalLigne = Math.round(P * Qorder * 100) / 100
                                                totalPricePerConditionnement += totalLigne
                                                return <li key={article.id + conditionnementId+conditionnementOrderKey+catalogueOrderKey} className="list-group-item d-flex justify-content-between lh-sm">
                                                    <div>
                                                        <h6 className="my-0">{article.nom}</h6>
                                                        {lots.map((lot) => {
                                                            if (lot.quantite > 0) {
                                                                return <div key={lot.numero}>
                                                                    <small className="text-muted"><b>{lot.quantite}</b> x lot {lot.numero} - {P}€HT</small>
                                                                </div>
                                                            } else {
                                                                return null
                                                            }
                                                        })
                                                        }
                                                    </div>
                                                    <span className="text-muted">{totalLigne}€</span>
                                                </li>
                                            } else {
                                                return null
                                            }
                                        } else {
                                            return null
                                        }
                                    })
                                }
                            })
                        })
                        if(totalConditionnement > 0) {
                            return <>
                             {listToRender}
                             <li key="default" className="list-group-item d-flex justify-content-between list-group-item-warning">
                                <div >
                                    <strong className="my-0">Quantité {brasserie.conditionnements[conditionnementOrderKey].nom} : </strong>
                                </div>
                                { <strong>{totalConditionnement/brasserie.conditionnements[conditionnementOrderKey].multiple + ' x ' +brasserie.conditionnements[conditionnementOrderKey].multiple+' = ' +totalConditionnement}</strong> }
                                 {/* <strong>{Math.round(totalPricePerConditionnement * 100) / 100}€</strong> */} 
                            </li> 
                            </>
                        }else {
                            return null
                        }

                    }
                })}
                <li className="list-group-item d-flex justify-content-between list-group-item-dark">
                    <div>
                        <strong className="my-0">Sous-total HT : </strong>
                    </div>
                    <strong>{commande.sousTotal}€</strong>
                </li>
                {commande.statut < Commande.STATUT_VALIDEE &&  <>
                    {(isAdmin || commande.port.offert) && <>
                        <li className={"list-group-item d-flex justify-content-between lh-sm " + (commande.port.offert && "list-group-item-success")}>
                            <div>
                                <h6 className="my-0">Frais de port offerts</h6>
                                <small className="text-muted"></small>
                            </div>
                            <span className="text-muted">0€</span>
                        </li>
                        </>}
                        <li className={"list-group-item d-flex justify-content-between lh-sm " + (commande.port.retrait && "list-group-item-success")}>
                            <div>
                                <h6 className="my-0">Retrait à la brasserie</h6>
                                <small className="text-muted">Merci de convenir d'un rendez-vous pour le retrait de la marchandise.</small>
                            </div>
                            <span className="text-muted">{commande.port.remise}€</span>
                        </li>
                        {(client.tournee !== "" && client.tournee !== "aucune") && <>
                        <li className={"list-group-item d-flex justify-content-between lh-sm " + (commande.port.tournee && "list-group-item-success")}>
                            <div>
                                <h6 className="my-0">Tournée de livraison</h6>
                                <small className="text-muted">FRANCO à {commande.port.francoTournee}€HT</small>
                            </div>
                            <span className="text-muted">{commande.port.participationTournee}€</span>
                        </li>
                        </>}

                        <li className={"list-group-item d-flex justify-content-between lh-sm " + (commande.port.expedition && "list-group-item-success")}>
                            <div>
                                <h6 className="my-0">Transporteur
                                    <OverlayTrigger
                                        placement="left"
                                        containerPadding={20}
                                        overlay={renderPopover}
                                    >
                                        <i className="fas fa-question-circle ms-1"></i>

                                    </OverlayTrigger></h6>
                                <small className="text-muted">{commande.port?.franco > 0 && <>Dégressif à partir de {commande.port.franco / 2}€HT</>}<br />{commande.port?.franco > 0 && <>Offert à partir de {commande.port.franco}€HT</>}</small>
                            </div>
                            <span className="text-muted">{commande.port.participation}€</span>
                        </li>

                    </>}
                {//retrait à la brasserie
                    commande.statut >= Commande.STATUT_VALIDEE
                    && commande.port.retrait
                    && commande.sousTotal > 0
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Retrait à la brasserie</h6>
                            <small className="text-muted">Merci de convenir d'un rendez-vous pour le retrait de la marchandise.</small>
                        </div>
                        <span className="text-muted">{commande.port.remise}€</span>
                    </li>
                }
                {//port offert
                    commande.statut >= Commande.STATUT_VALIDEE
                    && commande.port.offert
                    && commande.sousTotal > 0
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Frais de port offerts</h6>
                            <small className="text-muted"></small>
                        </div>
                        <span className="text-muted">0€</span>
                    </li>
                }
                {
                    commande.statut >= Commande.STATUT_VALIDEE
                    && commande.port.tournee //tournée de livraison
                    && commande.sousTotal > 0 //commande non vide
                    && commande.port.francoTournee <= commande.sousTotal // franco atteint
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Tournée de livraison</h6>
                            <small className="text-muted"></small>
                        </div>
                        <span className="text-muted">{commande.port.participationTournee}€</span>
                    </li>
                }
                {
                    commande.statut >= Commande.STATUT_VALIDEE
                    && commande.port.tournee //tournée de livraison
                    && commande.sousTotal > 0 //commande non vide
                    && commande.port.francoTournee > commande.sousTotal // franco non atteintatteint
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Tournée de livraison</h6>
                            <small className="text-muted">Minimum de commande ({commande.port.francoTournee}€) non atteint.</small>
                        </div>
                        <span className="text-muted">{commande.port.participationTournee}€</span>
                    </li>
                }

                {
                    commande.statut >= Commande.STATUT_VALIDEE
                    && !commande.port.tournee //pas de tournee de livraison
                    && !commande.port.retrait //pas de retrait à la brasserie
                    && !commande.port.offert //pas de frais de port offert
                    && commande.port.franco === 0 //franco à 0 = pas de marge residuelle (prix distri)
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Frais de port</h6>
                        </div>
                        <span className="text-muted">{Math.round(commande.port.cout * 100) / 100}€</span>
                    </li>
                }
                {
                    commande.statut >= Commande.STATUT_VALIDEE
                    && !commande.port.tournee //pas de tournée de livraison
                    && !commande.port.retrait //pas de retrait à la brasserie
                    && !commande.port.offert //pas de frais de port offert
                    && commande.sousTotal > 0 //commande non vie
                    && commande.port.franco <= commande.sousTotal // franco atteint
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">FRANCO</h6>
                        </div>
                        <span className="text-muted">0€</span>
                    </li>
                }
                {
                    commande.statut >= Commande.STATUT_VALIDEE
                    && !commande.port.tournee //pas de tournée de livraison
                    && !commande.port.retrait //pas de retrait à la brasserie
                    && !commande.port.offert //pas de frais de port offert
                    && commande.sousTotal > 0 //commande non vide
                    && commande.port.franco > commande.sousTotal //franco non atteint
                    && commande.sousTotal < commande.port.franco / 2 //mini de commande non atteint
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Frais de port au réel</h6>
                        </div>
                        <span className="text-muted">{commande.port.cout}€</span>
                    </li>
                }
                {
                    commande.statut >= Commande.STATUT_VALIDEE
                    && !commande.port.tournee //pas de tournée de livraison
                    && !commande.port.retrait //pas de retrait à la brasserie
                    && !commande.port.offert //pas de frais de port offert
                    && commande.sousTotal > 0 //commande non vide
                    && commande.port.franco > commande.sousTotal //franco non atteint
                    && commande.sousTotal > commande.port.franco / 2 //mini de commande atteint donc participation aux frais de port
                    && <li className="list-group-item d-flex justify-content-between lh-sm">
                        <div>
                            <h6 className="my-0">Participation aux frais de port</h6>
                        </div>
                        <span className="text-muted">{commande.port.participation}€</span>
                    </li>
                }
                <li className="list-group-item d-flex justify-content-between list-group-item-dark">
                    <div>
                        <strong className="my-0">Total HT : </strong>
                    </div>
                    <strong>{Math.round((commande.sousTotal + (commande.port.sousTotal)) * 100) / 100}€</strong>
                </li>
            </ul>
        </>
    );
}
