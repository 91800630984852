
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsMore from 'highcharts/highcharts-more';
import _ from "lodash";

HighchartsSunburst(Highcharts);
HighchartsMore(Highcharts);

export function createDataSeries(data, brasserie, produits, initZeroLevel) {
    const dataSeries = []
    const zeroLevel = initZeroLevel || [{
        name: brasserie.nom,
        id: brasserie.id,
        color: "#fff",
    }]
    console.log("zeroLevel", zeroLevel, initZeroLevel)
    const firstLevel = []
    const secondLevel = []
    const thirdLevel = []
    const fourthLevel = []
    const fifthLevel = []

    let firstCommandeCreated = null

    for (let index = 0; index < data.length; index++) {
        const commande = data[index];
        const commandeValidated = _.find(commande.events, { 'nom': 'création' })?.timestamp*1000
        const firstDayOf2023 = new Date("2023-01-01").getTime()
        if (commandeValidated > firstDayOf2023) {


            if (!firstCommandeCreated) {
                firstCommandeCreated = commandeValidated
            } else {
                if (commandeValidated < firstCommandeCreated) {
                    firstCommandeCreated = commandeValidated
                }

            }

            //1er niveau : grille tarifaire
            const grilleTarifaireName = brasserie.pricings[commande.client.pricing].nom || "Sans grille tarifaire"
            const grilleTarifaireId = commande.client.pricing || "Sans grille tarifaire"
            //2eme niveau : client
            const clientName = commande.client.livraison.nom || "Sans client"
            const clientId = commande.client.id + "" + grilleTarifaireId || "Sans client"
            //3eme niveau : catalogue
            //4eme niveau : produit
            //5eme niveau : conditionnement
            const orderCreated = _.find(commande.events, { 'nom': 'validation' })?.timestamp

            for (const productId in commande.quantities) {
                if (Object.hasOwnProperty.call(commande.quantities, productId)) {
                    const productQuantities = commande.quantities[productId];
                    const product = produits.find((produit) => produit.id === productId)

                    for (const conditionnementId in productQuantities) {
                        if (Object.hasOwnProperty.call(productQuantities, conditionnementId)) {
                            const detailsVente = productQuantities[conditionnementId];
                            if (detailsVente.quantite > 0) {
                                const catalogueName = (product.stocks[conditionnementId].catalogue ? brasserie.catalogues[product.stocks[conditionnementId]?.catalogue]?.nom : "Sans catalogue")
                                const catalogueId = catalogueName || "Sans catalogue"
                                const productName = product.nom || "Sans nom"
                                const productId = product.id + "" + catalogueId
                                const conditionnementName = brasserie.conditionnements[conditionnementId]?.nom || "Sans conditionnement"
                                //const value = Math.round(detailsVente.quantite * _.toNumber(detailsVente.prix) * 100) / 100
                                const value = Math.round(detailsVente.quantite * brasserie.conditionnements[conditionnementId].volume * 100) / 100

                                if (!thirdLevel.find((catalogue) => (catalogue.id === catalogueId && catalogue.parent === zeroLevel[0].id))) {

                                    thirdLevel.push({
                                        name: catalogueName,
                                        id: catalogueId,
                                        parent: zeroLevel[0].id
                                    })
                                    fourthLevel.push({
                                        name: productName,
                                        id: productId,
                                        parent: catalogueId
                                    })
                                    fifthLevel.push({
                                        name: conditionnementName,
                                        id: conditionnementId,
                                        parent: productId,
                                        value: value
                                    })


                                } else {
                                    if (!fourthLevel.find((produit) => (produit.id === productId && produit.parent === catalogueId))) {
                                        fourthLevel.push({
                                            name: productName,
                                            id: productId,
                                            parent: catalogueId
                                        })
                                        fifthLevel.push({
                                            name: conditionnementName,
                                            id: conditionnementId,
                                            parent: productId,
                                            value: value
                                        })
                                    } else {
                                        if (!fifthLevel.find((conditionnement) => (conditionnement.id === conditionnementId && conditionnement.parent === productId))) {
                                            fifthLevel.push({
                                                name: conditionnementName,
                                                id: conditionnementId,
                                                parent: productId,
                                                value: value
                                            })
                                        } else {
                                            const conditionnement = fifthLevel.find((conditionnement) => (conditionnement.id === conditionnementId && conditionnement.parent === productId))
                                            conditionnement.value += value
                                        }
                                    }
                                }
                            }


                        }
                    }


                }
            }

        }
    }
    console.log(fifthLevel)
    //log firstCommandeCreated as a french date
    firstCommandeCreated = new Date(firstCommandeCreated ).toLocaleDateString('fr-FR')
    console.log("firstCommandeCreated", firstCommandeCreated)

    return _.concat(zeroLevel, firstLevel.sort((a, b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)), secondLevel.sort((a, b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)), thirdLevel.sort((a, b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)), fourthLevel.sort((a, b) => ((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)), fifthLevel)
}

export function getOptions(data, brasserie, produits, initZeroLevel) {
    const series = createDataSeries(data, brasserie, produits, initZeroLevel)
    return {
        chart: {
            type: 'sunburst',
            height: '600px'
        },
        title: {
            text: 'Répartition des ventes'
        },
        series: [{
            type: 'sunburst',
            data: series,
            name: 'Root',
            allowDrillToNode: true,
            cursor: 'pointer',
            dataLabels: {
                format: '{point.name}',
                filter: {
                    property: 'innerArcLength',
                    operator: '>',
                    value: 16
                },
                rotationMode: 'circular'
            },
            levels: [{
                level: 1,
                levelIsConstant: false,
                dataLabels: {
                    filter: {
                        property: 'outerArcLength',
                        operator: '>',
                        value: 64
                    }
                }
            }, {
                level: 2,
                colorByPoint: true
            },
            {
                level: 3,
                colorVariation: {
                    key: 'brightness',
                    to: -0.5
                }
            }, {
                level: 4,
                colorVariation: {
                    key: 'brightness',
                    to: 0.5
                }
            }]

        }],
        tooltip: {
            headerFormat: '',
            valueDecimals: 2,
            pointFormat: '{point.name}: <b>{point.value}l</b>'
        }
    }
}

export function VenteRepartition({ brasserie, produits, data, initZeroLevel = null }) {

    const chart = <HighchartsReact highcharts={Highcharts} options={getOptions(data, brasserie, produits, initZeroLevel)} allowChartUpdate={false} />
    return chart
}