import React, { useState } from "react";
import clientService from "../services/client.service";

//timestampStart and timestampUpdated are used to know when the session started and when it was last updated
//timestampEnd is used to know when the session will expire and it is calculated from timestampStart and timestampUpdated
//the validity is 30 minutes
//this is used to know if the session is still valid
//if the session is not valid, we start a new session
//if the session is valid, we update the timestampUpdated and the timestampEnd
//if the session is valid, we update the session on the server


const initialState = {
  isAdmin: false,
  isAdminLoggedIn: false,
  client: null,
  contact: null,
  brasserie: null,
  timestampStart: new Date().getTime(),
  timestampUpdated: new Date().getTime(),
  timestampEnd: new Date().getTime(),
};

const SessionContext = React.createContext(initialState);

function SessionProvider({ children }) {
  const key = "session"
  const session = JSON.parse(localStorage.getItem(key)) || initialState;
  /* const [session, setSession] = useLocalStorage("session", initialState); */
  const updateSession = (sessionWhy) => {
    const session = JSON.parse(localStorage.getItem(key)) || initialState;
    const now = new Date().getTime();
    if (session) {
      //merge sessionWhy with session
      const currentSession = {
        ...session,
        ...sessionWhy,
      };
      if (now >= session.timestampEnd) {
        const newSession = {
          ...currentSession,
          timestampStart: now,
          timestampUpdated: now,
          timestampEnd: now + 30 * 60000,
        };
        localStorage.setItem(key, JSON.stringify(newSession));
        if(!session.isAdmin && session.brasserie && session.client && session.contact){
          const event = {
            context: "session",
            nom: "connexion",
            source: session.contact.prenom + " " + session.contact.nom[0],
            timestamp: now,
            data: {
              session: newSession,
            },
          };
          clientService.addEvent(session.brasserie,session.client,event)
        }
       
      } else {
/*         console.log("session valid");
 */        const newSession = {
          ...currentSession,
          timestampUpdated: now,
          timestampEnd: now + 30 * 60000,
        };
        localStorage.setItem(key, JSON.stringify(newSession));
      }
    }
  }

  return (
    <SessionContext.Provider
      value={{
        session,
        updateSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
}

export { SessionProvider, SessionContext };
