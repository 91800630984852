import { useEffect, useState } from "react"
import { useOutletContext } from "react-router-dom"
import CommandeProvider, {  } from "../../contexts/CommandeProvider"
import Loader from "../../components/Loader"
import clientService from "../../services/client.service"
import productService from "../../services/product.service"
import { Catalogue } from "../../model/Catalogue.model"
import transporteurs from "../../data/transporteurs"
import BonDeCommande from "../../components/bonDeCommande"

export default function Test(props) {
    // faire un bon de commande avec le context et le reducer

    const [client, setClient] = useState(null)
    const [products, setProducts] = useState(null)
    const [isLoading, setIsLoading] = useState(2)

    const idCommande = "xzV6XKp5EzkflVeAkLM3"
    const clientId = "RdCptJnHEryIJF6YxHV5"
    const { brasserie } = useOutletContext();

    useEffect(() => {
        let loading = isLoading
        const getClient = () => {
            clientService.getById(brasserie.id, clientId)
                .then(res => {
                    const client = res.data

        client.transporteur = transporteurs.getBestTransporteurForDepartement(Math.trunc(client.livraison.codePostal / 1000))
                    setClient(res.data)
                    loading--
                    setIsLoading(loading)
                })
        }
        const getProducts = () => {
            productService.getByBrasserieId(brasserie.id)
                .then(res => {
                    setProducts(res.data)
                    loading--
                    setIsLoading(loading)
                })
        }
        if(isLoading > 0){
            getProducts()
            getClient()
        }
    }, [])


    const catalogue = new Catalogue(brasserie)
    if(products && client && !!!isLoading){
        catalogue.setRawProducts(products)
        catalogue.setClient(client)
    }

    return (
       <>
            <h1>Test</h1>
            {!!isLoading && <Loader />}

            {!!!isLoading &&  
            <CommandeProvider initialState={{
                client: client,
                catalogue: catalogue,
                commande: null,
                filters:  { conditionnements: {}, catalogues: {} },
                loading: false,
                error: null,
                brasserie: brasserie
            }}>
                <BonDeCommande id={idCommande}  />
            </CommandeProvider>}
        </>

    )
}
