import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DollarSign } from "react-feather";
import productService from "../../../services/product.service";
import _ from "lodash";
import Loader from "../../../components/Loader";
import * as echarts from "echarts";
import { aggregate } from "@manufac/echarts-simple-transform";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getWeek, format } from 'date-fns'

import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import { useOutletContext } from "react-router-dom";

// Register the required components
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, PieChart, CanvasRenderer, DatasetComponent,
        TransformComponent, ToolboxComponent, LegendComponent]
);

echarts.registerTransform(aggregate);

export default function StatistiquesVentesProduit({ productId }) {

    //get product statistiques by props productId and session brasserieId
    //use http.get to get statistiques
    //useEffect to get statistiques
    // endpoint is /brasseries/:brasserieId/produits/:id/statistiques
    // return statistiques

    const [statistiques, setStatistiques] = useState([])
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const { brasserie } = useOutletContext()
    console.log(brasserie)
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        title:
            [
                {
                    text: 'CA & Volume par conditionnement',
                    left: '22.5%',
                    top: '5%',
                    textStyle: {
                        color: '#495057',
                        fontSize: 12
                    },
                    textAlign: 'center'
                },
                {
                    text: 'Top client par CA',
                    left: '74.5%',
                    top: '5%',
                    textStyle: {
                        color: '#495057',
                        fontSize: 12
                    },
                    textAlign: 'center'
                }, {
                    /*   textAlign: 'center',
                      text: "Evolution du Volume et de la Valorisation",
                      left: '25%',
                      top:'5%',
                      textStyle: {
                          color: '#495057'
                      }, */
                }

            ],
        legend: {},
        xAxis: [{ type: 'category', gridIndex: 0 }, { type: 'category', gridIndex: 1,max:4, axisLabel: { interval: 0, rotate: 45 } }],
        yAxis: [{ type: 'value', gridIndex: 0 }, { type: 'value', gridIndex: 1 }],
        grid: [{ left: '5%', width: "40%",height: "80sh %" }, { left: '55%', width: "40%" }],
    };



    useEffect(() => {
        setLoading(true)
        const getStatistiques = async () => {
            productService.getStatistiquesVentes(brasserie.id, productId).then((response) => {

                setStatistiques(response.data.filter((stat) => stat.productId === productId))
                //log les items dont la date est "18-2-2023"
                console.log(statistiques)
                setLoading(false)

            }).catch((err) => {
                console.log(err)
                setError(err)
                setLoading(false)
            })

        }
        getStatistiques()
    }, [brasserie, productId])



    console.log("test", statistiques.length, loading, !loading && statistiques.length === 0)
    if (statistiques.length === 0) {
        console.log("pas de statistiques")
    } else {
        console.log("statistiques")
        const conditionnements = _.uniq(statistiques.map((stat) => stat.conditionnementName))

        option['series'] = _.flatten(_.concat({
            type: 'line',
            datasetIndex: 1,
            xAxisIndex: 0,
            yAxisIndex: 0,
            emphasis: {
              focus: 'series'
            },
        }, {
            type: 'line',
            datasetIndex: 1,
            xAxisIndex: 0,
            yAxisIndex: 0,
            emphasis: {
              focus: 'series'
            },
        }, conditionnements.map((conditionnement, index) => {
            return [{
                name:"CA " + conditionnement,
                type: 'bar',
                stack: 'CA',
                datasetIndex: index + 3,
                xAxisIndex: 0,
                yAxisIndex: 0,
                emphasis: {
                  focus: 'series'
                },
                encode: {
                    x: 'week',
                    y: 'CA'
                }

            }
                , {
                    name:"Volume " + conditionnement,
                type: 'bar',
                stack: 'Volume',
                datasetIndex: index + 3,
                xAxisIndex: 0,
                yAxisIndex: 0,
                emphasis: {
                  focus: 'series'
                },
                encode: {
                    x: 'week',
                    y: 'volume'
                }

            }
            ]
        }), {
            type: 'bar',
            datasetIndex: 2,
            xAxisIndex: 1,
            yAxisIndex: 1,
            emphasis: {
              focus: 'series'
            }
        }))

        console.log("series", option['series'])


        option['dataset'] = _.concat({
            source: statistiques,
            dimension: ['week', 'CA', 'volume', 'conditionnementName'],

        }, {
            transform: [
                {
                    type: 'sort',
                    config: { dimension: 'timestamp', order: 'asc' }
                },
                {
                    type: 'ecSimpleTransform:aggregate',
                    config: {
                        resultDimensions: [
                            { from: 'week' },
                            { from: 'CA', method: 'sum' },
                            { from: 'volume', method: 'sum' },
                        ],
                        groupBy: 'week'
                    },
                    print: true
                }
            ]
        }, {
            transform: [
               
                {
                    type: 'ecSimpleTransform:aggregate',
                    config: {
                        resultDimensions: [
                            { from: 'clientName' },
                            { from: 'CA', method: 'sum' },
                            { from: 'volume', method: 'sum' },
                        ],
                        groupBy: 'clientName'
                    },
                    print: true
                }, {
                    type: 'sort',
                    config: { dimension: 'CA', order: 'desc' }
                },
            ]
        }
            , conditionnements.map((conditionnement) => {
                return {
                    transform: [
                        {
                            type: 'filter',
                            config: {
                                dimension: 'conditionnementName',
                                value: conditionnement
                            },
                        }, {
                            type: 'sort',
                            config: { dimension: 'timestamp', order: 'asc' }
                        },
                        {
                            type: 'ecSimpleTransform:aggregate',
                            config: {
                                resultDimensions: [
                                    { from: 'week' },
                                    { from: 'CA', method: 'sum' },
                                    { from: 'volume', method: 'sum' },
                                    { from: 'conditionnementName' },
                                ],
                                groupBy: 'week'
                            },
                            print: true
                        }
                    ]
                }

            }))
        console.log("dataset", option.dataset)
    }


    return (<>
        {loading && <Loader />}
        {!loading &&
            <>
                {/* <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Statistiques des 30 derniers jours</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).volume} l</h3>

                                                <p className="mb-2">Volume</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).valorisation} €HTDI</h3>

                                                <p className="mb-2">Valorisation</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).valorisation / 2} €HTDI</h3>

                                                <p className="mb-2">Chiffre d'affaire</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).valorisation / 2} €HTDI</h3>

                                                <p className="mb-2">Marge nette</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>


                    </Card.Body>
                </Card> */}
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Evolution des ventes</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <ReactEChartsCore echarts={echarts} option={option} style={{ height: '90vh', width: '100%' }} />
                        </Row>


                    </Card.Body>
                </Card>
            </>
        }
    </>)
}