
import { useState } from "react";

import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import clientService from "../../services/client.service";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { MyTextInput } from ".";
import sireneService from "../../services/sirene.service";

const FacturationForm = ({ source, brasserie, client, setClient, closeModal, ...props }) => {



  const { enqueueSnackbar } = useSnackbar();

  const [localClient, setLocalClient] = useState(_.cloneDeep(client))
  const [etablissements, setEtablissements] = useState([])
  const [etablissementsLookedUp, setEtablissementsLookedUp] = useState(false)


  const getEtablissements = (siren) => {
    sireneService.getEtablissementsBySiren(siren).then(resp => {
      console.log(resp)
      if (resp.status === 200) {
        const etablissements = resp.data.records.map(record => {
          const et = { ...sireneService.extractDataFromRecord(record), siren: siren }
          return et
        })
        setEtablissements(etablissements)

        setEtablissementsLookedUp(true)

      }
    })
  }

  const handleSelectionEtablissement = (e) => {

    let newLocalClient = _.cloneDeep(client)
    newLocalClient.facturation.siret = etablissements[e.target.value].siret
    newLocalClient.facturation.nom = (etablissements[e.target.value].nom) ? etablissements[e.target.value].nom : etablissements[e.target.value].nomCommercial
    newLocalClient.facturation.adresse = etablissements[e.target.value].adresse
    newLocalClient.facturation.codePostal = etablissements[e.target.value].codePostal
    newLocalClient.facturation.ville = etablissements[e.target.value].ville
    newLocalClient.siren = etablissements[e.target.value].siren
    setLocalClient(newLocalClient)
  }

  if (!etablissementsLookedUp) {
    let siren = '';
    if (client?.siren && client.siren !== '') {
      siren = client.siren
      console.log('siren', siren)
      getEtablissements(siren)
    } else if (client.facturation.siret !== '') {
      siren = _.truncate(client.facturation.siret, { length: 9, omission: '' })
      console.log('siret facturation', siren)
      getEtablissements(siren)
    } else if (client.livraison.siret !== '') {
      siren = _.truncate(client.livraison.siret, { length: 9 })
      getEtablissements(siren)
    } else {
      setEtablissements([])
      setEtablissementsLookedUp(true)
    }

  }

  return (
    <Formik
      initialValues={localClient || clientService.DEFAULT_CLIENT}
      enableReinitialize={true}
      validationSchema={
        Yup.object({
          facturation:
            Yup.object({
              nom: Yup.string().required('Le nom de la société est obligatoire pour une belle facture'),
              adresse: Yup.string().required('L\'adresse est obligatoire'),
              codePostal: Yup.number().required('Une adresse sans code postal? Un petit effort ;)'),
              ville: Yup.string().required('On en voit le bout!'),
            })

        })
      }
      onSubmit={(values, { setSubmitting }) => {
        clientService.updateClientPartial(brasserie, values, 'facturation').then(resp => {
          if (resp.status === 200) {
            enqueueSnackbar('Données de facturation mises à jour', { variant: 'success' })
            const event = {
              timestamp: Date.now(),
              nom: 'modification',
              source: source,
              context: 'facturation'
            };
            clientService.addEvent(brasserie, values, event)
            const newClient = _.cloneDeep(client)
            newClient.facturation = values.facturation
            setClient(newClient)
            if (closeModal) {
              closeModal(false)
            }

          } else {
            enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
          }
        }).catch(error => {
          enqueueSnackbar(error.message, { variant: 'error' })
        });
      }}
    >
      <Form>
      {(client.siren || client.facturation.siret) &&
      <select className="form-select mb-2" onChange={handleSelectionEtablissement}>
          <option>Selectionner un établissement</option>
          {etablissements.map((etablissement, index) => {
            return <option key={index} value={index}>{etablissement.nom} - {etablissement.adresse} - {etablissement.codePostal} {etablissement.ville}</option>
          })}
        </select>
      }
        <MyTextInput
          label="N° SIRET"
          name="facturation.siret"
          type="number"
        />
        <MyTextInput
          label="Nom de la société"
          name="facturation.nom"
          type="text"
        />
        <MyTextInput
          label="Adresse"
          name="facturation.adresse"
          type="text"
        />
        <MyTextInput
          label="Code Postal"
          name="facturation.codePostal"
          type="text"
        />
        <MyTextInput
          label="Ville"
          name="facturation.ville"
          type="text"
        />
        <MyTextInput
          label="N° TVA"
          name="facturation.tva"
          type="text"
        />
        <div class="d-grid gap-2">
          <button className="btn btn-primary mt-3" type="submit">Valider</button>
        </div>
      </Form>
    </Formik >
  );
}

export { FacturationForm }

