import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from "react-chartjs-2";

import { Card, Dropdown, Table } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import { format, sub } from "date-fns";
import Loader from "../../../components/Loader";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);


const VentesParGrilleTarifairePieChart = ({ dataVentes, loadingDataVentes }) => {

  const { brasserie } = useOutletContext();

  const [statistiques, setStatistiques] = useState([])

  useEffect(() => {
    //const date is based on today date day, month, year (without the seconds and milliseconds)
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const daysago30 = sub(date, { days: 30 });
    const daysago60 = sub(date, { days: 60 });
    //filter for the last 60 days
    const dataForDateRange = dataVentes.filter((item) => {
      return item.timestamp >= daysago60;
    });

    const pricings = {}
    for (const pricing in brasserie.pricings) {
      pricings[pricing] = {
        CA: 0,
        marge: 0,
        volume: 0,
      }
    }

    const accInit = {
      'currentPeriod': _.cloneDeep(pricings),
      'previousPeriod':  _.cloneDeep(pricings),
    };

    const totaux = {
      'currentPeriod': {
        CA: 0,
        marge: 0,
        volume: 0,
      },
      'previousPeriod': {
        CA: 0,
        marge: 0,
        volume: 0,
      }
    }


    //reduce the data to get the sum of CA, Marge and Volume for each pricing
    const data = dataForDateRange.reduce((acc, item) => {
      const { day,clientGrilleTarifaire, CA, marge, volume } = item;

      //check if the item is for current (daysago30) or previous (between daysago30 and daysago60) period
      const period = item.timestamp >= daysago30 ? "currentPeriod" : "previousPeriod";

      totaux[period].CA += CA;
      totaux[period].marge += marge;
      totaux[period].volume += volume;
      
      acc[period][clientGrilleTarifaire].CA += CA;
      acc[period][clientGrilleTarifaire].marge += marge;
      acc[period][clientGrilleTarifaire].volume += volume;

      return acc;
    }, accInit);

    //order by CA
    for (const period in data) {
      data[period] = Object.fromEntries(
        Object.entries(data[period]).sort(([, a], [, b]) => b.CA - a.CA)
      );
    }

    setStatistiques(data)
  }, [brasserie.pricings, dataVentes])


  if (loadingDataVentes && statistiques.length < 1) return <Loader />


  const data = {
    labels: Object.keys(statistiques['currentPeriod']),
    datasets: [
      {
        data: Object.values(statistiques['currentPeriod']).map((item) => item.CA),
        borderWidth: 3,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cutoutPercentage: 70,

    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
      },
      colors: {
        enabled: true,
        forceOverride: true,

      }, legend: {
        display: false,
      },
    },
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">Ventes par Grille Tarifaire</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="py-3">
            <div className="chart chart-xs">
              <Pie data={data} options={options} />
            </div>
          </div>

          <Table className="mb-0">
            <thead>
              <tr>
                <th>Grille Tarifaire</th>
                <th className="text-end">CA</th>
                <th className="text-end">Value</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(statistiques['currentPeriod']).map((key) => {
                let increase = 0

                if (statistiques['previousPeriod'][key].CA === 0 && statistiques['currentPeriod'][key].CA === 0) {
                  return null
                }

                if (statistiques['previousPeriod'][key].CA === 0 && statistiques['currentPeriod'][key].CA !== 0) {
                  increase = 100
                }
                if (statistiques['previousPeriod'][key].CA !== 0) {
                  increase = Math.round((statistiques['currentPeriod'][key].CA - statistiques['previousPeriod'][key].CA) / statistiques['previousPeriod'][key].CA * 100)
                }
                return (
                  <tr key={key}>
                    <td>
                      <FontAwesomeIcon icon={faSquare} className="text-primary" />{" "}
                      {brasserie.pricings[key].nom}
                    </td>
                    <td className="text-end">{Math.round(statistiques['currentPeriod'][key].CA * 100) / 100}€</td>
                    {(increase >= 0) ? <td className="text-end text-success">+{increase}%</td> : <td className="text-end text-danger">{increase}%</td>}


                  </tr>
                )
              }
              )}
            </tbody>
          </Table>
        </div>
      </Card.Body>
    </Card>
  );
};


export default VentesParGrilleTarifairePieChart;
