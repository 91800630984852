import { useCallback, useContext, useEffect, useState } from "react";
import { Outlet, useLoaderData,useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useIntervalAsync from "../hooks/UseIntervalAsync";
import brasserieService from "../services/brasserie.service";
import '@tremor/react/dist/esm/tremor.css';
import { SessionContext } from "../contexts/SessionContext";

export const loader = async ({ params }) => {
  try {
    let brasserie;

    const respBrasserie = await brasserieService.getByHostName(window.location.hostname)
    if (respBrasserie.data === null) {
      throw new Error()
    } else {
      brasserie = respBrasserie.data;

    }


    return { brasserie: brasserie }

  } catch {
    throw new Response(null, {
      status: 500,
      statusText: "Internal Server Error",
    });
  }
}


function AdminLayout() {


  const { brasserie } = useLoaderData()
  const [notifications, setNotifications] = useState({});
  const navigate = useNavigate();
  const updateState = useCallback(async () => {
    const response = await brasserieService.getNotifications(brasserie)

    setNotifications(response.data);
  }, []);
  useIntervalAsync(updateState, 300000);

  const {session,updateSession} = useContext(SessionContext)

  //check session with useEffect

  useEffect(() => {
    if(session.isAdminLoggedIn === false || session.isAdminLoggedIn === undefined){
      navigate('/login', { replace: true })
    }else{
      session.isAdminLoggedIn = true
      session.isAdmin = true
      session.brasserie = null
      session.client = null
      session.contact = null

    }
    updateSession(session)
  }, [])



  return (

    <div className="main">
      <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
        <div className="container-fluid">
          <Link to={"/admin/" + brasserie.id} className="navbar-brand">
            Le Père l'Amer - Admin
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
              <li className="nav-item">
                <Link to={"/admin/" + brasserie.id + "/clients"} className="nav-link">
                  Clients
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/admin/" + brasserie.id + "/produits"} className="nav-link">
                  Produits
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/admin/" + brasserie.id + "/commandes"} className="nav-link">
                  Commandes {notifications?.commandesOuvertes > 0 && <span className=" badge bg-danger">{notifications.commandesOuvertes}</span>}
                </Link>
              </li>

              <li className="nav-item">
                <Link to={"/admin/" + brasserie.id + "/inventaires"} className="nav-link">
                  Inventaires
                </Link>
              </li>
              <li className="nav-item">
                <Link to={"/admin/" + brasserie.id + "/remises/creer"} className="nav-link">
                  Remises
                </Link>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  Gestion
                </a>
                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                  <li>
                    <Link to={"/admin/" + brasserie.id + "/grilles-tarifaires"} className="dropdown-item">
                      Grilles Tarifaires
                    </Link>
                  </li>
                  <li>
                    <Link to={"/admin/" + brasserie.id + "/catalogues"} className="dropdown-item">
                      Catalogues
                    </Link>
                  </li>
                  <li>
                    <Link to={"/admin/" + brasserie.id + "/conditionnements"} className="dropdown-item">
                      Conditionnements
                    </Link>
                  </li>
                  <li>
                    <Link to={"/admin/" + brasserie.id + "/tournees"} className="dropdown-item">
                      Tournées
                    </Link>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </nav>
      <div className="content">
      <div className="container-fluid p-0">
        <Outlet context={{ brasserie: brasserie }}></Outlet>
      </div>
      </div>
      


    </div>

  );
}

export default AdminLayout;
