
import React, { useState } from "react";
import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import { MySelect, MyTextArea, MyTextInput } from '../../components/forms';
import { useNavigate, useOutletContext } from "react-router-dom";
import clientService from "../../services/client.service";
import { useSnackbar } from "notistack";
import { AutocompleteRechercheSiege } from "../../components/forms/Autocomplete";
import sireneService from "../../services/sirene.service";
import _ from "lodash";


const AutoUpdateFormiK = (localClient) => {
    // Grab values and submitForm from context
    const { setValues } = useFormikContext();
    React.useEffect(() => {
        setValues(localClient.localClient)
    }, [localClient, setValues]);
    return null;
};

export default function ClientNew(props) {

    const { brasserie } = useOutletContext();

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate()


    const [localClient, setLocalClient] = useState({ ...clientService.DEFAULT_CLIENT,...{brasserieId: brasserie.id} });
    const [dataSiege, setDataSiege] = useState()
    const [dataEtablissements, setDataEtablissements] = useState([])
    const [etablissementFacturation, setEtablissementFacturation] = useState()
    const [etablissementLivraison, setEtablissementLivraison] = useState()

    const updateLocalUserWithDataEtablissement = (clientToUpdate, path, dataEtablissement) => {
        switch (path) {
            case "facturation":
                clientToUpdate[path].siret = dataEtablissement.siret
                clientToUpdate[path].nom = (dataEtablissement.nom) ? dataEtablissement.nom : dataEtablissement.nomCommercial
                clientToUpdate[path].adresse = dataEtablissement.adresse
                clientToUpdate[path].codePostal = dataEtablissement.codePostal
                clientToUpdate[path].ville = dataEtablissement.ville
                clientToUpdate.siren = dataEtablissement.siren
                break;

            case "livraison":
                clientToUpdate[path].siret = dataEtablissement.siret
                clientToUpdate[path].nom = (dataEtablissement.nomCommercial) ? dataEtablissement.nomCommercial : dataEtablissement.nom
                clientToUpdate[path].adresse = dataEtablissement.adresse
                clientToUpdate[path].codePostal = dataEtablissement.codePostal
                clientToUpdate[path].ville = dataEtablissement.ville
                clientToUpdate.siren = dataEtablissement.siren
                break;

            default:
                break;
        }
        console.log(clientToUpdate)
        return clientToUpdate
    }

    const handleOnChangeRechercheSiege = (siege) => {
        setEtablissementFacturation('')
        setEtablissementLivraison('')
        setDataEtablissements([])
        if (siege.length > 0) {
            const dataSiege = siege[0]

            const data2 = sireneService.getEtablissementsBySiren(dataSiege.siren).then(resp => {
                console.log(resp)
                if (resp.status === 200) {
                    const etablissements = resp.data.records.map(record => {
                        const et = { ...sireneService.extractDataFromRecord(record), nomCommercial: dataSiege.nomCommercial, siren:dataSiege.siren }
                        return et
                    })
                    setDataEtablissements(etablissements)
                    if (etablissements.length === 1) {

                        let newLocalClient = _.cloneDeep(localClient)
                        setEtablissementFacturation(0)
                        setEtablissementLivraison(0)
                        newLocalClient = updateLocalUserWithDataEtablissement(newLocalClient, 'facturation', etablissements[0])
                        newLocalClient = updateLocalUserWithDataEtablissement(newLocalClient, 'livraison', etablissements[0])
                        setLocalClient(newLocalClient)
                    }else{
                        let newLocalClient = _.cloneDeep(localClient)
                        setEtablissementFacturation(0)
                        setEtablissementLivraison(0)
                        newLocalClient = updateLocalUserWithDataEtablissement(newLocalClient, 'facturation', etablissements[0])
                        setLocalClient(newLocalClient)
                    }
                }
            }


            ).catch(error => {

            }
            )

            setDataSiege(dataSiege)

        }

    }

    const handleSelectionEtablissement = (e) => {

        let newLocalClient = _.cloneDeep(localClient)
        switch (e.target.name) {
            case "facturation":
                setEtablissementFacturation(e.target.value)
                break;

            case "livraison":
                setEtablissementLivraison(e.target.value)
                break;

            default:
                break;
        }
        newLocalClient = updateLocalUserWithDataEtablissement(newLocalClient, e.target.name, dataEtablissements[e.target.value])
        setLocalClient(newLocalClient)
    }


    return <div className="row">

        <main className="col-12 px-md-4">
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Nouveau prospect</h1>
                <div className="col-6">
                    <AutocompleteRechercheSiege handleChange={handleOnChangeRechercheSiege} />
                </div>
            </div>
            <Formik
                initialValues={localClient}
                validationSchemaOld={Yup.object({
                    mail: Yup.string()
                        .email('Adresse email incorrecte :(')
                        .required('L\'adresse email est obligatoire pour créer un prospect')
                })}
                validationSchema={Yup.object({
                    facturation:
                        Yup.object({
                            nom: Yup.string()
                                .required('Le nom de l\'entreprise est nécessaire pour créer un client')
                        })
                })
                }
                onSubmit={(values, { setSubmitting }) => {
                    clientService.createv2(brasserie.id, values).then(resp => {
                        console.log(resp)
                        if (resp.data?.client && resp.data.client?.id) {
                            setSubmitting(false);
                            navigate('/admin/' + brasserie.id + '/clients/' + resp.data.client.id)
                        } else {

                        }
                    }).catch(error => {
                        console.log(error)
                        enqueueSnackbar(error.response.data.error.data.error, { variant: 'error' })
                    });

                }}
            >
                <Form>
                    <div className='row'>
                        <div className="col-6">
                            <div className="card my-2">
                                <div className="card-header d-flex align-items-center">
                                    <h5 className="m-0">
                                        Facturation
                                    </h5>
                                    <select className="form-select ms-2" name="facturation" value={etablissementFacturation} onChange={handleSelectionEtablissement}>
                                        <option>Selectionner un établissement</option>
                                        {dataEtablissements.map((etablissement, index) => {
                                            return <option key={index} value={index}>{etablissement.nom} - {etablissement.adresse} - {etablissement.codePostal} {etablissement.ville}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="card-body">
                                    <MyTextInput
                                        label="N° SIRET"
                                        name="facturation.siret"
                                        type="number"
                                    />
                                    <MyTextInput
                                        label="Nom de la société"
                                        name="facturation.nom"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Adresse"
                                        name="facturation.adresse"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Code Postal"
                                        name="facturation.codePostal"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Ville"
                                        name="facturation.ville"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="TVA"
                                        name="facturation.tva"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-6">
                            <div className="card my-2">
                                <div className="card-header d-flex align-items-center">
                                    <h5 className="m-0">
                                        Livraison
                                    </h5>
                                    <select className="form-select ms-2" name="livraison" value={etablissementLivraison} onChange={handleSelectionEtablissement}>
                                        <option>Selectionner un établissement</option>
                                        {dataEtablissements.map((etablissement, index) => {
                                            return <option key={index} value={index}>{etablissement.nom} - {etablissement.adresse} - {etablissement.codePostal} {etablissement.ville}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="card-body">
                                    <MyTextInput
                                        label="N° SIRET"
                                        name="livraison.siret"
                                        type="number"
                                    />
                                    <MyTextInput
                                        label="Nom d'enseigne/nom commercial"
                                        name="livraison.nom"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Adresse"
                                        name="livraison.adresse"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Code Postal"
                                        name="livraison.codePostal"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Ville"
                                        name="livraison.ville"
                                        type="text"
                                    />
                                    <MyTextInput
                                        label="Horaires de livraison"
                                        name="livraison.horaires"
                                        type="text"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-12 mt-3 d-flex">
                            <button className="btn btn-lg btn-primary  flex-fill" type="submit">Créér le client</button>
                        </div>
                    </div>
                    <AutoUpdateFormiK localClient={localClient} />
                </Form>
            </Formik >
        </main>
    </div>






}
