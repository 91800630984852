import React, { useCallback, useContext, useRef, useState } from "react";

import _ from 'lodash'
import { useSnackbar } from 'notistack';
import commandeService from "../../../services/commande.service";
import { Alert, Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";

import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import Webcam from "react-webcam";
import { useCommande, useCommandeDispatch } from "../../../contexts/CommandeProvider";
import { SessionContext } from "../../../contexts/SessionContext";
import clientService from "../../../services/client.service";
import { useNavigate } from "react-router-dom";
import { Commande } from "../../../model/Commande.model";
import SignatureCanvas from 'react-signature-canvas'

export default function Suppression() {

    const dispatch = useCommandeDispatch()
    const { changementStatutLoading, commande,brasserie } = useCommande();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin

    const handleClick = (e) => {
        e.preventDefault()
        dispatch({ type: 'changementStatutLoading' })


        commande.delete().then(resp => {
            //if resp status is 200
            if(resp.status === 200){
               
                enqueueSnackbar('La commande est supprimee.', { variant: 'success' })
                //revenir à la liste des commandes dans l'admin
                    navigate('/admin/'+brasserie.id+'/commandes')

            } else {
               
                enqueueSnackbar('Un truc n\'a pas marché :(.', { variant: 'error' })
            }

            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })

        }).catch(e => {
            console.log(e)
            
            enqueueSnackbar(e.response.data.error, { variant: 'error' })


            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })
        })
    }

    return <>
        <div className="d-flex mb-3">
            <Button variant="secondary" className="flex-fill" onClick={(e) => handleClick(e)} disabled={changementStatutLoading}>{!changementStatutLoading ? "Supprimer la commande" : <Spinner />}</Button>
        </div>
    </>

}
