import jardel from "./jardel";
import geodis from "./geodis";
import gauthier from "./gauthier";

const data = [jardel, geodis, gauthier];

class Transporteurs {
    getPricesForDepartement(num) {
        let prices = [];
        const initPrice = { name: '', price: 0, gazoil: 0 }
        data.forEach((t) => {
            let price = { ...initPrice };
            price.name = t.name;
            price.gazoil = t.gazoil;
            price.price = t.palette[num];
            prices.push(price)
        })
        return prices;
    }
    getBestTransporteurForDepartement(num) {
        const initPrice = { name: '', price: 10000, gazoil: 0 }
        const bestPrice = { ...initPrice }
        data.forEach((t) => {
            if (bestPrice.price > t.palette[num]) {
                bestPrice.name = t.name;
                bestPrice.gazoil = t.gazoil;
                bestPrice.price = t.palette[num];
            }else{

            }
        })
        return bestPrice;
    }
    getBestPriceFordepartement(num) {
        let price = 1000;
        data.forEach((t) => {
            if (price > t.palette[num]) {
                price = t.palette[num]
            }
        })
        return price;
    }
    getBestPriceColorFordepartement(num) {
        let price = 1000;
        let color = "";
        data.forEach((t) => {
            if (price > t.palette[num]) {
                price = t.palette[num]
                color = t.color
            }
        })
        return color;
    }

    getFrancoForMargin(margin) {
        let result = {};
        let francos = [];
        data.forEach((t) => {
            let gazoil = t.gazoil
            let departements = Object.keys(t.palette)
            departements.forEach((departement) => {
                let price = t.palette[departement];
                let transportPrice = gazoil / 100 * price + price;
                let CAMini = Math.round(transportPrice / (margin / 100));
                let franco = Math.ceil(CAMini * 0.01) / 0.01;
                if (result[departement]) {
                    if (result[departement]['CA'] > CAMini) {

                        result[departement]['departement'] = departement;
                        result[departement]['transporteur'] = t.name;
                        result[departement]['price'] = price;
                        result[departement]['transportPrice'] = transportPrice;
                        result[departement]['CA'] = CAMini;
                        result[departement]['franco'] = franco;
                        if (francos.indexOf(franco) === -1) {
                            francos.push(franco)
                        }
                    }
                } else {
                    result[departement] = {
                        'departement': departement,
                        'transporteur': t.name,
                        'price': price,
                        'transportPrice': transportPrice,
                        'CA': CAMini,
                        'franco': franco,
                    }
                    if (francos.indexOf(franco) === -1) {
                        francos.push(franco)
                    }

                }
            })
        })
        let values = Object.values(result);
        values.sort((a, b) => {
            return a.franco - b.franco;
        })

       

        francos.sort((a,b)=>{return a-b})

        let colors = ['blue','green','red','pink','grey','orange','black']
        let francoResult = {};

        for(var i = 0; i < francos.length;i++){
            francoResult[francos[i]] = colors[i]
        }
        return { 'data': values, 'francos': francoResult };
    }
}
export default new Transporteurs();
