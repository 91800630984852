import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DollarSign } from "react-feather";
import productService from "../../../services/product.service";
import _ from "lodash";
import Loader from "../../../components/Loader";
import * as echarts from "echarts/core";
import { aggregate } from "@manufac/echarts-simple-transform";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getWeek,format } from 'date-fns'


import { useOutletContext } from "react-router-dom";

export default function InsightsProduit({ product }) {

    //get product statistiques by props productId and session brasserieId
    //use http.get to get statistiques
    //useEffect to get statistiques
    // endpoint is /brasseries/:brasserieId/produits/:id/statistiques
    // return statistiques

    const [statistiques, setStatistiques] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const { brasserie } = useOutletContext()
    console.log(brasserie)

    const formatStatistiques = (statistiques) => {
        //format statistiques which 
        //raw data are an array of object like this:
        // {"id":"U2wea4CZHB7qbumi0Bwf","productId":"EToGtBFvR2ntT89OCX1x","year":2023,"prix":"60","brasserieId":"LJIxsOUtFOOQN7Voy6me","lotId":"#26","type":"lots","conditionnementType":"polykeg \"A\"","catalogue":"la-mifa","volume":20,"conditionnementVolume":20,"dayOfWeek":2,"month":1,"abv":5,"conditionnementId":"polykeg-20-l","valorisation":60,"stock":1,"day":14,"timestamp":1676332800000}
        // we want to order the data by year, month, day
        // and then we want to group by year, month, day
        // and then we want to sum the valorisation and volume and quantity
        // and then we want to return an array of objects like this:
        // {year: 2021, month: 1, day: 1, volume: 100, valorisation: 1000, quantity: 10}

        const stats = _.orderBy(statistiques, ['year', 'month', 'day'], ['asc', 'asc', 'asc'])

        const formatedStatistiques = []

        _.forEach(stats, (stat) => {
            const formatedStat = _.find(formatedStatistiques, (formatedStat) => {
                return formatedStat.year === stat.year && formatedStat.month === stat.month && formatedStat.day === stat.day
            })
            if(stat.conditionnementId==="bouteille-33-cl"){
                console.log(stat)
            }
            if (formatedStat) {
                formatedStat.volume += stat.volume
                formatedStat.valorisation += stat.valorisation
                formatedStat.conditionnements[stat.conditionnementId] += (stat.stock)*1

            } else {
                formatedStatistiques.push({
                    year: stat.year,
                    month: stat.month,
                    day: stat.day,
                    volume: stat.volume,
                    valorisation: stat.valorisation,
                    conditionnements: {
                        [stat.conditionnementId]: (stat.stock)*1
                    }

                })
            }
        })
        return formatedStatistiques
    }



    useEffect(() => {
        setLoading(true)
        const getStatistiques = async () => {
            productService.getStatistiques(brasserie.id, product.id).then((response) => {

               setStatistiques( formatStatistiques(response.data))
                //log les items dont la date est "18-2-2023"
                console.log(statistiques)
                setLoading(false)

            }).catch((err) => {
                console.log(err)
                setError(err)
                setLoading(false)
            })

        }
        getStatistiques()
    }, [brasserie, product])

   
    return (<>
        {loading && <Loader />}
        {!loading &&
            <>{console.log(statistiques)}
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Statistiques des 30 derniers jours</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).volume} l</h3>

                                                <p className="mb-2">Volume</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).valorisation} €HTDI</h3>

                                                <p className="mb-2">Valorisation</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).valorisation / 2} €HTDI</h3>

                                                <p className="mb-2">Chiffre d'affaire</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md="6" xl className="d-flex">
                                <Card className="flex-fill">
                                    <Card.Body className=" py-4">
                                        <div className="d-flex align-items-start">
                                            <div className="flex-grow-1">
                                                <h3 className="mb-2">{_.last(statistiques).valorisation / 2} €HTDI</h3>

                                                <p className="mb-2">Marge nette</p>
                                            </div>
                                            <div className="d-inline-block ms-3">
                                                <div className="stat">
                                                    <DollarSign className="align-middle text-success" />
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>


                    </Card.Body>
                </Card>
               
            </>
        }
    </>)
}