
import React from "react";
import { useState } from 'react';
import { Link, redirect, useLoaderData, useNavigate, useParams } from "react-router-dom";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MyTextInput } from "../components/forms";
import clientService from "../services/client.service";
import { useSnackbar } from "notistack";

import brasserieService from "../services/brasserie.service";
import _ from "lodash";

export const loader = async ({ params }) => {
  try {
    let brasserie;


    const respBrasserie = await brasserieService.getByHostName(window.location.hostname)
    if (respBrasserie.data === null) {
      throw new Error()
    } else {
      brasserie = respBrasserie.data;

    }
    let prospectDefault = _.defaultsDeep({
      brasserieId: brasserie.id,
      source: 'client',
      contacts: [
        _.defaultsDeep({
          mail: params.email || null
        }, clientService.DEFAULT_CONTACT)
      ],
      catalogues: [
        "classics", "d-collaborations", "le-pere-l-amer"
      ]
    }, clientService.DEFAULT_CLIENT)
    const respContact = await clientService.getByContactEmail(params.email)
    if (respContact.data) {
      if (parseInt(respContact.data.statut) === clientService.STATUT_CLIENT) {
        console.log("deja vu")
        return redirect('/demande-acces/' + params.email)

      } else {
        prospectDefault = respContact.data
      }

    }

    console.log(prospectDefault)

    return { brasserie: brasserie, prospectDefault: prospectDefault }

  } catch (e) {
    console.log(e)
    throw new Response(null, {
      status: 500,
      statusText: "Internal Server Error",
    });
  }
}

export default function DemandeTarif(props) {


  const { enqueueSnackbar } = useSnackbar();


  //navigation hooks
  const params = useParams()
  //navigation hook
  const navigate = useNavigate();

  //contexts

  const { brasserie, prospectDefault } = useLoaderData()

  //page states
  const [status, setStatus] = useState('prospect')
  const [prospectChecked, setProspectChecked] = useState(false)

  const [prospect, setProspect] = useState(prospectDefault)

  if (!prospectChecked) {
    console.log("check prospect", prospect, prospectChecked)
    if (parseInt(prospect.statut) > clientService.STATUT_SUSPECT) {
      setStatus('prospectAlreadySent')
      setProspectChecked(true)
    }

  }

  if (status === 'prospect') {
    return (

      <div className="row">
        <section className="text-center container">
          <div className="row py-lg-4">
            <div className="col-lg-8 col-md-8 mx-auto">
              <h1 className="fw-light">Demande de tarifs</h1>
              <p className="lead text-muted">
                Que vous souhaitiez rejoindre la famille de nos clients ou que nous travaillions déjà ensemble, nous vous proposons ce nouvel outil pour faciliter notre collaboration.
              </p>
              <p className="lead text-muted">
                Le point de départ consiste à remplir ce formulaire afin que votre fiche client soit à jour. Dès que nous aurons traité vos données vous recevrez un lien vers votre espace dédié !
              </p>
            </div>
          </div>
        </section>
        <div className="col-8 offset-2">

          <Formik
            initialValues={prospect}
            enableReinitialize={true}
            validationSchema={Yup.object({
              siren: Yup.string().matches(/^[0-9]{9}$/, 'Un numéro de SIREN est composé de 9 chiffres')
                .required('Le siren est obligatoire. Si vous n\'en avez pas (en cours de création, association : contactez-nous).'),
              contacts: Yup.array().of(
                Yup.object({
                  mail: Yup.string()
                    .email('Adresse email incorrecte :(')
                    .required('L\'adresse email est obligatoire.'),
                  telephone: Yup.number().typeError("Uniquement des chiffres :)")
                    .required('Le numéro de téléphone est obligatoire.'),
                  nom: Yup.string()
                    .required('Le nom est obligatoire.'),

                  prenom: Yup.string()
                    .required('Le prénom est obligatoire.'),
                  horaires: Yup.string()
                    .required('Pour vous déranger le moins possible :)')
                }
                )
              ),
              facturation:
                Yup.object({
                  nom: Yup.string()
                    .required('Le nom de l\'entreprise est nécessaire pour créer un client')
                }),
              livraison:
                Yup.object({
                  nom: Yup.string()
                    .required('Le nom de l\'entreprise est nécessaire pour créer un client')
                })
            })
            }
            onSubmit={(values, { setSubmitting }) => {
              const prospectToSend = values
              prospectToSend.statut = clientService.STATUT_PROSPECT_CHAUD
              prospectToSend.mails = [values.contacts[0].mail]
              prospectToSend.uuids = [values.contacts[0].uuid]
              console.log(prospectToSend)
              clientService.createv2(prospectToSend.brasserieId, clientService.cleanClient(values)).then(resp => {
                console.log(resp)
                if (resp.data?.client && resp.data.client?.id) {
                  const client = resp.data.client
                  setStatus('prospectSent')
                  const event = {
                    timestamp: Date.now(),
                    nom: 'creation',
                    source: (prospectToSend.source === "admin") ? "admin" : values.prenom + " " + values.nom.charAt(0),
                    context: 'contact'
                  };
                  clientService.addEvent(brasserie, client, event)

                } else {

                }
              }).catch(error => {
                console.log(error)
                enqueueSnackbar(error.response.data.error.data.error, { variant: 'error' })
              });


            }}
          >
            <Form>
              <MyTextInput
                label="Raison social"
                name="facturation.nom"
                type="text"
              />
              <MyTextInput
                label="Nom d'enseigne / nom commercial"
                name="livraison.nom"
                type="text"
              />
              <MyTextInput
                label="N° de SIREN"
                name="siren"
                type="text"
              />
              <MyTextInput
                label="Adresse email"
                name={"contacts[0].mail"}
                type="text"
              />
              <MyTextInput
                label="Téléphone"
                name={"contacts[0].telephone"}
                type="text"
              />
              <MyTextInput
                label="Nom"
                name={"contacts[0].nom"}
                type="text"
              />
              <MyTextInput
                label="Prénom"
                name={"contacts[0].prenom"}
                type="text"
              />
              <MyTextInput
                label="Horaires de contact"
                name={"contacts[0].horaires"}
                type="text"
              />
              <button className="btn btn-primary" type="submit">Valider</button>
            </Form>
          </Formik >
          {/* form */}
        </div>
      </div>
    );
  }
  if (status === 'prospectSent') {
    return <div className="row">
      <section className="text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-8 col-md-8 mx-auto">
            <h1 className="fw-light">Nous avons bien reçu votre demande. Merci !</h1>
            <p className="lead text-muted">
              Nous reviendrons vers vous dans les plus brefs délais (48h max hors weekends). Si jamais vous n'entendiez pas parler de nous, n'hésitez pas à nous passer un coup de fil.
            </p>
            <p className="lead text-muted">
              En attendant, vous pouvez découvrir une partie de nos produits :-)
            </p>
            <p className="lead text-muted">
              <Link to={"/client/" + prospect.contacts[0].uuid + "/catalogue"} className="btn btn-primary">
                Je jette un oeil
              </Link>
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Antoine : 06 48 64 18 88</li>
              <li className="list-group-item">Céline (si vous n'arrivez à joindre Antoine) : 06 49 81 58 13</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  }
  if (status === 'prospectAlreadySent') {
    return <div className="row">
      <section className="text-center container">
        <div className="row py-lg-5">
          <div className="col-lg-8 col-md-8 mx-auto">
            <h1 className="fw-light">Nous avons déjà une demande en attente pour vous, mais soit il y a un problème soit nous ne sommes pas assez rapide.</h1>
            <p className="lead text-muted">
              N'hésitez pas à nous contacter pour que nous puissions corriger le tir. Merci d'avance pour votre patience !
            </p>
            <ul className="list-group list-group-flush">
              <li className="list-group-item">Miri - Super-Commerciale 19/24/33/87 : 07 57 18 80 48</li>
              <li className="list-group-item">Antoine - Reste du monde, ou de la France, ou de la Région : 06 48 64 18 88</li>
              <li className="list-group-item">Céline (si vous n'arrivez à joindre ni Miri, ni Antoine) : 06 49 81 58 13</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  }








}
