
import React, { useState } from "react";


import { matchSorter } from "match-sorter";
import _ from "lodash"
import { useSnackbar } from 'notistack';


import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import clientService from "../../services/client.service";
import { AddCommentaire, AddCommunication } from "../../components/actions/Client";
import InsightsCommandes from "../../features/insightsCommandes";
import commandeService from "../../services/commande.service";
import { Commande } from "../../model/Commande.model";

export const loader = async ({ params }) => {
    try {
        let clients;

        const respClients = await clientService.getAllLight(params.brasserieId);
        clients = respClients.data;

        const newClients = clients.map((client) => {
            client.statut = clientService.STATUTS[client.statut].nom
            //create commande object for each commande in client.commandes
            /* client.commandes = client.commandes.map((commande) => {
                return commande = Commande.initFromJson(commande)
            }) */
            return client
        })

        return { clients: newClients }

    } catch (error) {
        console.log(error)
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function Clients(props) {

    const { enqueueSnackbar } = useSnackbar();
    const { brasserie } = useOutletContext();
    const { clients } = useLoaderData();
    console.log('brasserie', brasserie)


    const defaultColumns = [
        {
            nom: "statut",
            path: "statut",
            type: "direct",
            visible: true,
            searchable: true,
            searchType: "array",
            searchData: clientService.STATUTS,
            search: []
        }, {
            nom: "entreprise",
            path: "facturation.nom",
            type: "direct",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "enseigne",
            path: "livraison.nom",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "nom",
            path: "contacts.0.nom",
            type: "direct",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "prénom",
            path: "contacts.0.prenom",
            type: "direct",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "téléphone",
            path: "contacts.0.telephone",
            type: "direct",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "mail",
            path: "contacts.0.mail",
            type: "direct",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "code postal",
            path: "livraison.codePostal",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "catalogues",
            path: "catalogues",
            type: "array",
            refPath: "catalogues",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "tarifs",
            path: "pricing",
            type: "ref",
            refPath: "pricings",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "Ville",
            path: "livraison.ville",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "tournées",
            path: "tournee",
            type: "ref",
            refPath: "tournees",
            visible: true,
            searchable: true,
            searchType: "array",
            searchData: _.merge(brasserie.tournees, { "": { nom: "Aucune Tournée" } }),
            searchEmpty: true,
            search: []
        },
    ]


    const [clientList, setClientList] = useState(_.cloneDeep(clients))
    const [columns, setColumns] = useState(defaultColumns)
    const [commandes, setCommandes] = useState([])

    const doFilter = (newColumns) => {

        let filteredClients = _.cloneDeep(clients);
        for (let i = 0; i < newColumns.length; i++) {
            if (_.isArray(newColumns[i].search)) {
                if (newColumns[i].search.length > 0) {
                    console.log(newColumns[i])
                    filteredClients = filteredClients.filter(client => {
                        if (_.get(client, newColumns[i].path) === null) {
                            _.set(client, newColumns[i].path, '')
                        }
                        return _.indexOf(newColumns[i].search, _.get(client, newColumns[i].path)) >= 0
                    })
                    console.log("filtered clients", filteredClients)
                }
            } else {
                const terms = newColumns[i].search.split(' ')
                if (!terms || !newColumns[i].search.length) {
                } else {
                    // reduceRight will mean sorting is done by score for the _first_ entered word.
                    filteredClients = terms.reduceRight(
                        (results, term) => matchSorter(results, term, { keys: [newColumns[i].path] }),
                        filteredClients,)
                }
            }

        }

        setClientList(filteredClients)
        //les commandes d'un client sont stockées dans client.commandes
        //extraires toutes les commandes des clients filtrés
        const commandes = _.flatten(_.map(filteredClients, 'commandes'))
        setCommandes(commandes)
    }

    const handleCheckBoxFiltre = (event, columnIndex, value) => {
        console.log('handleCheckBoxFiltre', event, columnIndex, value)
        const newColumns = _.cloneDeep(columns)

        if (event.target.checked) {
            newColumns[columnIndex].search.push(value)
        } else {
            _.pull(newColumns[columnIndex].search, value)
        }
        doFilter(newColumns)
        setColumns(newColumns)
    }

    const handleSearch = (searchTerm, index) => {
        const newColumns = _.cloneDeep(columns)
        newColumns[index].search = searchTerm


        setColumns(newColumns)

        doFilter(newColumns)
    }

    const handleChangeColumnVisibility = (e, index) => {

        const newColumns = _.cloneDeep(columns)
        newColumns[index].visible = !columns[index].visible

        setColumns(newColumns)
    }
    const handleFocusColumnVisibility = (e, index) => {

        const newColumns = _.cloneDeep(columns)
        newColumns[index].visible = true

        setColumns(newColumns)
    }

    const handleClickGetEmails = (e) => {
        const emails = clientList.map((client, k) => {
            return client.contacts.map(contact => {
                return contact.mail
            })
        })
        navigator.clipboard.writeText(_.join(_.compact(_.flattenDeep(emails))))
        enqueueSnackbar('Liste de mails copiée dans le presse papier.', { variant: 'success' })
        //console.log(_.join(_.compact(_.flattenDeep(emails))))
    }

    return <>
        <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block ">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Filtres</h1>
                </div>
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        {columns.map((column, index) => {
                            return (
                                <li className="nav-item" key={index}>

                                    {column.searchable && !column.searchType
                                        && <div className="input-group mb-3 ">
                                            <div className="input-group-text form-switch  ps-5 pe-0">
                                                <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(column.visible ? "checked" : "")} id={index} onChange={(e) => handleChangeColumnVisibility(e, index)} />
                                            </div>
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id={index} aria-label="Text input with checkbox" onChange={(e) => handleSearch(e.target.value, index)} onFocus={(e) => handleFocusColumnVisibility(e, index)} value={column.search} />
                                                <label forhtml={index}>{column.nom}</label>
                                            </div>
                                        </div>
                                    }
                                    {column.searchable && column?.searchType === "array"
                                        && <>
                                            <div className="input-group mb-3 ">
                                                <div className="input-group-text form-switch  ps-5 pe-0">
                                                    <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(column.visible ? "checked" : "")} id={index} onChange={(e) => handleChangeColumnVisibility(e, index)} />
                                                </div>
                                                <div className="form-floating">
                                                    <input type="text" className="form-control " id={index} aria-label="Text input with checkbox" onChange={(e) => handleSearch(e.target.value, index)} onFocus={(e) => handleFocusColumnVisibility(e, index)} value={column.search.join(', ')} disabled />
                                                    <label forhtml={index}>{column.nom}</label>
                                                </div>
                                            </div>
                                            <div>

                                                {_.isArray(column.searchData) && column.searchData.map((v, k) => {
                                                    return <div key={k} className="form-check">

                                                        <input className="form-check-input" type="checkbox" value="" id={column.nom + k} checked={v?.checked} onChange={(e) => handleCheckBoxFiltre(e, index, v.nom)} />
                                                        <label className="form-check-label" htmlFor={column.nom + k}>
                                                            {v.nom}
                                                        </label>

                                                    </div>
                                                })}
                                                {!_.isArray(column.searchData) && Object.keys(column.searchData).map((v, k) => {
                                                    return <div key={k} className="form-check">

                                                        <input className="form-check-input" type="checkbox" value="" id={column.nom + k} checked={v?.checked} onChange={(e) => handleCheckBoxFiltre(e, index, v)} />
                                                        <label className="form-check-label" htmlFor={column.nom + k}>
                                                            {column.searchData[v].nom}
                                                        </label>

                                                    </div>
                                                })}


                                            </div>
                                        </>
                                    }

                                    {!column.searchable && column.nom}
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </nav>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4" >
                <div style={{ position: 'sticky', top: 0 }}>
                   
                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="h2">Fichier client ({clientList.length})</h1>
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <div className="btn btn-sm btn-outline-primary" onClick={(e) => handleClickGetEmails(e)}>
                                    Emails
                                </div>

                            </div>
                            <div className="btn-group me-2">
                                <Link to={"/admin/" + brasserie.id + "/clients/creer"} className="btn btn-sm btn-outline-primary">
                                    Créer
                                </Link>
                            </div>
                        </div>
                    </div>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                {
                                    columns.map((column, index) => {
                                        if (column.visible) {
                                            return <th scope="col" key={index}>{_.capitalize(column.nom)}</th>
                                        } else {
                                            return null;
                                        }
                                    })
                                }
                                <th scope="col" key="action">Action</th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {clientList.map((client, k) => {
                                console.log(client)
                                return <tr key={k}>
                                    {columns.map((column, indexC) => {
                                        if (column.visible) {
                                            switch (column.type) {
                                                case "direct":
                                                    /** if(column.translate){
                                                        return <td key={indexC}>{column.translate[_.get(client, column.path)].nom}</td>
                                                    }*/
                                                    if (column.nom === "mail") {
                                                        return <td key={indexC}><a href={"mailto:" + _.get(client, column.path)}>{_.get(client, column.path)}</a></td>
                                                    }

                                                    return <td key={indexC}>{_.get(client, column.path)}</td>
                                                case "array":
                                                    return <td key={indexC}>
                                                        {_.get(client, column.path).length && _.get(client, column.path).map(catalogueId => {
                                                            return <span key={catalogueId} className="badge text-bg-secondary mx-1">{brasserie[column.refPath][catalogueId].nom}</span>
                                                        })}
                                                    </td>
                                                case "ref":
                                                    return <td key={indexC}>
                                                        {
                                                            brasserie[column.refPath][_.get(client, column.path)]?.nom
                                                        }
                                                    </td>

                                                default:
                                                    return null;
                                            }
                                        } else {
                                            return null;
                                        }
                                    })
                                    }
                                   {/*  <td key={"commandes" + k}>
                                        {client.commandes.length}
                                    </td> */}
                                    <td key={"action" + k}>
                                        <div className="btn-group me-2">
                                            <Link to={"/admin/" + brasserie.id + "/clients/" + client.id} className="btn btn-sm btn-outline-primary">
                                                <i className="fa-solid fa-eye"></i>
                                            </Link>
                                            {client.contacts[0]?.uuid && <Link to={"/client/" + client.contacts[0].uuid} className="btn btn-sm btn-outline-primary">
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </Link>}
                                            <Link to={"/admin/" + brasserie.id + "/clients/" + client.id + "/commandes/creer"} className="btn btn-sm btn-outline-primary">
                                                <i className="fa-solid fa-cart-plus"></i>
                                            </Link>
                                            <AddCommunication brasserie={brasserie} client={client} communication={{ type: "Mail", icon: "fa-envelope-circle-check" }} />
                                            <AddCommunication brasserie={brasserie} client={client} communication={{ type: "Téléphone", icon: "fa-phone" }} />
                                            <AddCommentaire brasserie={brasserie} client={client} />
                                        </div>
                                    </td>
                                </tr>

                            })
                            }
                        </tbody>
                    </table>

                </div>
            </main>
        </div>
    </>


}