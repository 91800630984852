import React from "react";

import _ from 'lodash';

import commandeService from "../../services/commande.service";

import { Badge, Card, Col, Row } from "react-bootstrap";
import { DollarSign, ShoppingBag, ShoppingCart, Truck } from "react-feather";

export default function InsightsCommandes(props) {
    const commandes =  props.commandes
    console.log(commandes)

    const nombreCommandes = commandes.length
    const volume = Math.round(_.reduce(commandes, (result, commande) => {
        console.log(commande)
        return result + commande.statistiques.v?.total
    }, 0) * 100) / 100
    const CA = Math.round(_.reduce(commandes, (result, commande) => {
        return result + commande.statistiques.ca?.total
    }, 0) * 100) / 100
    const margeNette =  Math.round(_.reduce(commandes, (result, commande) => {
        return result + commande.statistiques.m?.total  +commande.statistiques.m?.port 
    }, 0) * 100) / 100

    return <>
        <Row>
            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-2">{nombreCommandes}</h3>
                                <p className="mb-2">Commandes</p>
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <ShoppingBag className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>

            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-2">{volume} l</h3>

                                <h3 className="mb-2"><small>{(nombreCommandes>0)?Math.round(volume/nombreCommandes*100)/100+" l/commande":""}</small></h3>
                                <p className="mb-2">Volumes</p>
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <DollarSign className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-0">{CA} €HT </h3>
                                <h3 className="mb-2"><small>{(volume>0)?Math.round(CA/volume*100)/100+" €/l":""}</small></h3>
                                <p className="mb-2">Chiffre d'Affaire</p>
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <DollarSign className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
            <Col md="6" xl className="d-flex">
                <Card className="flex-fill">
                    <Card.Body className=" py-4">
                        <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                                <h3 className="mb-0">{margeNette} €HT </h3>
                                <h3 className="mb-2"><small>{(volume>0)?Math.round(margeNette/volume*100)/100+" €/l":""}</small></h3>
                                <p className="mb-2">Marge Nette</p>
                            </div>
                            <div className="d-inline-block ms-3">
                                <div className="stat">
                                    <DollarSign className="align-middle text-success" />
                                </div>
                            </div>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}
