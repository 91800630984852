import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";

import Header from "./Header";
import Appointments from "./Appointments";
import BarChart from "./BarChart";
import Calendar from "./Calendar";
import Feed from "./Feed";
import Projects from "./Projects";
import Statistics from "./Statistics";
import ChartRevenuMarge from "./ChartRevenuMarge";
import VentesParGrilleTarifairePieChart from "./VentesParGrilleTarifaire.piechart";
import VentesParCataloguePieChart from "./VentesParCatalogue.piechart";
import { useOutletContext } from "react-router-dom";
import productService from "../../../services/product.service";
import TopClient from "./TopClient";
import TopProduit from "./TopProduit";
import StatistiquesVelociteProduit from "../Produit/StatistiquesVelociteProduit";
import { StockRepartition } from "../../../components/charts/StockRepartition";
import statistiqueServices from "../../../services/statistique.service";
import Loader from "../../../components/Loader";
import { VenteParTourneeCatalogue } from "../../../components/charts/VenteParTourneeCatalogue";
import clientService from "../../../services/client.service";

const Dashboard = () => {

  const { brasserie } = useOutletContext()

  const [dataVentes, setDataVentes] = useState([])
  const [loadingDataVentes, setLoadingDataVentes] = useState(true)
  const [produits, setProduits] = useState([])
  const [loadingProduits, setLoadingProduits] = useState(true)
  const [stockComposition, setStockComposition] = useState([])
  const [loadingStockComposition, setLoadingStockComposition] = useState(true)
  const [clients,setClients] = useState([])
  const [loadingClients,setLoadingClients] = useState(true)



  useEffect(() => {
    setLoadingDataVentes(true)
    const getDataVentes = async () => {
      productService.getStatistiquesVentes(brasserie.id).then(
        (response) => {
          setDataVentes(response.data)
          setLoadingDataVentes(false)
        }
      ).catch((err) => {
        console.log(err)
        setLoadingDataVentes(false)
      })
    }
    getDataVentes()
  }, [brasserie])

  useEffect(() => {
    setLoadingProduits(true)
    const getProduits = async () => {
      productService.getByBrasserieId(brasserie.id).then(
        (response) => {
          setProduits(response.data)
          setLoadingProduits(false)
        }
      ).catch((err) => {
        console.log(err)
      })
    }
    getProduits()
  }, [brasserie])


  useEffect(() => {
    setLoadingStockComposition(true)
    const getStockComposition = async () => {
      const respStatsStock = await statistiqueServices.getStockSnaphot(brasserie.id, new Date())
      setStockComposition(respStatsStock.data)
      setLoadingStockComposition(false)
    }
    getStockComposition()
  }, [brasserie])

  useEffect(() => {
    setLoadingClients(true)
    const getClients = async () => {
      const respClients = await clientService.getAllLight(brasserie.id)
      setClients(respClients.data)
      setLoadingClients(false)
    }
    getClients()
  },[brasserie])



  return (
    <React.Fragment>
      <Helmet title="Default Dashboard" />
      <Container fluid className="p-0">
        <Header />
       {/*  <Statistics />
        <Row>
          <Col lg="8" className="">
            <Row>
              <Col sm="12" className="d-flex">

                <ChartRevenuMarge dataVentes={dataVentes} loadingDataVentes={loadingDataVentes} />
              </Col>

            </Row>
            <Row>
              <Col md="6" xl className="d-flex">
                <TopClient dataVentes={dataVentes} loadingDataVentes={loadingDataVentes} />
              </Col>
              <Col md="6" xl className="d-flex">
                <TopProduit dataVentes={dataVentes} loadingDataVentes={loadingDataVentes} />
              </Col>
            </Row>
          </Col>
          <Col lg="4" className="d-flex">
            <Feed />
          </Col>
        </Row>
        <Row>
          <Col lg="6" xl="4" className="d-flex">
            <VentesParCataloguePieChart dataVentes={dataVentes} loadingDataVentes={loadingDataVentes} />
          </Col>
          <Col lg="6" xl="4" className="d-flex">
            <VentesParGrilleTarifairePieChart dataVentes={dataVentes} loadingDataVentes={loadingDataVentes} />
          </Col>
          <Col lg="6" xl="4" className="d-flex">
          </Col>
        </Row>
        <Row>
          <Col lg="8" xl="8" className="d-flex">

            <StatistiquesVelociteProduit produits={produits} setLoadingProduits={loadingProduits} />
          </Col>
          <Col lg="4" className="d-flex">
            {loadingStockComposition && <Loader />}
            {!loadingStockComposition && <StockRepartition data={stockComposition} brasserie={brasserie} produits={produits} />}
          </Col>

        </Row> */}
        <Row>
          <Col className="d-flex">
            {loadingStockComposition && loadingDataVentes ? <Loader />: <VenteParTourneeCatalogue data={dataVentes} clients={clients} brasserie={brasserie} produits={produits}  />}
          </Col>

        </Row>
      </Container>
    </React.Fragment>
  )
};

export default Dashboard;
