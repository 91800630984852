
import React, { Component, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import _ from "lodash"
import { useSnackbar } from 'notistack';

import LinearProgress from '@mui/material/LinearProgress';
import { useBrasserie } from "../../hooks/UseBrasserie";
import brasserieService from "../../services/brasserie.service";


export default function Conditionnements(props) {

    const defaultConditionnement = {
        nom: '',
        type: '',
        multiple: 0,
        poids:0,
        volume:0
    }

    const { brasserie } = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();

    const [conditionnement, setConditionnement] = useState(defaultConditionnement)
    const [key, setKey] = useState()

    const setData = (key,value) => {
        const newConditionnement = _.cloneDeep(conditionnement);
        newConditionnement[key] = value;
        setConditionnement(newConditionnement)
    }
   

    const editConditionnement= (conditionnementKey) => {
        setConditionnement(_.cloneDeep(brasserie.conditionnements[conditionnementKey]));
        setKey(conditionnementKey)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const objects = brasserie.conditionnements || {};
        if(key!==null && key!== undefined){
            objects[key] = conditionnement
        }else{
            objects[_.kebabCase(conditionnement.nom)] = conditionnement
        }
        brasserie.conditionnements = objects
        console.log(objects)

        setConditionnement(defaultConditionnement)
        brasserieService.updateConditionnements(brasserie,objects).then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Les conditionnements ont été mis à jour.', { variant: 'success' })
                setKey(undefined)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }

    if (true) {
        return (
            <div className="row">
                <div className="col-sm-12">
                <form onSubmit={(e)=>handleSubmit(e)}>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                {
                                    Object.keys(defaultConditionnement).map((key) => {
                                        return <th scope="col" key={key}>{_.upperFirst(key)}</th>
                                    })
                                }
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (brasserie.conditionnements && Object.keys(brasserie.conditionnements).map((key) => {
                                    return <tr key={key}>
                                       {
                                            Object.keys(defaultConditionnement).map((keyField) => {
                                                return <td key={keyField}>{brasserie.conditionnements[key][keyField]}</td>
                                            })
                                        }
                                        <td>
                                            <button type="button" className="btn btn-primary" onClick={(e) => editConditionnement(key)} >Editer</button>
                                        </td>
                                    </tr>
                                }))
                            }
                            <tr >
                                <td>
                                    <input type="hidden"  id="key" value={key} />
                                    <input type="text" className="form-control" id="style" placeholder="Nom" required onChange={(e) => setData('nom',e.target.value)} value={conditionnement.nom} />
                                </td>
                                <td>
                                    <input type="text" className="form-control" id="style" placeholder="Type" required onChange={(e) => setData('type',e.target.value)} value={conditionnement.type} />
                                </td>
                                <td>
                                    <input type="text" className="form-control" id="style" placeholder="Multiple" required onChange={(e) => setData('multiple',parseInt(e.target.value))} value={conditionnement.multiple} />
                                </td>
                                <td>
                                    <input type="text" className="form-control" id="style" placeholder="Poids" required onChange={(e) => setData('poids',parseFloat(e.target.value))} value={conditionnement.poids} />
                                </td>
                                <td>
                                    <input type="text" className="form-control" id="style" placeholder="Volume" required onChange={(e) => setData('volume',parseFloat(e.target.value))} value={conditionnement.volume} />
                                </td>
                                <td>
                                    <button type="submit" className="btn btn-primary" >{(key)?"Editer \""+brasserie.conditionnements[key].nom+"\"":"Créer un conditionnement"}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                                </form>

                </div>
            </div>

        )
    }else{
        return <LinearProgress />
    }
   
}