import React from "react";



import { useOutletContext } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CommandeProvider from "../../contexts/CommandeProvider";
import BonDeCommande from "../../components/bonDeCommande";



export default function CommandeNew() {


    const { client, brasserie, catalogue, contact, incomplet } = useOutletContext()

    const idCommande = null
   

    return <>
        <Helmet title="Nouvelle commande" />
        <CommandeProvider initialState={{
                    client: client,
                    catalogue: catalogue,
                    commande: null,
                    filters: { conditionnements: {}, catalogues: {} },
                    loading: false,
                    error: null,
                    brasserie: brasserie
                }}>
                    <BonDeCommande id={idCommande} />
                </CommandeProvider>
    </>


}