import _ from 'lodash';

import http from "../http-common";

export class Commande {


    static STATUT_EN_CREATION = -1
    static STATUT_OUVERTE = 0
    static STATUT_MODIFIEE = 1
    static STATUT_VALIDEE = 2
    static STATUT_EXPEDIEE = 3
    static STATUT_FACTUREE = 4
    static STATUT_ANNULEE = 5

    static STATUTS = ['ouverte', 'modifiée', 'validée', 'expédiée', 'facturée', 'annulée']

    constructor(commande = null) {
        this.id = commande?.id || null
        this.brasserie = commande?.brasserie || null
        this.client = commande?.client || null
        this.catalogue = commande?.catalogue || null
        this.created = commande?.created || new Date().getTime()
        this.updated = commande?.updated || new Date().getTime()
        this.quantities = commande?.quantities || {}
        this.tarifs = commande?.tarifs || {}
        this.sousTotal = commande?.sousTotal || 0
        this.statut = commande?.statut || this.STATUT_EN_CREATION
        this.port = commande?.port || {}
        this.statistiques = commande?.statistiques || {}
        this.facture = commande?.facture || ""
        this.signature = commande?.signature || ""
        this.photoLivraison = commande?.photoLivraison || ""
        this.events = commande?.events || []
    }

    

    //init a new commande
    static initNew(brasserie, client, catalogue) {
        var nouvelleCommande = new Commande()
        nouvelleCommande.brasserie = brasserie
        nouvelleCommande.client = client
        nouvelleCommande.catalogue = catalogue
        nouvelleCommande.quantities = {}
        nouvelleCommande.tarifs = {}
        nouvelleCommande.sousTotal = 0
        nouvelleCommande.statut = this.STATUT_EN_CREATION
        nouvelleCommande.port =  {
            expedition: (!client.tournee && client.livraison.codePostal !== "") ? true : false,
            cout: Math.round((client.transporteur.gazoil / 100 * client.transporteur.price + client.transporteur.price) * 1.05*100)/100,
            franco: 0,
            participation: 0,
            tournee: (client.tournee) ? true : false,
            coutTournee: 0,
            francoTournee:0,
            participationTournee: 0,
            retrait: (client.livraison.codePostal === "" && !client.tournee) ? true : false,
            remise:0,
            offert: false,
            sousTotal: 0,
        }
        nouvelleCommande.statistiques = {}
        nouvelleCommande.events = []
        nouvelleCommande.facture = ""
        nouvelleCommande.signature = ""
        nouvelleCommande.photoLivraison = ""
        nouvelleCommande.created = new Date().getTime()
        nouvelleCommande.updated = new Date().getTime()

        //init quantities
        nouvelleCommande.initQuantities()
        nouvelleCommande.initTarifs()
        nouvelleCommande.updateSousTotal()
        nouvelleCommande.updateStatistiques()
        nouvelleCommande.updatePort()

        return nouvelleCommande
    }

    //init a commande from a json object from api
    static initFromJson( commande,brasserie = null, catalogue = null, client = null) {
        var nouvelleCommande = new Commande()
        nouvelleCommande.id = commande.id
        nouvelleCommande.brasserie = brasserie 
        nouvelleCommande.catalogue = catalogue
        nouvelleCommande.client = client || commande.client
        nouvelleCommande.created = commande.created
        nouvelleCommande.updated = commande.updated
        nouvelleCommande.quantities = commande.quantities
        nouvelleCommande.tarifs = commande.tarifs
        nouvelleCommande.sousTotal = commande.sousTotal
        nouvelleCommande.statut = commande.statut
        nouvelleCommande.port = commande.port
        if(client || commande.port.cout === 0) {
               //merge client from commande and client from param
               client = {...client, ...commande.client}
            
            nouvelleCommande.port.cout = Math.round((client.transporteur.gazoil / 100 * client.transporteur.price + client.transporteur.price) * 1.05*100)/100
        } 
       
        nouvelleCommande.statistiques = commande.s
        nouvelleCommande.facture = commande.facture
        nouvelleCommande.signature = commande.signature
        nouvelleCommande.photoLivraison = commande.photoLivraison
        nouvelleCommande.events = commande.events || []

        if (nouvelleCommande.statut < this.STATUT_VALIDEE) {
            if(catalogue) {
                nouvelleCommande.initTarifs()
                nouvelleCommande.updateSousTotal()
                nouvelleCommande.updatePort()
                if(brasserie) {
                    nouvelleCommande.updateStatistiques()
                }
            }
            

        }
        return nouvelleCommande
    }

    //init quantities from catalogue
    initQuantities() {
        this.quantities = {}

        _.forEach(this.catalogue.getRawProducts(), (item) => {
            _.forEach(item.stocks, (stock, conditionnementId) => {
                if (!this.quantities[item.id]) {
                    this.quantities[item.id] = {}
                    this.quantities[item.id][conditionnementId] = { quantite: 0, lots: [] }
                } else {
                    if (!this.quantities[item.id][conditionnementId]) {
                        this.quantities[item.id][conditionnementId] = { quantite: 0, lots: []}
                    }
                }
            })
        })
    }

    //init tarifs from catalogue
    initTarifs() {
        this.tarifs = {}

        _.forEach(this.catalogue.getRawProducts(), (item) => {
            _.forEach(item.stocks, (stock, conditionnementId) => {
                if( this.brasserie.pricings[this.client.pricing].coefs[stock.catalogue]){
                    const prix = (stock.prix && this.brasserie.pricings[this.client.pricing].coefs[stock.catalogue][conditionnementId]) ? Math.round(stock.prix * this.brasserie.pricings[this.client.pricing].coefs[stock.catalogue][conditionnementId] * 100) / 100 : 0

                    //trouver le tarif reference pour la brasserie (brasserie.pricings[x].reference = true)
    
                    const pricingRef = _.find(this.brasserie.pricings, (pricing) => {
                        return pricing.reference === true
                    })
    
                    // calculer egalement le prix de reference pour le calcul de la marge en ajoutant utilisant le tarif de reference de la brasserie
                    console.log("pricingRef", pricingRef)
                    console.log("conditionnementId", conditionnementId)
                    console.log("stock.catalogue", stock.catalogue)
                    const prixRef = (stock.prix && pricingRef.coefs[stock.catalogue][conditionnementId]) ? Math.round(stock.prix * pricingRef.coefs[stock.catalogue][conditionnementId] * 100) / 100 : 0
    
                    if (!this.tarifs[item.id]) {
                        this.tarifs[item.id] = {}
                        this.tarifs[item.id][conditionnementId] = { prix: prix, prixRef: prixRef, base: Number(stock.prix) }
                    } else {
                        if (!this.tarifs[item.id][conditionnementId]) {
                            this.tarifs[item.id][conditionnementId] = { prix: prix, prixRef: prixRef, base: Number(stock.prix) }
                        }
                    }
                }
               
            })
        })

    }

    setQuantities(quantities) {

        this.quantities = quantities

        this.updateSousTotal()
        this.updatePort()
        this.updateStatistiques()
    }

    updateSousTotal() {
        this.sousTotal = this.calculSubTotalBeer("client")
    }

    calculSubTotalBeer(tarif) {
        return Math.round(_.reduce(this.quantities, (result, quantite, productId) => {
            return result + _.reduce(quantite, (result, conditionnement, conditionnementId) => {
                if (conditionnement.quantite === 0) {
                    return result
                } else {
                    let prix = 0;
                    switch (tarif) {
                        case "base":
                            prix = this.tarifs[productId][conditionnementId].base
                            break;
                        case "ref":
                            prix = this.tarifs[productId][conditionnementId].prixRef
                            break;
                        case "client":
                        default:
                            prix = this.tarifs[productId][conditionnementId].prix
                            break;
                    }

                    return result + prix * conditionnement.quantite

                }
            }, 0)
        }, 0) * 1000) / 1000

    }

    updatePort() {
        const dataPort = this.port;

        //init remise if soustotal is > 0
        if (this.sousTotal > 0 && this.client.pricing !== "distributeur") {
            dataPort.remise = (-Math.round(this.sousTotal * 0.05 * 100) / 100)
        } else {
            dataPort.remise = 0
        }

        //gestion participation si tournée
        if (this.client.tournee === "aucune" || this.client.tournee === "" || this.client.tournee === undefined || this.client.tournee === null) {

        } else {
            dataPort.francoTournee = this.brasserie.tournees[this.client.tournee].franco

            dataPort.coutTournee = this.brasserie.tournees[this.client.tournee].participation
            if (dataPort.francoTournee === 0) {
                dataPort.participationTournee = 0
            } else {
                if (this.sousTotal > 0) {
                    if (dataPort.francoTournee > this.sousTotal) {
                        dataPort.participationTournee = this.brasserie.tournees[this.client.tournee].participation
                    } else {
                        dataPort.participationTournee = (0)
                    }
                } else {
                    dataPort.participationTournee = (0)
                }
            }
        }
        //gestion participation si expedition
        
        const franco = this.getFranco();
        dataPort.franco = franco;
        if (franco !== null) {
            if (franco === 0) {
                dataPort.participation = (Math.round(dataPort.cout * 100) / 100)
            } else {
                if (this.sousTotal > 0) {
                    if (franco > this.sousTotal) {
                        if (this.sousTotal < franco / 2) {
                            dataPort.participation = (dataPort.cout)
                        } else {
                            let coefProportionnel
                            coefProportionnel = -2 * dataPort.cout / franco
                            dataPort.participation = Math.round((this.sousTotal * coefProportionnel) + 2 * dataPort.cout)
                        }
                    } else {
                        dataPort.participation = (0)
                    }
                } else {
                    dataPort.participation = (0)
                }
            }
        }
        //set dataPort.sousTotal en fonction du mode de livraison (offert,retrait,tournée,expédition)
        if (dataPort.retrait) {
            dataPort.sousTotal = dataPort.remise
        } else if (dataPort.tournee) {
            dataPort.sousTotal = dataPort.participationTournee
        } else if (dataPort.offert) {
            dataPort.sousTotal = 0
        } else {
            dataPort.sousTotal = dataPort.participation
        }

        this.port = dataPort;
    }

    getFranco() {
        if (this.getMarginForOrder() > 0) {
            return this.getCaMini()
        } else {
            return 0
        }
    }

    getMarginForOrder() {
        const refCA = this.calculSubTotalBeer("ref")
        const orderCA = this.calculSubTotalBeer("client")
        console.log("refCA", refCA)
        console.log("orderCA", orderCA)
        return (orderCA - refCA) / refCA
    }

    

    getCaMini = () => {
        let transportPrice = this.port.cout;
        console.log("transportPrice", transportPrice)
        let CAMini = transportPrice / (this.getMarginForOrder()) + transportPrice;
        let franco = Math.ceil(CAMini * 0.1) / 0.1;
        console.log("franco", franco)
        return franco;
    }

    updateStatistiques() {
        const s = {
            //volume
            v: {
                total: 0,
                conditionnements: {},
                catalogues: {}
            },
            //chiffre d'affaire
            ca: {
                total: 0,
                conditionnements: {},
                catalogues: {},
                port: 0,
            },
            //poids
            poids: {
                total: 0,
                conditionnements: {},
                catalogues: {}
            },
            //marge
            m: {
                total: 0,
                conditionnements: {},
                catalogues: {},
                port: 0,
            },
            //nombre de produits
            nb: {
                total: 0,
                conditionnements: {},
                catalogues: {}
            },
        }
        const brasserie = this.brasserie
        _.forEach(this.quantities, (quantite, productId) => {
            _.forEach(quantite, (conditionnement, conditionnementId) => {
                if (conditionnement.quantite === 0) {
                    return
                } else {
                    const conditionnementData = brasserie.conditionnements[conditionnementId]
                    const tarif = this.tarifs[productId][conditionnementId]
                    const catalogue = this.catalogue.getProductById(productId).stocks[conditionnementId].catalogue
                    const nb = conditionnement.quantite
                    const volume = conditionnementData.volume * nb
                    const poids = conditionnementData.poids * nb
                    const marge = (tarif.prix - conditionnement.prix) * nb
                    const ca = tarif.prix * nb
                    s.v.total += volume
                    s.v.conditionnements[conditionnementId] = (s.v.conditionnements[conditionnementId] || 0) + volume
                    s.v.catalogues[catalogue] = (s.v.catalogues[catalogue] || 0) + volume
                    s.ca.total += ca
                    s.ca.conditionnements[conditionnementId] = (s.ca.conditionnements[conditionnementId] || 0) + ca
                    s.ca.catalogues[catalogue] = (s.ca.catalogues[catalogue] || 0) + ca
                    s.poids.total += poids
                    s.poids.conditionnements[conditionnementId] = (s.poids.conditionnements[conditionnementId] || 0) + poids
                    s.poids.catalogues[catalogue] = (s.poids.catalogues[catalogue] || 0) + poids
                    s.m.total += marge
                    s.m.conditionnements[conditionnementId] = (s.m.conditionnements[conditionnementId] || 0) + marge
                    s.m.catalogues[catalogue] = (s.m.catalogues[catalogue] || 0) + marge
                    s.nb.total += nb
                    s.nb.conditionnements[conditionnementId] = (s.nb.conditionnements[conditionnementId] || 0) + nb
                    s.nb.catalogues[catalogue] = (s.nb.catalogues[catalogue] || 0) + nb
                }
            })
        })
        const dataPort = this.port;
        if (dataPort.retrait) {
            s.ca.port = dataPort.remise
            s.m.port = dataPort.remise
        } else if (dataPort.expedition) {
            s.ca.port = dataPort.participation
            s.m.port = - dataPort.cout + dataPort.participation
        } else if (dataPort.tournee) {
            s.ca.port = dataPort.participationTournee
            s.m.port = - dataPort.coutTournee + dataPort.participationTournee
        } else if (dataPort.offert) {
            s.ca.port = 0
            s.m.port = 0
        }
        //round to 3 decimals
        _.forEach(s, (value, key) => {
            _.forEach(value, (value2, key2) => {
                if (typeof value2 === 'number') {
                    s[key][key2] = Math.round(value2 * 1000) / 1000
                }
                //if type is object, round to 3 decimals
                if (typeof value2 === 'object') {
                    _.forEach(value2, (value3, key3) => {
                        s[key][key2][key3] = Math.round(value3 * 1000) / 1000
                    })
                }
            })
        })

        this.statistiques = s
    }

    addEvent(event) {
        this.events.push(event)
    }

    toJSON() {
        const data = {
            id: this.id,
            client: this.cleanClient(this.client),
            quantities: this.quantities,
            tarifs: this.tarifs,
            sousTotal: this.sousTotal,
            statut: this.statut,
            port: this.port,
            facture: this.facture,
            photoLivraison: this.photoLivraison,
            signature: this.signature,
            s: this.statistiques,
            created: this.created,
            updated: this.updated,
            events: this.events,
        }
        if(this.brasserie){
            data.brasserieId = this.brasserie.id
        }
        return data
    }

    cleanClient(client) {
        const newClient = _.cloneDeep(client);
        delete newClient.events
        delete newClient.commentaires
        delete newClient.commandes
        return newClient
      }

    create() {
        const data = this.toJSON()
        return http.post("/clients/" + this.client.id + '/commandes', { order: data })
    }
    update() {
        const data = this.toJSON()
        return http.put("/clients/" + this.client.id + '/commandes/' + this.id, { client: data.client, order: data })
    }
    cancel(){
        const data = this.toJSON()
        return http.put("/clients/" + this.client.id + '/commandes/' + this.id + '/annuler', { client: data.client, order: data })
    }
    delete() {
        return http.delete("/clients/" + this.client.id + '/commandes/' + this.id)
    }

}