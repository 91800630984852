import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
//import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { SnackbarProvider } from 'notistack';
import { HelmetProvider, Helmet } from "react-helmet-async";
import _ from "lodash"

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import PublicLayout from './layouts/public.layout'
import Accueil from './pages/Accueil';
import DemandeTarif, { loader as demandeDeTarifLoader } from './pages/DemandeTarif';
import DemandeAcces, { loader as demandeAccesLoader } from './pages/DemandeAcces';

import ClientLayout, { loader as clientLoader } from './layouts/client.layout'
import Client from './pages/client/Client';
import Catalogue from './pages/client/Catalogue';
import Produit, { loader as clientProductLoader } from './pages/client/Produit';
import CommandeNew from './pages/client/Commande.new'
import Commandes, { loader as clientCommandesLoader } from './pages/client/Commandes';
import CommandeEdit from './pages/client/Commande.edit';
import Merci from './pages/client/Merci';

import AdminLayout, { loader as adminLoader } from './layouts/admin.layout';
import Admin, { loader as adminDashboardLoader } from './pages/admin/Admin';
import Login from './pages/Login';
import Prospects, { loader as adminProspectsLoader } from './pages/admin/Prospects';
import Clients, { loader as adminClientsLoader } from './pages/admin/Clients';
import ClientEdit, { loader as adminClientLoader } from './pages/admin/Client.edit';
import ClientNew from './pages/admin/Client.new';
import Produits, { loader as adminProductsLoader } from './pages/admin/Produits';
import ProduitAdmin, { loader as adminProductLoader } from './pages/admin/Produit/index';
import Inventaires, { loader as adminInventairesLoader } from './pages/admin/Inventaires';
import InventairesNew, { loader as adminInventairesCreerLoader } from './pages/admin/Inventaires.new';
import { Commandes as CommandesAdmin, loader as adminCommandesLoader } from './pages/admin/Commandes';
import { CommandeNew as CommandeNewAdmin, loader as adminCommandesNewLoader } from './pages/admin/Commande.new';
import { CommandeEdit as CommandeEditAdmin, loader as adminCommandesEditLoader } from './pages/admin/Commande.edit';
import { RemiseNew, loader as adminRemisesCreerLoader } from './pages/admin/Remise.new';
import GrillesTarifaires from './pages/admin/GrillesTarifaires';
import Catalogues from './pages/admin/Catalogues';
import Conditionnements from './pages/admin/Conditionnements';
import Tournees from './pages/admin/Tournees';

import ReactGA from "react-ga4";
import Mercurial, { loader as adminMercurialLoader } from './pages/admin/Mercurial';
import ErrorPage from './pages/ErrorPage';
import { SessionProvider } from './contexts/SessionContext';
import Dashboard from './pages/admin/Dashboard';
import Test from './pages/admin/Test';


ReactGA.initialize('G-SMLGSZNQNE');

const root = ReactDOM.createRoot(document.getElementById('root'));

const routes = [
    {
        path: "/",
        element: <PublicLayout />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Accueil />,
            },
            {
                path: "demande-de-tarif/:email",
                loader: demandeDeTarifLoader,
                element: <DemandeTarif />,
            },
            {
                path: "demande-acces/:email",
                loader: demandeAccesLoader,
                element: <DemandeAcces />,
            },{
                path: "login",
                element: <Login />,
            }
        ],
    },
    {
        path: "/client/:clientUUID",
        id: 'client',
        element: <ClientLayout />,
        errorElement: <ErrorPage />,
        loader: clientLoader,
        shouldRevalidate: ({ currentUrl }) => {
            // only revalidate if the submission originates from
            // the `/meal-plans/new` route.
            console.log("CUREEEENT", currentUrl)
            return _.endsWith(currentUrl.pathname, "merci");
        },
        children: [
            {
                path: "",
                element: <Catalogue />,
            },
            {
                path: "fiche-client",
                element: <Client />,
            },
            {
                path: "catalogue",
                element: <Catalogue />,
            },
            {
                path: "catalogue/:produitId",
                element: <Produit />,
                loader: clientProductLoader,
            },
            {
                path: "bon-de-commande",
                element: <CommandeNew />
            },
            {
                path: "merci",
                element: <Merci />,
            },
            {
                path: "commandes",
                loader: clientCommandesLoader,
                element: <Commandes />,
            },
            {
                path: "commandes/:idCommande",
                element: <CommandeEdit />,
            },
        ],
    },
    {
        path: "/admin/:brasserieId",
        element: <AdminLayout />,
        errorElement: <ErrorPage />,
        loader: adminLoader,
        shouldRevalidate: ({ currentUrl }) => {
            // only revalidate if the submission originates from
            // the `/meal-plans/new` route.
            console.log("CUREEEENT", currentUrl)
            return _.endsWith(currentUrl.pathname, "grilles-tarifaires") || _.endsWith(currentUrl.pathname, "catalogues") || _.endsWith(currentUrl.pathname, "conditionnements") || _.endsWith(currentUrl.pathname, "tournees");
        },
        children: [
            {
                path: "",
                loader: adminDashboardLoader,
                element: <Admin />,
            },{
                path: "test",
                element: <Test />,
            },{
                path: "dashboard",
                loader: adminDashboardLoader,
                element: <Dashboard />,
            }, {
                path: "demandes-de-tarifs",
                loader: adminProspectsLoader,
                element: <Prospects />,
            },
            {
                path: "clients",
                loader: adminClientsLoader,
                element: <Clients />,
            },
            {
                path: "clients/creer",
                element: <ClientNew />,
            },
            {
                path: "clients/:clientId",
                loader: adminClientLoader,
                element: <ClientEdit />,
            },
            {
                path: "clients/:clientId/commandes/creer",
                element: <CommandeNewAdmin />,
            },
            {
                path: "clients/:clientId/commandes/:commandeId",
                element: <CommandeEditAdmin />,
            },
            {
                path: "produits",
                loader: adminProductsLoader,
                element: <Produits />,
            },
            {
                path: "produits/:productId",
                loader: adminProductLoader,
                element: <ProduitAdmin />,
            },
            {
                path: "mercurial/:pricingId",
                loader: adminMercurialLoader,
                element: <Mercurial />,
            },
            {
                path: "commandes",
                loader: adminCommandesLoader,
                element: <CommandesAdmin />,
            },
            {
                path: "commandes/creer/:clientId",
                loader: adminCommandesNewLoader,
                element: <CommandeNewAdmin />,
            },
            {
                path: "commandes/:orderId",
                loader: adminCommandesEditLoader,
                element: <CommandeEditAdmin />,
            },
            {
                path: "inventaires",
                loader: adminInventairesLoader,
                element: <Inventaires />,
            },
            {
                path: "inventaires/creer",
                loader: adminInventairesCreerLoader,
                element: <InventairesNew />,
            },
            {
                path: "remises/creer",
                loader: adminRemisesCreerLoader,
                element: <RemiseNew />,
            },
            {
                path: "grilles-tarifaires",
                element: <GrillesTarifaires />,
            },
            {
                path: "catalogues",
                element: <Catalogues />,
            },
            {
                path: "conditionnements",
                element: <Conditionnements />,
            },
            {
                path: "tournees",
                element: <Tournees />,
            },
        ],
    },
];



const router = createBrowserRouter(routes);

root.render(
    <HelmetProvider>
        <Helmet
            titleTemplate="%s | Catalogue Pro - Brasserie Le Père l'Amer"
            defaultTitle="Catalogue pour les clients Pro de la Brasserie Le Père l'Amer"
        />
        <SnackbarProvider maxSnack={3}>
            <SessionProvider>
                <RouterProvider router={router}>
                </RouterProvider>
            </SessionProvider>

        </SnackbarProvider>
    </HelmetProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
