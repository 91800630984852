import React, { useCallback, useContext, useRef, useState } from "react";

import _ from 'lodash'
import { useSnackbar } from 'notistack';
import commandeService from "../../../services/commande.service";
import { Alert, Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";

import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import Webcam from "react-webcam";
import { useCommande, useCommandeDispatch } from "../../../contexts/CommandeProvider";
import { SessionContext } from "../../../contexts/SessionContext";
import clientService from "../../../services/client.service";
import { useNavigate } from "react-router-dom";
import { Commande } from "../../../model/Commande.model";
import SignatureCanvas from 'react-signature-canvas'

export default function Facturation () {

    const { commande, changementStatutLoading } = useCommande();
    const dispatch = useCommandeDispatch()
    const { enqueueSnackbar } = useSnackbar();
    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin
    const [show, setShow] = useState(false)
    const [progresspercent, setProgresspercent] = useState(0)

    const handleUpload = (e) => {
        e.preventDefault()
        dispatch({ type: 'changementStatutLoading' })
        const file = e.target.files[0]
        const storageRef = ref(storage, `files/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on("state_changed",
            (snapshot) => {
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                setProgresspercent(progress);
            },
            (error) => {
                enqueueSnackbar(error, { variant: 'error' })
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    commande.facture = downloadURL
                    const oldStatut = commande.statut
                    commande.statut = Commande.STATUT_FACTUREE
                    const event = {
                        timestamp: new Date().getTime(),
                        nom: 'facture',
                        source: 'Admin',
                        context: 'commande'
                    }; 
                    commande.addEvent(event)
                    commande.update().then(resp => {
                        if (resp.data.id) {
                           
                            clientService.addEvent(commande.brasserie, commande.client, event)
                           
                            enqueueSnackbar('La facture est ajoutée.', { variant: 'success' })
                            setShow(false)
                        } else {
                            commande.statut = oldStatut
                            enqueueSnackbar('Un truc n\'a pas marché :(.', { variant: 'error' })
                        }
                        dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })

                    }).catch(e => {
                        console.log(e)
                        commande.statut = oldStatut
                        enqueueSnackbar(e.response.data.error, { variant: 'error' })

                        dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })
                    })
                });
            }
        );
    }
    return <>
        <div className="d-flex mb-3">
            <Button variant="info" className="flex-fill" onClick={(e) => setShow(!show)} disabled={changementStatutLoading}>{!changementStatutLoading ? 'Ajouter la facture' : <Spinner
                animation="border"
                variant="primary"
            />}</Button>

            <Modal show={show} onHide={(e) => setShow(false)} className={"modal-colored modal-info"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter une facture à la commande</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center m-3">
                    <p className="mb-2 text-white">
                        Après avoir uploader la facture, le client y aura accès et pourra la télécharger. Le statut de la commande passera à "Facturée".
                    </p>

                    <Form.Group className="mb-3">
                        <Form.Control type="file" name="file" onChange={(e) => handleUpload(e)} />
                        <Form.Text className="text-muted">
                            <ProgressBar
                                className="mt-3"
                                variant="primary"
                                now={progresspercent}
                                label={`${progresspercent}%`}
                            />
                        </Form.Text>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={(e) => setShow(false)}>Fermer</Button>
                </Modal.Footer>

            </Modal>
        </div>
    </>
}