import React, { createContext, useContext, useEffect, useState } from 'react';
import brasserieService from '../services/brasserie.service';

const initialState = {
    brasserie: {},
    brasserieDN: undefined,
};

const BrasserieContext = createContext(initialState);

export function BrasserieProvider({ children }) {

    console.log("hook Brasserie")
    const [brasserieDN, setBrasserieDN] = useState(window.location.hostname);
    const [brasserie, setBrasserie] = useState({});

    const [loading, setLoading] = useState(false);
    const [brasserieLoaded, setBrasserieLoaded] = useState(false);

    

    useEffect(() => {
        const getBrasserie = () => {
            setLoading(true)
    
            brasserieService.get(window.location.hostname).then((resp) => {
                if (resp.data === null) {
                    setBrasserie({});
                    setLoading(false)
                    setBrasserieLoaded(true)
                    //TODO add sneackers error
                } else {
                    let brasserie = resp.data;
                    setBrasserie(brasserie);
                    setLoading(false)
                    setBrasserieLoaded(true)
                }
            });
        }
        getBrasserie();
    }, [brasserieDN]);


    return (
        <BrasserieContext.Provider value={{ loading, brasserieLoaded, brasserie,setBrasserie }}>
            {children}
        </BrasserieContext.Provider>
    );
}

export const useBrasserie = () => useContext(BrasserieContext);