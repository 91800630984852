
import React from "react";
import { useState } from 'react';
import { redirect, useNavigate } from "react-router-dom";



import clientService from '../services/client.service';

export default function Accueil(props) {

  //navigation hook
  const navigate = useNavigate()
  const [email, setEmail] = useState('');
 

  const handleSubmitMail = (e, email) => {
    e.preventDefault();
    clientService.getByContactEmail(email).then(resp => {
      console.log(resp)
      if (resp.data !== null) {
        navigate('/demande-acces/'+email,{replace:true})
      } else {
        //redirect to /demande-de-prix
        navigate('/demande-de-tarif/'+email,{replace:true})
        //store prospect status in session
      }
    });
  }

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="row align-items-center g-lg-5 py-5">
            <div className="col-lg-7 text-center text-lg-start">
              <h1 className="display-4 fw-bold lh-1 mb-3">Le catalogue du Père l'Amer</h1>
              <p className="col-lg-10 fs-4">Il suffit de saisir votre email* pour accéder au catalogue, à vos conditions tarifaires et nous envoyer votre commande en 1 clic. Le turfu.</p>
              <small className="col-lg-10 text-muted">* Vous recevrez un lien d'accès dans votre boite mail. Sans mot de passe mais sécurisé \o/</small>
            </div>
            <div className="col-md-10 mx-auto col-lg-5">
              <form className="p-4 p-md-5 border rounded-3 bg-light" onSubmit={(e) => handleSubmitMail(e, email)}>
                <div className="form-floating mb-3">
                  <input className="form-control me-2" type="email" placeholder="E-mail" aria-label="Search" required onChange={(e) => setEmail(e.target.value)} value={email} autoComplete name="email" />
                  <label htmlFor="floatingInput">Votre adresse mail</label>
                </div>
                <button className="btn btn-primary" type="submit">Accéder au catalogue</button>
                <hr className="my-4" />
                <small className="text-muted">Si votre fiche n'est pas encore créée, vous aurez la possibilité de nous envoyer vos informations à jour.</small>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
      </div>
    </div>
  )
}