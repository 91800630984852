
import React, { useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";

import _ from "lodash"
import { useSnackbar } from 'notistack';

import * as math from "mathjs"

import LinearProgress from '@mui/material/LinearProgress';
import brasserieService from "../../services/brasserie.service";


export default function GrillesTarifaires(props) {



    const { brasserie } = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();

    const defaultPricing = {
        coefs: _.mapValues(brasserie.catalogues, function () {
            return _.mapValues(brasserie.conditionnements, function () {
                return 0
            })
        }),
        nom: ''
    }

    const [pricing, setPricing] = useState(defaultPricing)
    const [key, setKey] = useState()

    const setNom = (nom) => {
        const newPricing = _.cloneDeep(pricing);
        newPricing.nom = nom;
        setPricing(newPricing)
    }
    const setCoef = (value, conditionnement, catalogue) => {
        const newPricing = _.cloneDeep(pricing);
        newPricing.coefs[catalogue][conditionnement] = value;
        setPricing(newPricing)
        console.log(newPricing)
    }

    const editPricing = (pricingKey) => {
        const pricingToEdit = _.defaultsDeep(brasserie.pricings[pricingKey],defaultPricing)
        console.log(defaultPricing,pricingToEdit)
        setPricing(pricingToEdit)
        setKey(pricingKey)
    }

    const handleSubmitPricing = (e) => {
        e.preventDefault()
        const pricings = brasserie.pricings || {};
        if (key !== null && key !== undefined) {
            pricings[key] = pricing
        } else {
            pricings[_.kebabCase(pricing.nom)] = pricing
        }

        brasserie.pricings = pricings
        setPricing(defaultPricing)
        brasserieService.updatePricings(brasserie, pricings).then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Grilles de prix mise à jour.', { variant: 'success' })
                setKey(undefined)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }


    if (true) {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <form onSubmit={(e) => handleSubmitPricing(e)}>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <th scope="col" style={{width:180}}>Catalogues</th>
                                    {Object.keys(brasserie.conditionnements).map((conditionnementKey) => {
                                        return <th scope="col" key={conditionnementKey}>{brasserie.conditionnements[conditionnementKey].nom}</th>
                                    })}
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (brasserie.pricings && Object.keys(brasserie.pricings).map((pricingKey) => {
                                        return <tr key={pricingKey}>
                                            <td>
                                                {brasserie.pricings[pricingKey].nom}
                                            </td>
                                            <td>
                                                {Object.keys(brasserie.catalogues).map((catalogueKey) => {
                                                    return <p  key={catalogueKey}>{brasserie.catalogues[catalogueKey].nom}</p>
                                                })}
                                            </td>
                                            {Object.keys(brasserie.conditionnements).map((conditionnementKey) => {
                                                return <td key={conditionnementKey}>
                                                    {Object.keys(brasserie.catalogues).map((catalogueKey) => {
                                                        if(brasserie.pricings[pricingKey].coefs[catalogueKey]){
                                                            return <p key={catalogueKey}>{brasserie.pricings[pricingKey].coefs[catalogueKey][conditionnementKey]}</p>
                                                        }else{
                                                            return <p key={catalogueKey}>0</p>
                                                        }
                                                        
                                                    })
                                                    }
                                                </td>
                                            })}

                                            <td>
                                                <button type="button" className="btn btn-primary" onClick={(e) => editPricing(pricingKey)} >Editer</button>
                                            </td>
                                        </tr>

                                    }))
                                }
                                <tr >
                                    <td>
                                        <input type="hidden" id="key" value={key} />
                                        <input type="text" className="form-control" id="nom" placeholder="Nom" required onChange={(e) => setNom(e.target.value)} value={pricing.nom} />
                                    </td>
                                    <td>
                                        {Object.keys(brasserie.catalogues).map((catalogueKey) => {
                                            return <p key={catalogueKey}>{brasserie.catalogues[catalogueKey].nom}</p>
                                        })}
                                    </td>
                                    {Object.keys(brasserie.conditionnements).map((conditionnementKey) => {
                                        return <td key={conditionnementKey}>
                                            {Object.keys(brasserie.catalogues).map((catalogueKey) => {
                                                return <input key={catalogueKey} type="text" className="form-control" id={conditionnementKey.catalogueKey} placeholder="Formule" onChange={(e) => setCoef(e.target.value, conditionnementKey, catalogueKey)} value={pricing.coefs[catalogueKey][conditionnementKey] || ''} />
                                            })
                                            }
                                        </td>
                                    })}
                                    <td>
                                        <button type="submit" className="btn btn-primary" >{(key) ? "Editer la grille" : "Créer la grille"}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>

                </div>
            </div>

        )
    } else {
        return <LinearProgress />
    }

}