
import React, { Component, useState } from "react";
import { useLoaderData, useOutletContext, useParams } from "react-router-dom";

import _ from "lodash"

import EditProduct from "./edit";

import LinearProgress from '@mui/material/LinearProgress';

import productService from "../../../services/product.service";
import { useBrasserie } from "../../../hooks/UseBrasserie";


export const loader = async ({ params }) => {
    try {
        let product;
        if(params.productId === 'new'){
            product = null;
        }else{
        const respProducts = await productService.getById(params.brasserieId, params.productId);
        product = respProducts.data;
        }
        return { product: product }

    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function Produit(props) {

    const { productId } = useParams();
    const { brasserie } = useOutletContext();
    const { product } = useLoaderData();

    if (true) {
        console.log('productId',productId)
        return (
            <div className="row">
                <div className="col-sm-12">

                    {productId === 'new' && <EditProduct  />}
                    {productId && productId !== 'new' && <EditProduct product={product} />}
                </div>
            </div>

        )
    }else{
        return <LinearProgress />
    }
   
}