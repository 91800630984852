const geodis = {
    gazoil:25,
    name:"geodis",
    color:"yellow",

    palette : {
        "01" : 134.90,
        "02" : 151.25,
        "03" : 144.43,
        "04" : 194.86,
        "05" : 194.86,
        "06" : 181.24,
        "07" : 141.72,
        "08" : 151.25,
        "09" : 144.43,
        "10" : 141.72,
        "11" : 137.62,
        "12" : 144.43,
        "13" : 147.15,
        "14" : 151.25,
        "15" : 144.43,
        "16" : 99.47,
        "17" : 129.45,
        "18" : 140.35,
        "19" : 99.47,
        "20" : null,
        "21" : 141.72,
        "22" : 151.25,
        "23" : 144.43,
        "24" : 85.86,
        "25" : 151.25,
        "26" : 141.72,
        "27" : 151.25,
        "28" : 151.25,
        "29" : 160.79,
        "30" : 136.26,
        "31" : 129.45,
        "32" : 129.45,
        "33" : 99.47,
        "34" : 141.72,
        "35" : 147.15,
        "36" : 140.35,
        "37" : 140.35,
        "38" : 145.79,
        "39" : 151.25,
        "40" : 125.37,
        "41" : 140.35,
        "42" : 130.80,
        "43" : 140.35,
        "44" : 133.54,
        "45" : 140.35,
        "46" : 125.37,
        "47" : 107.64,
        "48" : 155.35,
        "49" : 133.54,
        "50" : 151.25,
        "51" : 151.25,
        "52" : 151.25,
        "53" : 147.15,
        "54" : 151.25,
        "55" : 151.25,
        "56" : 147.15,
        "57" : 151.25,
        "58" : 147.15,
        "59" : 147.15,
        "60" : 141.72,
        "61" : 147.15,
        "62" : 147.15,
        "63" : 125.37,
        "64" : 129.45,
        "65" : 129.45,
        "66" : 143.08,
        "67" : 151.25,
        "68" : 151.25,
        "69" : 133.54,
        "70" : 151.25,
        "71" : 137.62,
        "72" : 147.15,
        "73" : 151.25,
        "74" : 151.25,
        "75" : 111.74,
        "76" : 151.25,
        "77" : 111.74,
        "78" : 111.74,
        "79" : 117.19,
        "80" : 151.25,
        "81" : 129.45,
        "82" : 129.45,
        "83" : 181.24,
        "84" : 137.62,
        "85" : 129.45,
        "86" : 129.45,
        "87" : 110.38,
        "88" : 151.25,
        "89" : 147.15,
        "90" : 151.25,
        "91" : 111.74,
        "92" : 111.74,
        "93" : 111.74,
        "94" : 111.74,
        "95" : 111.74,
        "96" : null,
        "97" : null,
        "98" : null,
        "99" : null
    }
}


export default geodis;