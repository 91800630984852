import React, { useState } from "react";

import { Link, useOutletContext, useSearchParams } from "react-router-dom";

import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import sireneService from "../../services/sirene.service";
import _ from "lodash";
import { MyTextInput } from "../../components/forms";
import clientService from "../../services/client.service";
import { useSnackbar } from "notistack";
import { AutocompleteRechercheSiege } from "../../components/forms/Autocomplete";
import { FacturationForm } from "../../components/forms/FacturationForm";
import { LivraisonForm } from "../../components/forms/LivraisonForm";
import { ContactForm } from "../../components/forms/ContactForm";


const AutoSubmitToken = (localClient) => {
  // Grab values and submitForm from context
  const { setValues } = useFormikContext();
  React.useEffect(() => {

    console.log(localClient)
    setValues(localClient.localClient)
  }, [localClient, setValues]);
  return null;
};

export default function Client(props) {

  const { brasserie, client, contact } = useOutletContext()
  const { enqueueSnackbar } = useSnackbar();



  let [searchParams, setSearchParams] = useSearchParams();

  const [localClient, setLocalClient] = useState(client);

  const [editingContact, setEditingContact] = useState(searchParams.get("contact") === 'true' ? searchParams.get("contact") : false)
  const [contactIndex, setContactIndex] = useState(_.indexOf(client.contacts, contact))
  const [editingFacturation, setEditingFacturation] = useState(searchParams.get("facturation") === 'true' ? searchParams.get("facturation") : false)
  const [editingLivraison, setEditingLivraison] = useState(searchParams.get("livraison") === 'true' ? searchParams.get("livraison") : false)

  const handleEditContact = (value) => {
    if(value){
      setEditingContact(true)
    }else{
      window.location.reload();
    }
  }

  const handleEditFacturation = (value) => {
    setEditingFacturation(!editingFacturation)
  }

  const handleEditLivraison = (value) => {
    setEditingLivraison(!editingLivraison)
  }

  const handleOnChangeRechercheSiege = (siege) => {
    console.log(siege)
    if (siege.length > 0) {
      const siegeData = siege[0].fields
      const siren = siegeData.siren

      const newLocalClient = _.cloneDeep(localClient)

      newLocalClient.facturation.siret = siegeData.siret
      newLocalClient.facturation.nom = (siegeData.denominationunitelegale) ? siegeData.denominationunitelegale : siegeData.denominationusuelleetablissement
      newLocalClient.facturation.adresse = siegeData.adresseetablissement
      newLocalClient.facturation.codePostal = siegeData.codepostaletablissement
      newLocalClient.facturation.ville = siegeData.libellecommuneetablissement

      setLocalClient(newLocalClient)



      const data2 = sireneService.getEtablissementsBySiren(siren).then(data =>
        console.log(data)


      ).catch(error => {

      }
      )
    }

  }

  if (localClient) {
    return (
      <div className="row">
        <div className="col-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2 className="h3">Contacts</h2>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                <button className="btn btn-sm btn-outline-primary" onClick={handleEditContact}> <i className="fa-solid fa-edit"></i></button>
              </div>
            </div>
          </div>
          {!editingContact &&
            <div className="card" >
              <div className="card-body">
                <p className="card-text">Nom : {localClient.contacts[contactIndex].nom} - {localClient.contacts[contactIndex].prenom}</p>
                <p className="card-text">Téléphone : {localClient.contacts[contactIndex].telephone}</p>
                <p className="card-text">Mail : {localClient.contacts[contactIndex].mail}</p>
                <p className="card-text">Horaires de contact : {localClient.contacts[contactIndex].horaires}</p>
              </div>
            </div>
          }
          {editingContact &&
            <div className="card" >
              <div className="card-body">
                <ContactForm source={contact.prenom + " " + contact.nom.charAt(0)} brasserie={brasserie} client={client} contact={localClient.contacts[contactIndex]} setClient={setLocalClient} closeModal={handleEditContact}/>
              </div>
            </div>
          }
        </div>
        <div className="col-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2 className="h3">Facturation</h2>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                <button className="btn btn-sm btn-outline-primary" onClick={handleEditFacturation}> <i className="fa-solid fa-edit"></i></button>
              </div>
            </div>
          </div>

          {!editingFacturation &&
            <div className="card" >
              <div className="card-body">
                <p className="card-text">Entreprise : {localClient.facturation.nom}</p>
                <p className="card-text">Adresse : {localClient.facturation.adresse}</p>
                <p className="card-text">Ville : {localClient.facturation.codePostal} {localClient.facturation.ville}</p>
                <p className="card-text">Siret : {localClient.facturation.siret}</p>
                <p className="card-text">TVA : {localClient.facturation.tva}</p>
              </div>
            </div>
          }
          {
            editingFacturation && 
            <div className="card" >
              <div className="card-body">
                <FacturationForm source={contact.prenom + " " + contact.nom.charAt(0)} brasserie={brasserie} client={client} setClient={setLocalClient} closeModal={handleEditFacturation}/>
              </div>
            </div>
          }
        </div>

        <div className="col-4">
          <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h2 className="h3">Livraison</h2>
            <div className="btn-toolbar mb-2 mb-md-0">
              <div className="btn-group me-2">
                <button className="btn btn-sm btn-outline-primary" onClick={handleEditLivraison}> <i className="fa-solid fa-edit"></i></button>
              </div>
            </div>
          </div>

          {!editingLivraison &&
            <div className="card" >
              <div className="card-body">
                <p className="card-text">Nom Commercial : {localClient.livraison.nom}</p>
                <p className="card-text">Adresse : {localClient.livraison.adresse}</p>
                <p className="card-text">Ville : {localClient.livraison.codePostal} {localClient.livraison.ville}</p>
                <p className="card-text">Siret : {localClient.livraison.siret}</p>
                <p className="card-text">Horaires / infos : {localClient.livraison.horaires}</p>
                <p className="card-text">Accessibilité livreur : {localClient.livraison.accessibilite?<img src={clientService.LIVRAISONS[localClient.livraison.accessibilite].url} alt={clientService.LIVRAISONS[localClient.livraison.accessibilite].name}/> : "Pas encore défini"}</p>
              </div>
            </div>
          }
          {
            editingLivraison && <div className="card" >
              <div className="card-body">
              <LivraisonForm source={contact.prenom + " " + contact.nom.charAt(0)} brasserie={brasserie} client={client} setClient={setLocalClient} closeModal={handleEditLivraison}/>
             
              </div>
            </div>
          }
        </div >
      </div >
    )
  }
}