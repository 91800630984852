
import React, { useCallback, useMemo, useState } from "react";
import { Calendar, Views, dateFnsLocalizer } from 'react-big-calendar'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import { fr } from 'date-fns/locale'
import { formatDistance, toDate } from 'date-fns'
import '../../../node_modules/react-big-calendar/lib/css/react-big-calendar.css';
import eventService from "../../services/event.service";
import statistiqueServices from "../../services/statistique.service";
import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import Offcanvas from 'react-bootstrap/Offcanvas'
import productService from "../../services/product.service";
import { StockRepartition } from "../../components/charts/StockRepartition";
import { VenteRepartition } from "../../components/charts/VenteRepartition";
import commandeService from "../../services/commande.service";
import clientService from "../../services/client.service";
import Feed from "./Dashboard/Feed";

require('globalize/lib/cultures/globalize.culture.fr')



const locales = {
    'fr': fr,
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})
export const loader = async ({ params }) => {
    try {

        const respStatsStock = await statistiqueServices.getStockSnaphot(params.brasserieId, new Date())
        const statStock = respStatsStock.data

        const respOrders = await commandeService.getByBrasserieId(params.brasserieId)
        const orders = respOrders.data

        const respEvents = await eventService.getAll(params.brasserieId)
        const events = respEvents.data

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        const products = respProducts.data;


        const hydratedEvents = events.map((event) => {
            event.start = new Date(event.start)
            if (event.end === null) {
                event.end = event.start
                event.allDay = true
            }
            return event
        })
        return { events: events, statStock: statStock, products: products, orders: orders}

    } catch (e) {
        console.log(e)
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}
export default function Admin(props) {

    const { brasserie } = useOutletContext();
    console.log(brasserie)

    const { events, statStock, products, orders } = useLoaderData();


    const { components, defaultDate, views, messages } = useMemo(
        () => ({
            defaultDate: new Date(),
            views: Object.keys(Views).map((k) => Views[k]),
            messages: {
                week: 'La semaine',
                work_week: 'Semaine de travail',
                day: 'Jour',
                month: 'Mois',
                previous: 'Antérieur',
                next: 'Prochain',
                today: `Aujourd'hui`,
                agenda: 'Ordre du jour',

                showMore: (total) => `+${total} plus`,
            },
        }),
        []
    )
    const [show, setShow] = useState(false);
    const [currentEvent, setCurrentEvent] = useState();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    return (
        <div>
            <div className="row">
                <div className="col-sm-6">
                    <StockRepartition data={statStock} brasserie={brasserie} produits={products} />
                    <VenteRepartition data={orders} brasserie={brasserie} produits={products} />
                </div>
                <div className="col-sm-6">
                <Feed />
                </div>
                <div className="col-sm-12" style={{ height: "800px" }}>
                    <Calendar
                        events={events}
                        culture={'fr'}
                        localizer={localizer}
                        showMultiDayTimes
                        defaultDate={defaultDate}
                        step={60}
                        views={views}
                        messages={messages}
                        onSelectEvent={(event) => {
                            console.log(event);
                            setCurrentEvent(event)
                            handleShow()
                        }}
                        eventPropGetter={(event) => {
                            let backgroundColor
                            switch (event.type) {
                                case eventService.TYPES[eventService.TYPE_CONTACT]:
                                    backgroundColor = "green"
                                    break;
                                default:
                                    backgroundColor = "grey"
                                    break;
                            }
                            return { style: { backgroundColor } }
                        }}
                    />
                </div>
            </div>
            <Offcanvas show={show} onHide={handleClose} placement="end">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{currentEvent?.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {currentEvent?.desc}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    )
}