
import { useState } from "react";

import { Formik, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import clientService from "../../services/client.service";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { MyTextInput } from ".";

const ContactForm = ({ source, brasserie, client, setClient, contact, closeModal, ...props }) => {



  const { enqueueSnackbar } = useSnackbar();

  return (
    <Formik
      initialValues={contact || _.assign(clientService.DEFAULT_CONTACT, { uuid: clientService.freshUUID() })}
      enableReinitialize={true}
      validationSchemaOld={Yup.object({
        mail: Yup.string()
          .email('Adresse email incorrecte :(')
          .required('L\'adresse email est obligatoire pour créer un contact')
      })}

      onSubmit={(values, { setSubmitting }) => {
        if (!!contact) {
          const indexContact = _.findIndex(client.contacts, ['mail', contact.mail])

          clientService.editContact(brasserie, client, values).then(resp => {
            if (resp.status === 200) {
              enqueueSnackbar('Le contact a été mis à jour', { variant: 'success' })
              const event = {
                timestamp: Date.now(),
                nom: 'modification',
                source: (source === "admin") ? "admin" : values.prenom + " " + values.nom.charAt(0),
                context: 'contact'
              };
              clientService.addEvent(brasserie, client, event)
              const newClient = _.cloneDeep(client)
              newClient.contacts[indexContact] = values
              if (newClient.events) {
                newClient.events.unshift(event)
              } else {
                newClient.events = [event]
              }
              setClient(newClient)
              closeModal(false)
            } else {
              enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
          }).catch(error => {
            console.log(error)
            enqueueSnackbar(error.response.data.error.data.error, { variant: 'error' })
          });

        } else {
          clientService.addContact(brasserie, client, values).then(resp => {
            if (resp.status === 200) {
              enqueueSnackbar('Le contact a été créé', { variant: 'success' })
              const event = {
                timestamp: Date.now(),
                nom: 'creation',
                source: (source === "admin") ? "admin" : values.prenom + " " + values.nom.charAt(0),
                context: 'contact'
              };
              clientService.addEvent(brasserie, client, event)
              const newClient = _.cloneDeep(client)
              newClient.contacts.push(values)
              newClient.mails.push(values.mail)
              if (newClient.events) {
                newClient.events.unshift(event)
              } else {
                newClient.events = [event]
              }
              setClient(newClient)
              closeModal(false)
            } else {
              enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
          }).catch(error => {
            console.log(error)
            enqueueSnackbar(error.response.data.error.data.error, { variant: 'error' })
          });
        }


      }}
    >
      <Form>
        <MyTextInput
          label="Adresse email"
          name={"mail"}
          type="text"
        />
        <MyTextInput
          label="Téléphone"
          name={"telephone"}
          type="text"
        />
        <MyTextInput
          label="Nom"
          name={"nom"}
          type="text"
        />
        <MyTextInput
          label="Prénom"
          name={"prenom"}
          type="text"
        />
        <MyTextInput
          label="Horaires de contact"
          name={"horaires"}
          type="text"
        />
        <div class="d-grid gap-2">
          <button className="btn btn-primary mt-3" type="submit">Valider</button>
        </div>
      </Form>
    </Formik >
  );
}

export { ContactForm }

