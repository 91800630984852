
import React, { Component, useState } from "react";
import { Link, useLoaderData, useOutletContext, useParams } from "react-router-dom";

import _ from "lodash"

import EditProduct from "./Produit/edit";
import ListProducts from "../../features/admin/produits/list";


import productService from "../../services/product.service";
import { Button } from "@mui/material";


export const loader = async ({ params }) => {
    try {
        let products;

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        products = respProducts.data;

        return { products: products }

    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function Inventaires(props) {

    const { brasserie } = useOutletContext();
    const { products } = useLoaderData();



        return (
            <div className="row">
                <div className="col-sm-12">
                    Liste des inventaires  <Button variant="contained" size="small">
                                                <Link to={"/admin/"+brasserie.id+"/inventaires/creer"} className="nav-link">
                                                    Créer
                                                </Link>
                                            </Button>
                </div>
            </div>

        )
   
}