import React, { createContext, useContext, useEffect, useReducer } from "react";

import _ from 'lodash';
import commandeService from "../services/commande.service";

export const CommandeContext = createContext(null);
export const CommandeDispatchContext = createContext(null);

const initialStateDefault = {
    commande: null,
    client: null,
    filters: { conditionnements: {}, catalogues: {} },
    brasserie: null,
    catalogue: null,
    loading: false,
    error: null,
}


export default function CommandeProvider({ children, initialState }) {
    const [commande, dispatch] = useReducer(commandeReducer, initialState);



    return (
        <CommandeContext.Provider value={commande}>
            <CommandeDispatchContext.Provider value={dispatch}>
                {children}
            </CommandeDispatchContext.Provider>
        </CommandeContext.Provider>
    )


}

export function useCommande() {
    const context = useContext(CommandeContext)
    if (context === undefined) {
        throw new Error("useCommande must be used within a CommandeProvider")
    }
    return context
}

export function useCommandeDispatch() {
    const context = React.useContext(CommandeDispatchContext)
    if (context === undefined) {
        throw new Error("useCommandeDispatch must be used within a CommandeProvider")
    }
    return context
}


function commandeReducer(state, action) {
    console.log(action.type)
    console.log("action",action)
    console.log("state",state)
    switch (action.type) {
        case 'fetchCommande':

            return { ...state, loading: true, error: null }
        case 'fetchCommandeSuccess':
            state.catalogue.updateCatalogueStocksWithCommande(action.payload)
            console.log('fetchCommandeSuccess', action.payload, state.catalogue)
            return { ...state, commande: action.payload, catalogue: state.catalogue, loading: false, error: null }
        case 'fetchCommandeError':
            return { ...state, loading: false, error: action.payload }
        case 'changeQuantites':
            if (state.commande.statut < commandeService.STATUT_VALIDEE) {
                state.commande.setQuantities(action.payload);
                return { ...state, commande: state.commande }
            } else {
                return state;
            }
        case 'initFilters':
            console.log("initFilters", action.payload)
            return { ...state, filters: action.payload }
        case 'changeCataloguesVisibility':

            state.filters.catalogues = action.payload;
            return { ...state, filters: state.filters }
        case 'changeConditionnementsVisibility':

            state.filters.conditionnements = action.payload;
            return { ...state, filters: state.filters }
        case 'changeTransport':
            if (state.commande.statut < commandeService.STATUT_VALIDEE) {
                let newCommande = _.cloneDeep(state.commande);
                console.log(action.payload, newCommande)
                switch (action.payload) {
                    case 'retrait':
                        newCommande.port.retrait = true
                        newCommande.port.tournee = false
                        newCommande.port.offert = false
                        newCommande.port.expedition = false
                        break;
                    case 'tournee':
                        newCommande.port.retrait = false
                        newCommande.port.tournee = true
                        newCommande.port.offert = false
                        newCommande.port.expedition = false
                        break;
                    case 'offert':
                        newCommande.port.retrait = false
                        newCommande.port.tournee = false
                        newCommande.port.offert = true
                        newCommande.port.expedition = false
                        break;
                    default:
                        if (state.client.livraison.codePostal === "") {
                            newCommande.port.retrait = true
                            newCommande.port.tournee = false
                            newCommande.port.offert = false
                            newCommande.port.expedition = false
                        } else {
                            newCommande.port.retrait = false
                            newCommande.port.tournee = false
                            newCommande.port.offert = false
                            newCommande.port.expedition = true
                        }
                        break;
                }
                return { ...state, commande: newCommande }
            } else {
                return state;
            }
        case 'changementStatutLoading':
            
            return { ...state,changementStatutLoading:true }
        case 'changementStatutLoaded':
            
            return { ...state,changementStatutLoading:false, commande: action.payload.commande }
        case 'changementStatutError':

        return { ...state,changementStatutLoading:false }

        default:
            throw new Error("Unhandled action type: " + action.type);

    }

}