import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DollarSign } from "react-feather";
import productService from "../../../services/product.service";
import _ from "lodash";
import Loader from "../../../components/Loader";
import * as echarts from "echarts/core";
import { aggregate } from "@manufac/echarts-simple-transform";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getWeek, format, addDays } from 'date-fns'

import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import { useOutletContext } from "react-router-dom";

// Register the required components
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, PieChart, CanvasRenderer, DatasetComponent,
        TransformComponent, ToolboxComponent, LegendComponent]
);

echarts.registerTransform(aggregate);

export default function StatistiquesVelociteProduit({ produits }) {

    const defaultOption = {
        animationDuration: 10000,
        title: {
            text: 'Evolution du stock par conditionnement sur les 60 premiers jours',
            left:'center'
        },
        xAxis: {
            type: 'category',
            nameLocation: 'middle',
            data://an array from J+0 to J+59 with the format "J+0" to "J+59"
                Array.from({ length: 60 }, (_, i) => {
                    return `J+${i}`
                }
                )
        },
        yAxis: {
            name: 'Quantité',
            type: 'value',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
                formatter: '{value} %'
            },
            axisPointer: {
                label: {
                    formatter: function (params) {
                        return (params.value).toFixed(2) + '%'
                    }
                }
            }

        },
        grid: {
            right: 140
        },
        tooltip: {
            trigger: 'item',
            axisPointer: {
              type: 'cross',
              label: {
                backgroundColor: '#283b56'
              }
            },
            formatter: function (params) {
                return `${params.seriesName} </br> ${params.marker} ${params.name} : ${params.value}%`
            }
          },
        series: []
    };

    const [options, setOptions] = useState(defaultOption)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const { brasserie } = useOutletContext()




    useEffect(() => {
        setLoading(true)
       async function getStatistiques() {
            //pour chaque produit


            const newOptions = _.cloneDeep(options)
            for (let i = 0; i < produits.length; i++) {
                const produit = produits[i];

                const response = await productService.getStatistiques(brasserie.id, produit.id)
                console.log(response)
                    const data = response.data

                    Object.keys(produit.lots).forEach((lotId) => {
                        const lot = produit.lots[lotId];
                        Object.keys(lot.stocks).forEach((conditionnementId) => {
                            const conditionnement = lot.stocks[conditionnementId];
                            const conditionnementAndLotData = data.filter((d) => d.lotId === lotId && d.conditionnementId === conditionnementId).sort((a, b) => a.timestamp - b.timestamp)
                            const defaultData = {}
                            const dateConditionnement = new Date(lot.dateConditionnement * 1000)

                            //create the default data with the dateConditionnement as key and the volume as value for the 60 days after
                            for (let i = 0; i < 60; i++) {
                                const newDate = addDays(dateConditionnement, i)
                                const key = format(newDate, 'dd/MM/yyyy')
                                //if newDate is in the future, exit the loop
                                if (newDate > new Date()) {

                                    break
                                } else {
                                    defaultData[key] = conditionnement.stockInitial
                                    defaultData[key] = 100
                                }

                            }
                            const keyToSearchBeforeSlice = format(dateConditionnement, 'dd/MM/yyyy')

                            //if the date of the first element of conditionnementAndLotData is not in the default data daterange (dateConditionnement to 60 days after), don't add the serie

                            if (!defaultData[format(new Date(conditionnementAndLotData[0].timestamp), 'dd/MM/yyyy')]) {
                                return
                            }
                            conditionnementAndLotData.forEach((d) => {
                                const key = format(new Date(d.timestamp), 'dd/MM/yyyy')
                                defaultData[key] = Math.round(d.stock*100/conditionnement.stockInitial*100)/100
                            })

                            //slice the data to remove the dates before the dateConditionnement and limit the data to 60 days
                            const slicedData = _.slice(Object.values(defaultData), _.indexOf(Object.keys(defaultData), keyToSearchBeforeSlice), 60)

                            const serie = {
                                type: 'line',
                                smooth: true,
                                data: slicedData,
                                showSymbol: true,
                                symbol: 'circle',
                                symbolSize: 5,
                                name: `${produit.nom} ${lotId} \n${conditionnementId}`,
                                endLabel: {
                                    show: false,
                                    formatter: function (params) {
                                        return params.seriesName + ': ' + params.value;
                                    }
                                },
                                labelLayout: {
                                    moveOverlap: 'shiftY'
                                },
                                emphasis: {
                                    focus: 'series'
                                }
                            }

                            console.log("serie", serie)

                           
                            newOptions.series.push(serie)
                           
                        })
                    })

                    if(i === produits.length - 1){

           console.log(newOptions)
           setOptions(newOptions)
                        setLoading(false)
                    }
              
            }


        }
        getStatistiques()
    }, [produits])




    return (<>
        {loading && <Loader />}
        {!loading &&
            <>

<Card className="flex-fill w-100">
                    <Card.Header>
                        <Card.Title className="mb-0">Velocité du stock</Card.Title>
                    </Card.Header>
                    <Card.Body>
                            <ReactEChartsCore echarts={echarts} option={options} style={{ minHeight: '100%', width: '100%' }} />
                
                    </Card.Body>
                </Card>
            </>
        }
    </>)
}