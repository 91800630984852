import React, { useEffect } from "react";
import { useState } from 'react';

import _ from 'lodash';
import FiltreModeDeLivraison from "./FiltreModeDeLivraison";
import { useCommande, useCommandeDispatch } from "../../contexts/CommandeProvider";
import commandeService from "../../services/commande.service";


export default function Filters(props) {

    const { filters, brasserie, client, catalogue } = useCommande()
    const dispatch = useCommandeDispatch()

    //component states
    const [stockTypesVisibility, setStockTypesVisibility] = useState(filters.conditionnements || {});
    const [stockCataloguesVisibility, setStockCataloguesVisibility] = useState(filters.catalogues || {});

    //initialise les filtres de visibilité des conditionnements et catalogues
    useEffect(() => {
        // if(commande.statut < commandeService.STATUT_MODIFIEE){
        let stockType = {};
        let cataloguesList = {};
        let i = 0;
        for (const catalogueKey in brasserie.catalogues) {
            if (_.indexOf(client.catalogues, catalogueKey) >= 0) {
                cataloguesList[catalogueKey] = { visible: true, nom: brasserie.catalogues[catalogueKey].nom }
            }
        }
         //trier les catalogues par ordre alphabétique
         const clesCataloguesTries = Object.keys(cataloguesList).sort(function (a, b) {
            if (cataloguesList[a].nom < cataloguesList[b].nom) {
                return -1;
            }
            if (cataloguesList[a].nom > cataloguesList[b].nom) {
                return 1;
            }
            return 0;
        })
        const cataloguesTries = clesCataloguesTries.map(key => cataloguesList[key])
        setStockCataloguesVisibility(_.zipObject(clesCataloguesTries, cataloguesTries))

        for (const catalogueConditionnementId in catalogue.getProducts()) {
            const conditionnement = brasserie.conditionnements[catalogueConditionnementId];

            if (stockType[conditionnement.type]) {
            } else {
                stockType[conditionnement.type] = {
                    visible: true,
                    nom: conditionnement.type
                }
            }
        }
          //trier les conditionnements par ordre alphabétique
          const clesTypesTries = Object.keys(stockType).sort(function (a, b) {
            if (a < b) {
                return -1;
            }
            if (a > b) {
                return 1;
            }
            return 0;
        })
        const typesTries = clesTypesTries.map(key => stockType[key])
        setStockTypesVisibility(_.zipObject(clesTypesTries, typesTries))
        dispatch({ type: 'initFilters', payload: { conditionnements: stockType, catalogues: cataloguesList } })
        // }
       

      
    }, [])

    const handleChangeTypeVisibility = (e, type) => {
        const newStockTypes = _.cloneDeep(stockTypesVisibility);
        newStockTypes[type].visible = !newStockTypes[type].visible
        setStockTypesVisibility(newStockTypes)
        dispatch({ type: 'changeConditionnementsVisibility', payload: newStockTypes })

    }
    const handleChangeCataloguesVisibility = (e, type) => {
        const newStockCatalogues = _.cloneDeep(stockCataloguesVisibility);
        newStockCatalogues[type].visible = !newStockCatalogues[type].visible
        setStockCataloguesVisibility(newStockCatalogues)
        dispatch({ type: 'changeCataloguesVisibility', payload: newStockCatalogues })

    }





    return (
        <>
            <div className="col align-self-center">
                <div className="btn-group">
                    <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                        Conditionnements
                    </button>
                    <form className="dropdown-menu p-0">
                        <ul className="list-group" id="pills-tab" role="tablist">
                            {Object.keys(stockTypesVisibility).map(typeName => {
                                const tN = stockTypesVisibility[typeName].nom || typeName
                                const Q = stockTypesVisibility[typeName].quantity
                                const V = stockTypesVisibility[typeName].visible
                                return <StockType typeName={tN} quantity={Q} checked={V} arrayKey={tN} key={tN} handleChange={handleChangeTypeVisibility} />
                            }
                            )}
                        </ul>
                    </form>
                </div>
                <div className="btn-group mx-2">
                    <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                        Catalogues
                    </button>
                    <form className="dropdown-menu p-0">
                        <ul className="list-group" id="pills-tab" role="tablist">
                            {Object.keys(stockCataloguesVisibility).sort((a, b) => {
                                return b.nom - a.nom
                            }).map(catalogueId => {
                                const tN = stockCataloguesVisibility[catalogueId].nom
                                const Q = stockCataloguesVisibility[catalogueId].quantity
                                const V = stockCataloguesVisibility[catalogueId].visible
                                return <StockType typeName={tN} quantity={Q} checked={V} arrayKey={catalogueId} key={tN} handleChange={handleChangeCataloguesVisibility} />
                            }
                            )}
                        </ul>
                    </form>
                </div>
                <FiltreModeDeLivraison />
            </div>
        </>
    );

}

const StockType = (props) => {
    return <li className="list-group-item flex-fill">
        <div className="nav-link position-relative">
            <div className="form-check form-switch">
                <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(props.checked ? "checked" : "")} id={"typeCB-" + props.typeName} onChange={(e) => props.handleChange(e, props.arrayKey)} />
                <label className="form-check-label stretched-link text-nowrap" htmlFor={"typeCB-" + props.typeName}>
                    {props.typeName}
                </label>
            </div>
        </div>
    </li>
}
