
import React from "react";
import { useLoaderData, useOutletContext, useParams } from "react-router-dom";
import _ from "lodash";
import productService from "../../services/product.service";

export const loader = async ({ params }) => {
    try {
        let products;

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        products = respProducts.data;

        return { products: products }

    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}


export default function Mercurial(props) {

    console.log('mercurial page', props)

    const { brasserie } = useOutletContext()
    const { products } = useLoaderData();
    const { pricingId } = useParams()

    const catalogues = _.mapValues(brasserie.conditionnements, () => { return _.mapValues(brasserie.catalogues, () => { return [] }) })

    for (let index = 0; index < products.length; index++) {
        const produit = products[index];
        for (const stock in produit.stocks) {
            const catalogueName = produit.stocks[stock].catalogue
            const conditionnementId = stock
            if (Object.hasOwnProperty.call(catalogues, conditionnementId)) {
                if (catalogueName !== "") {
                    if (Object.hasOwnProperty.call(catalogues[conditionnementId], catalogueName)) {
                        catalogues[conditionnementId][catalogueName].push(produit)
                    } else {
                        catalogues[conditionnementId][catalogueName] = [produit]
                    }
                }
            } else {
                catalogues[conditionnementId] = [produit]
            }
        }
    }

    console.log('catalogues', catalogues)

    if (products) {

        console.log(products)

        return <div className="row">
            <div className="col-sm-12">
            <h2 className="border-bottom pb-2 mb-4">Récapitulatifs des tarifs de la grille <u>{brasserie.pricings[pricingId].nom}</u></h2>
                                   
                <table className="table table-striped table-hover">
                    <tbody>
                        {Object.keys(catalogues).map(conditionnementId => {
                            return <>
                                <tr className="table-dark text-center">
                                    <td colSpan={7}>
                                        <div key={conditionnementId} >{brasserie.conditionnements[conditionnementId].nom} </div>

                                    </td>
                                </tr>
                                <tr  className="table-dark">
                                <td>
                                                        Catalogue
                                                    </td>
                                                    <td>
                                                       Bio
                                                    </td>
                                                    <td>
                                                        Nom
                                                    </td>
                                                    <td>
                                                        ABV
                                                    </td>
                                                    <td>
                                                        Style
                                                    </td>
                                                    <td>
                                                        Stock
                                                    </td>
                                                    <td>
                                                        
                                                    Prix
                                                    </td>
                                </tr>

                                {Object.keys(catalogues[conditionnementId]).map(catalogueName => {
                                    if (catalogues[conditionnementId][catalogueName].length > 0) {

                                        const products = _.uniq(catalogues[conditionnementId][catalogueName]).sort((a, b) => {
                                            return a.abv - b.abv
                                        })
                                        return products.map((item, index) => {
                                            if (item.stocks && Object.keys(item.stocks).length > 0) {
                                                //return <div className="col" ><CardV2 product={item}/></div>
                                               
                                                                    const stock = item.stocks[conditionnementId]
                                                                    const conditionnement = brasserie.conditionnements[conditionnementId];
                                                                    let displayStock = (stock.stock)
                                                                    let prixAffiche
                                                                    if (brasserie.pricings[pricingId].coefs[stock.catalogue]) {
                                                                        prixAffiche = Math.round(stock.prix * brasserie.pricings[pricingId].coefs[stock.catalogue][conditionnementId] * 100) / 100
                                                                    } else {
                                                                        prixAffiche = 0
                                                                    }
                                                                
                                                                       
                                                return <tr>
                                                    <td>
                                                        {brasserie.catalogues[catalogueName].nom}
                                                    </td>
                                                    <td>
                                                        {(item.bio) ? <span>Bio</span> : ''}
                                                    </td>
                                                    <td>
                                                        {item.nom}
                                                    </td>
                                                    <td>
                                                        {item.abv}%
                                                    </td>
                                                    <td>
                                                        {item.style}
                                                    </td>
                                                    <td>
                                                        {displayStock}
                                                    </td>
                                                    <td>
                                                        
                                                    {(prixAffiche) ? prixAffiche + '€ HTDI' : 'Nous consulter'}
                                                    </td>
                                                </tr>

                                            }

                                        })
                                    }
                                })}
                            </>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    }
}