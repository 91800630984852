import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from "react-chartjs-2";

import { Card, Dropdown, Table } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { useOutletContext } from "react-router-dom";
import _ from "lodash";
import { format, sub } from "date-fns";
import Loader from "../../../components/Loader";

ChartJS.register(ArcElement, Tooltip, Legend, Colors);


const TopProduit = ({ dataVentes, loadingDataVentes }) => {

  const { brasserie } = useOutletContext();

  const [statistiques, setStatistiques] = useState([])

  useEffect(() => {
    //const date is based on today date day, month, year (without the seconds and milliseconds)
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const daysago30 = sub(date, { days: 30 });

    //filter for the last 60 days
    const dataForDateRange = dataVentes.filter((item) => {
      return item.timestamp >= daysago30;
    });


    const initValues = {
      CA: 0,
      marge: 0,
      volume: 0,
    }

    //init totaux by clients
    const totaux = {

    }


    //reduce the data to get the sum of CA, Marge and Volume for each catalogue
    const data = dataForDateRange.reduce((acc, item) => {
      const { productName, CA, marge, volume } = item;

      if (acc[productName] === undefined) acc[productName] = _.cloneDeep(initValues);

      acc[productName].CA += CA;
      acc[productName].marge += marge;
      acc[productName].volume += volume;

      return acc;
    }, totaux);

    //order by CA
   
    const dataOrdered = Object.keys(data).sort((a, b) => {
      return data[b].CA - data[a].CA;
    }).reduce((acc, key) => {
      acc[key] = data[key];
      return acc;
    }, {});

    console.log("data top client",dataOrdered)
    //get the top 5 clients
    const top3Clients = Object.keys(dataOrdered).slice(0, 3);
    console.log("data top client",top3Clients)
    setStatistiques(top3Clients)
  }, [brasserie.catalogues, dataVentes])


  if (loadingDataVentes && statistiques.length < 1) return <Loader />


 

  return (
    <Card className="flex-fill">
    <Card.Header>

      <Card.Title className="mb-0">Top Produit</Card.Title>
    </Card.Header>
    <Card.Body className=" py-4">
      <div className="d-flex justify-content-center align-text-bottom podium">
          <div className="text-center podium-left" >{statistiques[2]}</div>
          <div className="text-center podium-center">{statistiques[0]}</div>
          <div className="text-center podium-right">{statistiques[1]}</div>
      </div>
    </Card.Body>
  </Card>
  );
};


export default TopProduit;
