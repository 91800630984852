
import React from "react";

import Typography from '@mui/material/Typography';
import productService from "../../services/product.service";
import { format, fromUnixTime } from 'date-fns'
import _ from "lodash";
import { useCommande, useCommandeDispatch } from "../../contexts/CommandeProvider";
import { Commande } from "../../model/Commande.model";


export default function LigneProduitTableQuantite(props) {
    
    const dispatch = useCommandeDispatch()
    const {commande,brasserie,client} = useCommande()

    /**
     * 
     * @todo notistack si stock insuffisant
     */
    const handleQuantityPlus = (e, product, conditionnementId, brasserie) => {
        if (stock.stock - props.quantity - parseInt(brasserie.conditionnements[conditionnementId].multiple) >= 0) {
            const newQ = props.quantity + parseInt(brasserie.conditionnements[conditionnementId].multiple)
            changeQuantity(product, conditionnementId, newQ)
        }
    }

    const handleQuantityMinus = (e, product, conditionnementId, brasserie) => {
        const newQ = (props.quantity > 0) ? props.quantity - parseInt(brasserie.conditionnements[conditionnementId].multiple) : 0
        changeQuantity(product, conditionnementId, newQ)
    }


    const changeQuantity = (product, conditionnementId, quantity) => {
        let newQuantities = _.cloneDeep(commande.quantities);
        const lots = product.lots;
        const lotsParDateDeConditionnement = Object.values(lots).sort(
            function (a, b) {
                return a.dateConditionnement - b.dateConditionnement
            }
        )

        let quantiteRestante = quantity
        if (!newQuantities[product.id]) {
            newQuantities[product.id] = {}
            newQuantities[product.id][conditionnementId] = {
                quantite: 0,
                lots: []
            }
        } else {
            if (!newQuantities[product.id][conditionnementId]) {
                newQuantities[product.id][conditionnementId] = {
                    quantite: 0,
                    lots: []
                }
            }
        }
        newQuantities[product.id][conditionnementId].quantite = 0
        newQuantities[product.id][conditionnementId].lots = []
        for (let index = 0; index < lotsParDateDeConditionnement.length; index++) {
            const lot = lotsParDateDeConditionnement[index];
            if (_.has(lot, ['stocks', conditionnementId])) {
                if (lot.stocks[conditionnementId].stock >= quantiteRestante) {
                    newQuantities[product.id][conditionnementId].quantite += quantiteRestante
                    newQuantities[product.id][conditionnementId].lots.push({ numero: lot.numero, quantite: quantiteRestante })
                    newQuantities[product.id][conditionnementId].prix = product.stocks[conditionnementId].prix
                    break;
                } else {
                    newQuantities[product.id][conditionnementId].quantite += lot.stocks[conditionnementId].stock
                    quantiteRestante -= lot.stocks[conditionnementId].stock;
                    newQuantities[product.id][conditionnementId].lots.push({ numero: lot.numero, quantite: lot.stocks[conditionnementId].stock })
                    newQuantities[product.id][conditionnementId].prix = product.stocks[conditionnementId].prix
                }
            }

        }
        dispatch({ type: 'changeQuantites', payload: newQuantities })
    }


    let stock = props.stock
    let article = props.article
    let stockPercent = (stock.stock / stock.stockInitial);
    let displayStock = (stock.stock - props.quantity)
    const lotDDM = productService.getCurrentLotForProduct(article, props.conditionnementId)
    return <tr>
        <td className="d-none d-sm-table-cell">
            <img className="" width="25" height="25" src={article.image} alt={article.name} />
        </td>
        <td>{article.nom} {article.bio && <Typography color="success.light" component="span">AB</Typography>}</td>
        <td className="d-none d-lg-table-cell">{article.style}</td>
        <td className="d-none d-md-table-cell">{article.abv}%</td>
        <td>{productService.formatPrice(stock.prix, brasserie.pricings[client.pricing].coefs[stock.catalogue][props.conditionnementId])}€</td>
        <td>{lotDDM !== undefined && format(fromUnixTime(lotDDM.ddm), 'MM/yyyy')}</td>
        <td className="d-none d-md-table-cell">
            {(stockPercent > 0.6) ? <span className="badge bg-success rounded-pill">{displayStock}</span> :
                (stockPercent <= 0.6 && stockPercent > 0.3) ? <span className="badge bg-warning rounded-pill text-dark">{displayStock}</span> :
                    ((stock.stock < 120 && stock.type === "bouteille") || (stock.stock < 2 && stock.type === "fut")) ? <span className="badge bg-dark rounded-pill blink">{displayStock}</span> :
                        (stockPercent <= 0.3 && stockPercent > 0.05) ? <span className="badge bg-danger rounded-pill">{displayStock}</span> : <span className="badge bg-secondary rounded-pill">{displayStock}</span>}
        </td>
        <td style={{ width: 120 }}>
            <div className="input-group input-group-sm">
            {commande.statut < Commande.STATUT_VALIDEE && <button className="btn btn-outline-secondary" type="button" onClick={(e) => handleQuantityMinus(e, article, props.conditionnementId, brasserie)} >-</button>}
                <input
                    id={'quantity-' + article.id + '-' + props.conditionnementId}
                    name={'quantity-' + article.id + '-' + props.conditionnementId}
                    type="text"
                    className={(props.quantity <= stock.stock) ? "form-control text-end" : "form-control is-invalid text-end"}
                    readOnly
                    value={props.quantity || 0} />
                 {commande.statut < Commande.STATUT_VALIDEE && <button className="btn btn-outline-secondary" type="button" onClick={(e) => handleQuantityPlus(e, article, props.conditionnementId, brasserie)}>+</button>}
            </div>
        </td>
    </tr>
}