import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { DollarSign } from "react-feather";
import productService from "../../../services/product.service";
import _ from "lodash";
import Loader from "../../../components/Loader";
import * as echarts from "echarts/core";
import { aggregate } from "@manufac/echarts-simple-transform";
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { getWeek,format } from 'date-fns'

import {
    LineChart,
    BarChart,
    PieChart,
    // ScatterChart,
    // RadarChart,
    // MapChart,
    // TreeChart,
    // TreemapChart,
    // GraphChart,
    // GaugeChart,
    // FunnelChart,
    // ParallelChart,
    // SankeyChart,
    // BoxplotChart,
    // CandlestickChart,
    // EffectScatterChart,
    // LinesChart,
    // HeatmapChart,
    // PictorialBarChart,
    // ThemeRiverChart,
    // SunburstChart,
    // CustomChart,
} from 'echarts/charts';
// import components, all suffixed with Component
import {
    // GridSimpleComponent,
    GridComponent,
    // PolarComponent,
    // RadarComponent,
    // GeoComponent,
    // SingleAxisComponent,
    // ParallelComponent,
    // CalendarComponent,
    // GraphicComponent,
    ToolboxComponent,
    TooltipComponent,
    // AxisPointerComponent,
    // BrushComponent,
    TitleComponent,
    // TimelineComponent,
    // MarkPointComponent,
    // MarkLineComponent,
    // MarkAreaComponent,
    LegendComponent,
    // LegendScrollComponent,
    // LegendPlainComponent,
    // DataZoomComponent,
    // DataZoomInsideComponent,
    // DataZoomSliderComponent,
    // VisualMapComponent,
    // VisualMapContinuousComponent,
    // VisualMapPiecewiseComponent,
    // AriaComponent,
    TransformComponent,
    DatasetComponent,
} from 'echarts/components';
// Import renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import {
    CanvasRenderer,
    // SVGRenderer,
} from 'echarts/renderers';
import { useOutletContext } from "react-router-dom";

// Register the required components
echarts.use(
    [TitleComponent, TooltipComponent, GridComponent, LineChart, BarChart, PieChart, CanvasRenderer, DatasetComponent,
        TransformComponent, ToolboxComponent, LegendComponent]
);

echarts.registerTransform(aggregate);

export default function StatistiquesStockProduit({ product }) {

    //get product statistiques by props productId and session brasserieId
    //use http.get to get statistiques
    //useEffect to get statistiques
    // endpoint is /brasseries/:brasserieId/produits/:id/statistiques
    // return statistiques

    const [statistiques, setStatistiques] = useState({})
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)
    const { brasserie } = useOutletContext()
    console.log(brasserie)



    useEffect(() => {
        setLoading(true)
        const getStatistiques = async () => {
            productService.getStatistiques(brasserie.id, product.id).then((response) => {

                setStatistiques(response.data.map((stat) => {
                    const statDate = new Date(stat.timestamp)
                    const dateKeys = {
                        dateDay: format(statDate, "dd-MMM-yyyy"),
                        dateWeek: format(statDate, "w-yyyy"),
                        dateMonth: format(statDate, "MMM-yyyy"),
                        conditionnement: brasserie.conditionnements[stat.conditionnementId].nom,
                    }
                    return ({
                        ...stat,
                        ...dateKeys
                    })
                }))
                //log les items dont la date est "18-2-2023"
                console.log(statistiques)
                setLoading(false)

            }).catch((err) => {
                console.log(err)
                setError(err)
                setLoading(false)
            })

        }
        getStatistiques()
    }, [brasserie, product])

    const option = {
        title: 
            [
                {
                    text: 'Volume',
                    left: '74.5%',
                    top:'23.5%',
                    textStyle: {
                        color: '#495057',
                        fontSize: 12
                    },
                    textAlign: 'center'
                },
                {
                    text: 'Valorisation',
                    left: '74.5%',
                    top: '56.5%',
                    textStyle: {
                        color: '#495057',
                        fontSize: 12
                    },
                    textAlign: 'center'
                },{
                  /*   textAlign: 'center',
                    text: "Evolution du Volume et de la Valorisation",
                    left: '25%',
                    top:'5%',
                    textStyle: {
                        color: '#495057'
                    }, */
                  } 

            ],
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                const valorisationLine = params[1].marker + params[1].seriesName + " : " + Math.round(params[1].value[params[1].seriesIndex + 1]*100)/100 + "€"
                const volumeLine = params[0].marker + params[0].seriesName + " : " + Math.round(params[0].value[params[0].seriesIndex + 1]*100)/100 + "l"
                return "<b>"+ params[0].name + "</b><br/> " + volumeLine + "<br/>" + valorisationLine
            }
        },
        legend: {
            left:'0%'
        },
        toolbox: {
            show: true,
            feature: {
                dataView: { show: true, readOnly: false },
                magicType: { show: true, type: ['line', 'bar'] },
                restore: { show: true },
                saveAsImage: { show: true }
            }
        },
        xAxis: [{ type: 'category', gridIndex: 0 }, { type: 'category', gridIndex: 1 },{ type: 'category', gridIndex: 2 }],
        yAxis: [{ type: 'value', gridIndex: 0 }, { type: 'value', gridIndex: 1 },{ type: 'value', gridIndex: 2 }],
        grid: [
            { left: '5%', top: '5%',height:'45sh %', width: '40%',},
            { right: '5%', bottom: '5%', width: '40%', height: '20%' },
            { left: '5%', bottom: '5%',height:'45sh %', width: '40%',},
        ], 
        series: [{
            type: 'line',
            datasetIndex: 1,
            gridIndex: 1,
            xAxisIndex: 0,
            yAxisIndex: 0,

        }, {
            type: 'line',
            datasetIndex: 1,
            xAxisIndex: 0,
            yAxisIndex: 0,

        }, 
        {
            name: 'Volume',
            type: 'pie',
            roseType: 'radius',
            datasetIndex: 2,
            center: ['75%', '25%'],
            radius: ['15%', '25%'],
             emphasis: {
                focus: 'self'
            },
            label:{
                formatter: '{b}: {d}%'
            },
            encode: {
                itemName: 'conditionnement',
                value: 'volume',
                tooltip: 'volume'
            },tooltip: {
                trigger: 'item' ,
                formatter: (params) => {
                    return params.name + " : " + Math.round(params.value[params.seriesIndex - 1]*100)/100 + "l"
                }
            },

        }, 
        {
            name: 'Valorisation',
            type: 'pie',
            datasetIndex: 2,
            roseType: 'radius',
            center: ['75%', '58%'],
            radius: ['15%', '25%'],
            emphasis: {
                focus: 'self'
            },
            label:{
                formatter: '{b}: {d}%'
            },
            encode: {
                itemName: 'conditionnement',
                value: 'valorisation',
                tooltip: 'valorisation',
            }, 
            tooltip: {
                trigger: 'item' ,
                formatter: (params) => {
                    return params.name + " : " + Math.round(params.value[params.seriesIndex - 1]*100)/100 + "€"
                }
            },

        }, {
            type: 'bar',
            datasetIndex: 2,
            xAxisIndex: 1,
            yAxisIndex: 1,
            encode: {
                x: 'conditionnement',
                y: 'stock',
                tooltip: ['conditionnement', 'stock']
            }, 
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    return params.name + " : " + params.value[params.seriesIndex - 1]
                }
            },
        }, {
            type: 'line',
            datasetIndex: 3,
            xAxisIndex: 2,
            yAxisIndex: 2,
            encode: {
                x: 'conditionnement',
                y: 'stock',
                tooltip: ['conditionnement', 'stock']
            }, 
            tooltip: {
                trigger: 'item',
                formatter: (params) => {
                    return params.name + " : " + params.value[params.seriesIndex - 1]
                }
            },
        }],
        dataset: [
            {
                dimension: ['date', 'volume', 'valorisation', 'stock'],
                source: statistiques,
            },
            {
                transform: [
                    {
                    id: 'filter7days',
                    type: 'filter',
                    config: {
                        //filtrer les items dont le jour est le même que le jour courant (stocké dans dayOfWeek)
                        dimension: 'dayOfWeek',
                        value: new Date().getDay()


                    }

                }, 
                {
                    fromDatasetId: 'filter7days',
                    type: 'ecSimpleTransform:aggregate',
                    config: {
                        resultDimensions: [
                            { from: 'dateDay' },
                            { from: 'volume', method: 'sum' },
                            { from: 'valorisation', method: 'sum' },
                            { from: 'stock', method: 'sum' }
                        ],
                        groupBy: 'dateDay'
                    },
                    print: true
                }
            ]
            }, {
                transform: [
                    {
                        type: 'filter',
                        config: {
                            //filtrer les items dont le jour est aujourd'hui
                            dimension: 'dateDay',
                            value: format(new Date(), 'dd-MMM-yyyy')
                        },
                    }, {
                        type: 'ecSimpleTransform:aggregate',
                        config: {
                            resultDimensions: [
                                { from: 'conditionnement' },
                                { from: 'volume', method: 'sum' },
                                { from: 'valorisation', method: 'sum' },
                                { from: 'stock', method: 'sum' }
                            ],
                            groupBy: 'conditionnement'

                        }, print: true
                    }
                ]
            }]

    };



    return (<>
        {loading && <Loader />}
        {!loading &&
            <>
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Evolution du stock</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <Row>
                            <ReactEChartsCore echarts={echarts} option={option} style={{ height: '90vh', width: '100%' }} />
                        </Row>


                    </Card.Body>
                </Card>
            </>
        }
    </>)
}