import React, { useEffect, useState } from "react";

import { useLoaderData, useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";

import _ from 'lodash';

import { formatDistance, toDate } from 'date-fns'
import { fi, fr } from 'date-fns/locale'


import commandeService from "../../services/commande.service";
import OrderStatut from "../../components/OrderStatut";
import { Card, Col, Row, Table } from "react-bootstrap";
import InsightsCommandes from "../../features/insightsCommandes";
import { Commande } from "../../model/Commande.model";
import Loader from "../../components/Loader";

export const loader = async ({ params }) => {

}
const initFilters = {
    0: true,
    1: true
}
const filterOrders = (orders, filters) => {
    return orders.filter(order => {
        return filters[order.statut]
    })
}

function Commandes() {

    const { brasserie } = useOutletContext();
    const [commandes, setCommandes ] = useState([])
    const [isLoading, setIsLoading] = useState(true)

   
    const [filtreStatuts, setFiltreStatuts] = useState(initFilters)

    const [ordersToShow, setOrdersToShow] = useState([])

    //get Commandes for brasserie with commandeService at loading with useEffect
    //create new Commande for each commande from API with Commande.initFromJson
    useEffect(() => {

        

        const getCommandes = async (filtreStatuts) => {
            //const respCommandes = await commandeService.getByBrasserieId(brasserie.id);
            const statusToShow = Object.keys(filtreStatuts).filter(key => filtreStatuts[key] === true).map(key => parseInt(key))
            const respCommandes = await commandeService.getByBrasserieIdAndStatus(brasserie.id,statusToShow);
            const commandesRaw = respCommandes.data;
            const commandes = commandesRaw.map(commande => {
               
                return Commande.initFromJson(commande)
            })
            console.log(commandes)

            commandes.sort(
                function (a, b) {
                    if (a.updated && b.updated) {
                        return b.updated._seconds - a.updated._seconds
                    } else {
                        return -1
                    }

                }
            )
            setCommandes(commandes)
            setOrdersToShow(filterOrders(commandes, filtreStatuts))
            setIsLoading(false)

        }
        console.log("filtreStatuts", filtreStatuts)
        if(filtreStatuts){
        getCommandes(filtreStatuts)
        }
    }, [filtreStatuts])




    const changeFiltreStatuts = (e, index) => {
        const newFiltreStatuts = _.cloneDeep(filtreStatuts)
        if (newFiltreStatuts[index]) {
            newFiltreStatuts[index] = !newFiltreStatuts[index]
        } else {
            newFiltreStatuts[index] = true
        }
        setFiltreStatuts(newFiltreStatuts)
        //setOrdersToShow(filterOrders(commandes, newFiltreStatuts))
    }

    console.log("isLoading", isLoading)

    return <>
   
        <Row>
            <Col md="3" xl="2">
                <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">Filtres</Card.Title>
                    </Card.Header>
                    <Card.Body>
                        <ul className="list-group" id="pills-tab" role="tablist">
                            {Commande.STATUTS.map((statut, i) => {
                                return <li key={i} className="list-group-item flex-fill">
                                    <div className="nav-link position-relative">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(filtreStatuts[i]) ? filtreStatuts[i] : false} id={statut} onChange={e => changeFiltreStatuts(e, i)} />
                                            <label className="form-check-label stretched-link" htmlFor={statut}>
                                                {_.capitalize(statut)}</label>
                                        </div>
                                    </div>
                                </li>
                            })}
                        </ul>
                    </Card.Body>
                </Card>
            </Col>

            <Col md="9" xl="10">
                <InsightsCommandes commandes={ordersToShow} brasserie={brasserie} />
                 <Card>
                    <Card.Header>
                        <Card.Title className="mb-0">
                            <div className="d-flex justify-content-between align-items-center">
                                Commandes
                            </div>
                        </Card.Title>
                    </Card.Header>
                    <Card.Body>
                    {isLoading && <Loader />}
                {!isLoading &&<Table size="sm" hover striped>
                            <thead>
                                <tr>
                                    <th scope="col">Client</th>
                                    <th scope="col">Quand</th>
                                    <th scope="col">Statut</th>
                                    <th scope="col" className="d-none d-md-table-cell">Bière HT</th>
                                    <th scope="col" className="d-none d-lg-table-cell">Transport HT</th>
                                    <th scope="col">Total HT</th>
                                    <th scope="col">Volume</th>
                                    <th scope="col">Marge Nette</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (ordersToShow.length > 0 &&
                                        ordersToShow.map((order, index) => {

                                            if (filtreStatuts[order.statut]) {
                                                return <tr key={index}>
                                                    <td>
                                                        <Link to={"/admin/" + brasserie.id + "/clients/" + order.client.id}>
                                                            {order.client.livraison.nom + " (" + order.client.facturation.nom + ")"}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {formatDistance(toDate((order.updated) ? order.updated._seconds * 1000 : order.created), new Date(), { addSuffix: true, locale: fr })}
                                                    </td>
                                                    <td>
                                                        <OrderStatut statut={order.statut} />
                                                    </td>
                                                    <td>
                                                        {order.sousTotal}€
                                                    </td>
                                                    <td>
                                                        {order.port.sousTotal}€
                                                    </td>
                                                    <td>
                                                        {Math.round((order.sousTotal + order.port.sousTotal) * 100) / 100}€
                                                    </td>
                                                    <td>
                                                        {order.statistiques.v?.total}l
                                                    </td>
                                                    <td>
                                                        {Math.round((order.statistiques.m?.total + order.statistiques.m?.port) * 100) / 100}€
                                                    </td>
                                                    <td>
                                                        <Link to={"/admin/" + brasserie.id + "/clients/" + order.client.id + "/commandes/" + order.id} className="btn btn-primary">
                                                            Modifier
                                                        </Link>
                                                    </td>
                                                </tr>
                                            } else {
                                                return null;
                                            }
                                        }))
                                }
                            </tbody>
                        </Table>}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}



export { Commandes }