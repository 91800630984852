
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

import _ from "lodash"
import { useSnackbar } from 'notistack';

import brasserieService from "../../services/brasserie.service";
import LinearProgress from '@mui/material/LinearProgress';


export default function Catalogues(props) {

    const defaultCatalogue = {
        description: '',
        nom: '',
        isVisible:true
    }

    const { brasserie } = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();

    const [catalogue, setCatalogue] = useState(defaultCatalogue)
    const [key, setKey] = useState()

    const setNom = (nom) => {
        const newCatalogue = _.cloneDeep(catalogue);
        newCatalogue.nom = nom;
        setCatalogue(newCatalogue)
    }
    const setDescription= (description) => {
        const newCatalogue = _.cloneDeep(catalogue);
        newCatalogue.description = description;
        setCatalogue(newCatalogue)
    }

    const editCatalogue= (catalogueKey) => {
        setCatalogue(_.cloneDeep(brasserie.catalogues[catalogueKey]))
        setKey(catalogueKey)
    }

    const handleSubmitCatalogue = (e) => {
        e.preventDefault()
        const catalogues = brasserie.catalogues || {};
        if(key!==null && key!== undefined){
            catalogues[key] = catalogue
        }else{
            catalogues[_.kebabCase(catalogue.nom)] = catalogue
        }
        brasserie.catalogues = catalogues

        setCatalogue(defaultCatalogue)
        brasserieService.updateCatalogues(brasserie,catalogues).then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Les catalogues ont été mis à jour.', { variant: 'success' })
                setKey(undefined)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }


    if (true) {

        return (
            <div className="row">
                <div className="col-sm-12">
                <form onSubmit={(e)=>handleSubmitCatalogue(e)}>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th scope="col">Nom</th>
                               <th scope="col" >Description</th>
                               
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (brasserie.catalogues && Object.keys(brasserie.catalogues).map((catalogueKey) => {
                                    return <tr key={catalogueKey}>
                                        <td>
                                            {brasserie.catalogues[catalogueKey].nom}
                                        </td>
                                        <td>
                                            {brasserie.catalogues[catalogueKey].description}
                                        </td>
                                       

                                        <td>
                                            <button type="button" className="btn btn-primary" onClick={(e) => editCatalogue(catalogueKey)} >Editer</button>
                                        </td>
                                    </tr>

                                }))
                            }
                            <tr >
                                <td>
                                    <input type="hidden"  id="key" value={key} />
                                    <input type="text" className="form-control" id="style" placeholder="Nom" required onChange={(e) => setNom(e.target.value)} value={catalogue.nom} />
                                </td>
                                <td>
                                    <input type="text" className="form-control" id="style" placeholder="Nom" required onChange={(e) => setDescription(e.target.value)} value={catalogue.description} />
                                </td>
                               
                                <td>
                                    <button type="submit" className="btn btn-primary" >{(key)?"Editer \""+brasserie.catalogues[key].nom+"\"":"Créer un catalogue"}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                                </form>

                </div>
            </div>

        )
    }else{
        return <LinearProgress />
    }
   
}