import { useState } from "react";

import { Typeahead, AsyncTypeahead, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import sireneService from "../../services/sirene.service";

const AutocompleteRechercheSiege = ({ handleChange, ...props }) => {

    const SEARCH_URI = 'https://public.opendatasoft.com/api/records/1.0/search/?dataset=economicref-france-sirene-v3&';
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);

    const handleSearch = (query) => {
        setIsLoading(true);
        sireneService.getSieges(query).then(resp => {
            if (resp.status === 200) {
                const options = resp.data.records.map(record => {
                   return sireneService.extractDataFromRecord(record)
                })
                setOptions(options);
                setIsLoading(false);
            }
        })
    };


    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    const filterBy = () => true;

    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey={option => {
               


                return option.nom + " " + option.nomCommercial
            }
            }
            minLength={3}
            onSearch={handleSearch}
            onChange={handleChange}
            options={options}
            useCache={false}
            placeholder="Chercher une entreprise"
            renderMenuItemChildren={(option, { text }) => {
                    return <>
                        <strong>{option.nom} {(option.nomCommercial !== "") ? "(" + option.nomCommercial + ")" : ""}</strong>
                        <div>
                            <small>{option.ville} - {option.activite}</small>
                        </div>

                    </>
               
            }

            }
        />
    );
}

export { AutocompleteRechercheSiege }