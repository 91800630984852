const jardel = {
    gazoil:25,
    name:"jardel",
    color:"blue",
    palette : {
        "01" : 114,
        "02" : 123,
        "03" : 91,
        "04" : 112,
        "05" : 112,
        "06" : 114,
        "07" : 107,
        "08" : 132,
        "09" : 82,
        "10" : 137,
        "11" : 82,
        "12" : 82,
        "13" : 107,
        "14" : 111,
        "15" : 85,
        "16" : 80,
        "17" : 82,
        "18" : 81,
        "19" : 97,
        "20" : 206,
        "21" : 95,
        "22" : 110,
        "23" : 90,
        "24" : 40,
        "25" : 130,
        "26" : 106,
        "27" : 109,
        "28" : 91,
        "29" : 118,
        "30" : 90,
        "31" : 74,
        "32" : 82,
        "33" : 71,
        "34" : 87,
        "35" : 103,
        "36" : 89,
        "37" : 89,
        "38" : 117,
        "39" : 100,
        "40" : 80,
        "41" : 89,
        "42" : 99,
        "43" : 97,
        "44" : 90,
        "45" : 89,
        "46" : 82,
        "47" : 82,
        "48" : 88,
        "49" : 95,
        "50" : 116,
        "51" : 134,
        "52" : 126,
        "53" : 100,
        "54" : 128,
        "55" : 138,
        "56" : 112,
        "57" : 131,
        "58" : 89,
        "59" : 128,
        "60" : 121,
        "61" : 105,
        "62" : 126,
        "63" : 91,
        "64" : 82,
        "65" : 83,
        "66" : 85,
        "67" : 134,
        "68" : 134,
        "69" : 100,
        "70" : 131,
        "71" : 93,
        "72" : 95,
        "73" : 114,
        "74" : 114,
        "75" : 100,
        "76" : 114,
        "77" : 96,
        "78" : 100,
        "79" : 81,
        "80" : 126,
        "81" : 82,
        "82" : 82,
        "83" : 109,
        "84" : 90,
        "85" : 83,
        "86" : 80,
        "87" : 92,
        "88" : 130,
        "89" : 89,
        "90" : 138,
        "91" : 97,
        "92" : 100,
        "93" : 101,
        "94" : 100,
        "95" : 102,
        "96" : null,
        "97" : null,
        "98" : null,
        "99" : null
    }
}

export default jardel;