import React, { useState } from "react";

import _ from 'lodash';
import { format, formatDistance, toDate } from 'date-fns'

import { fr } from 'date-fns/locale'

import commandeService from "../../services/commande.service";

import { Link, useLoaderData, useOutletContext } from "react-router-dom";

import LinearProgress from '@mui/material/LinearProgress';
import OrderStatut from "../../components/OrderStatut";
import catalogueService from "../../services/catalogue.service";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons";
import Recapitulatif from "../../components/bonDeCommande/Recapitulatif";
import { Commande } from "../../model/Commande.model";


export const loader = async ({ params }) => {
    try {
        let orders;

        const resp = await commandeService.getByContactUUID(params.clientUUID)
        if (resp.data === null) {
            throw new Error()
        } else {
            orders = _.orderBy(resp.data, (order) => {
                return order.updated._seconds
            }, ["desc"])
        }

        return { orders: orders }

    } catch (error) {
        console.log(error)
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function CommandeList() {
    const { client, catalogue, brasserie, contact } = useOutletContext()
    const { orders } = useLoaderData();


    const [order, setOrder] = useState();


    const handleShowCommande = (order) => {
        setOrder(order)
    }

    return <>
        <div className="row">
            <div className="col-8">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h2 className="h3">Vos commandes</h2>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <Link to={"/client/" + contact.uuid + "/bon-de-commande"} className="btn btn-sm btn-outline-primary">
                                <i className="fa-solid fa-cart-plus"></i>
                            </Link>
                        </div>
                    </div>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col" className="d-none d-sm-table-cell"></th>
                            <th scope="col">Création</th>
                            <th scope="col">Dernière modification</th>
                            <th scope="col" className="d-none d-md-table-cell">Bière HT</th>
                            <th scope="col" className="d-none d-lg-table-cell">Transport HT</th>
                            <th scope="col">Total HT</th>
                            <th scope="col">Statut</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (orders.length > 0 &&
                                orders.map((orderLoop, index) => {

                                    const commandeLoop = Commande.initFromJson(orderLoop)
                                    console.log("commandeLoop", commandeLoop)
                                    let orderCreated = _.find(commandeLoop.events, { 'nom': 'création' }).timestamp
                                    if (orderCreated > 9999999999) {
                                        orderCreated = Math.round(orderCreated / 1000)
                                    }
                                    if (orderCreated > 1674255600) {
                                        return <tr key={index} onClick={(e) => { handleShowCommande(commandeLoop) }} className={(order?.id === commandeLoop.id) ? "table-info" : ""}>
                                            <td>
                                                #{index}
                                            </td>
                                            <td>
                                                {format(orderCreated * 1000, 'dd/MM/yyyy', { locale: fr })}
                                            </td>
                                            <td>
                                                {formatDistance(toDate((commandeLoop.updated) ? commandeLoop.updated._seconds * 1000 : commandeLoop.created._seconds * 1000), new Date(), { addSuffix: true, locale: fr })}
                                            </td>
                                            <td>
                                                {commandeLoop.sousTotal}€
                                            </td>
                                            <td>
                                                {commandeLoop.port.sousTotal}€
                                            </td>
                                            <td>
                                                {commandeLoop.sousTotal + commandeLoop.port.sousTotal}€
                                            </td>
                                            <td>
                                                <OrderStatut statut={commandeLoop.statut} />
                                                {commandeLoop.facture && <a href={commandeLoop.facture} target="_blank" rel="noreferrer" className="ms-1">
                                                    <FontAwesomeIcon icon={faFilePdf} />
                                                </a>}
                                            </td>
                                        </tr>

                                    } else {
                                        return null
                                    }

                                }))
                        }
                    </tbody>
                </table>
            </div>
            <div className="col-4">
                {order &&
                    <div className="col-12">
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h2 className="h3">Aperçu</h2>

                        </div>
                        <div className="d-grid gap-2">

                            <Recapitulatif catalogue={catalogue}
                                commande={order}
                                brasserie={brasserie}
                                client={client} />
                            <button className="btn btn-primary " type="sumbit">
                                <Link to={"/client/" + contact.uuid + "/commandes/" + order.id} className="nav-link" reloadDocument>
                                    {order.statut < 2 && "Modifier"}
                                    {order.statut >= 2 && "Consulter"}

                                </Link>
                            </button>
                            {(order.statut === Commande.STATUT_FACTUREE && order.facture) && <button className="btn btn-primary " type="sumbit">
                                <a href={order.facture} target="_blank" rel="noreferrer" className="ms-1 nav-link">
                                    <FontAwesomeIcon icon={faFilePdf} /> Télécharger la facture
                                </a>
                            </button>}
                        </div>
                    </div>
                }
            </div>
        </div>
    </>


}