import _ from "lodash";
import http from "../http-common";

class CatalogueDataService {


  updateCatalogueStock = (catalogue, order) => {
    const updatedCatalogue = _.cloneDeep(catalogue)
    for (const productId in order.quantities) {
      for (const conditionnementId in order.quantities[productId]) {
        const productIndex = _.findIndex(catalogue, { id: productId })
        for (let index = 0; index < order.quantities[productId][conditionnementId].lots.length; index++) {
          const numeroDeLot = order.quantities[productId][conditionnementId].lots[index].numero
          updatedCatalogue[productIndex].lots[numeroDeLot].stocks[conditionnementId].stock = parseInt(catalogue[productIndex].lots[numeroDeLot].stocks[conditionnementId].stock) + parseInt(order.quantities[productId][conditionnementId].lots[index].quantite)
        }
        updatedCatalogue[productIndex].stocks[conditionnementId].stock = parseInt(catalogue[productIndex].stocks[conditionnementId].stock) + parseInt(order.quantities[productId][conditionnementId].quantite)
        if (parseInt(order.quantities[productId][conditionnementId]) > 0) {
          updatedCatalogue[_.findIndex(catalogue, { id: productId })].stocks[conditionnementId].visibilite = true;
        }
      }
    }
    return updatedCatalogue;
  }
  /**
   * 
   * A partir de la liste des produits en stocks
   * En fonction des catalogues accessibles par le client
   * Retourne la liste des produtis disponibles
   * 
   * @param {*} [produit] 
   * @param {*} client 
   * @returns array of produit
   */
  createCatalogueForClient = (produits, client) => {
    const visibleCatalogue = produits.map(produit => {
      if (produit?.stocks && Object.keys(produit.stocks).length > 0) {
        Object.keys(produit.stocks).map(stock => {
          if (client.catalogues.indexOf(produit.stocks[stock].catalogue) === -1 || !produit.stocks[stock].visibilite) {
            delete produit.stocks[stock]
          }
        })
      }
      return produit
    }).filter(produit => produit.stocks && Object.keys(produit.stocks).length > 0)
    return visibleCatalogue;
  }

  /**
     * prend une liste de produit et filtre par type de contenant, catalogue, degré d'alcool
     * @param {*} brasserie 
     * @param {*} client 
     * @param {*} catalogue 
     * @returns 
     */
   reorganizeCatalogue = (brasserie, catalogue) => {

    const catalogues = _.mapValues(brasserie.conditionnements, () => { return _.mapValues(brasserie.catalogues, () => { return [] }) })

    for (let index = 0; index < catalogue.length; index++) {
        const produit = catalogue[index];
        for (const stock in produit.stocks) {
            const catalogueName = produit.stocks[stock].catalogue
            const conditionnementId = stock
            if (Object.hasOwnProperty.call(catalogues, conditionnementId)) {
                if (catalogueName !== "") {
                    if (Object.hasOwnProperty.call(catalogues[conditionnementId], catalogueName)) {
                        catalogues[conditionnementId][catalogueName].push(produit)
                    } else {
                        catalogues[conditionnementId][catalogueName] = [produit]
                    }
                }
            } else {
                catalogues[conditionnementId] = [produit]
            }
        }
    }

    //if all values lengths are 0 for a conditionnement, remove it
    for (const conditionnement in catalogues) {
        if (Object.hasOwnProperty.call(catalogues, conditionnement)) {
            const element = catalogues[conditionnement];
            let allValuesLengthsAreZero = true
            for (const catalogue in element) {
                if (Object.hasOwnProperty.call(element, catalogue)) {
                    const elementCatalogue = element[catalogue];
                    if (elementCatalogue.length !== 0) {
                        allValuesLengthsAreZero = false
                        const products = _.uniq(elementCatalogue).sort((a, b) => {
                            return a.abv - b.abv
                        })
                        catalogues[conditionnement][catalogue] = products
                    }
                }
            }
            if (allValuesLengthsAreZero) {
                delete catalogues[conditionnement]
            }
        }
    }

    return catalogues;

}


  async get(brasserie) {

    const resp = await http.get('/brasseries/' + brasserie.id + '/produits')

    console.log(resp)
    if (resp.data === null) {
      return []
      //TODO add sneackers error
    } else {
      return resp.data;
    }
  }



}

export default new CatalogueDataService();