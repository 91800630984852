
import React, { useEffect, useState } from "react";

import { useOutletContext, useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import _ from 'lodash';



import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';


import productService from "../../services/product.service";
import { AddCommentaire, AddCommunication } from "../../components/actions/Client";
import clientService from "../../services/client.service";
import transporteurs from "../../data/transporteurs";


import CommandeProvider, { } from "../../contexts/CommandeProvider"
import Loader from "../../components/Loader"
import { Catalogue } from "../../model/Catalogue.model"
import BonDeCommande from "../../components/bonDeCommande"

export const loader = async ({ params }) => {
    try {
      
    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export function CommandeEdit() {

   
    const [client, setClient] = useState(null)
    const [products, setProducts] = useState(null)
    const [isLoading, setIsLoading] = useState(2)

    const { clientId,commandeId} = useParams()

    const { brasserie } = useOutletContext();

    useEffect(() => {
        let loading = isLoading
        const getClient = () => {
            clientService.getById(brasserie.id, clientId)
                .then(res => {
                    const client = res.data

                    client.transporteur = transporteurs.getBestTransporteurForDepartement(Math.trunc(client.livraison.codePostal / 1000))
                    setClient(res.data)
                    loading--
                    setIsLoading(loading)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        const getProducts = () => {
            productService.getByBrasserieId(brasserie.id)
                .then(res => {
                    setProducts(res.data)
                    loading--
                    setIsLoading(loading)
                }).catch(err => {
                    console.log(err)
                })
        }
        if (isLoading > 0) {
            getProducts()
            getClient()
        }
    }, [])


    const catalogue = new Catalogue(brasserie)
    if (products && client && !!!isLoading) {
        catalogue.setRawProducts(products)
        catalogue.setClient(client)
    }


    return <>

        {!!isLoading && <Loader />}

        {!!!isLoading && <div className="row">
            <div className="col-12 mb-3">

                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" to={"/admin/" + brasserie.id}>
                        Admin
                    </Link>
                    <Link
                        underline="hover"
                        color="inherit"
                        to={"/admin/" + brasserie.id + "/clients/" + client.id}
                    >
                        {client.facturation.nom}
                    </Link>
                    <Typography color="text.primary">Nouvelle commande</Typography>
                </Breadcrumbs>
                {
                    client.contacts && client.contacts.map((contact, index) => {
                        return <div key={index} className="alert alert-secondary mt-2 mb-0 d-flex justify-content-between" role="alert">
                            <div>
                                <strong>Contact</strong> : {" " + contact.nom + " " + contact.prenom} | <strong>Téléphone </strong> :  <a href={"tel:" + contact.telephone}>{contact.telephone}</a> | <strong>Mail</strong> : {contact.mail}
                            </div>
                            <div className="btn-group me-2">
                                <AddCommunication brasserie={brasserie} client={client} communication={{ type: "Téléphone", icon: "fa-phone" }} />
                                <AddCommunication brasserie={brasserie} client={client} communication={{ type: "Mail", icon: "fa-envelope-circle-check" }} />
                                <AddCommentaire brasserie={brasserie} client={client} />
                            </div>
                        </div>
                    })
                }

            </div>

            <div className="col-md-12 col-sm-12 col-lg-12">
                <CommandeProvider initialState={{
                    client: client,
                    catalogue: catalogue,
                    commande: null,
                    filters: { conditionnements: {}, catalogues: {} },
                    loading: false,
                    error: null,
                    brasserie: brasserie
                }}>
                    <BonDeCommande id={commandeId} />
                </CommandeProvider>
            </div>
        </div>}
    </>
}
