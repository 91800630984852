import {  useContext, useEffect } from "react"
import { useCommande, useCommandeDispatch } from "../../contexts/CommandeProvider"
import Loader from "../../components/Loader"
import PDFViewer from "../../components/PDFViewer"
import Filters from "../../components/bonDeCommande/Filters"
import Historique from "../../components/bonDeCommande/Historique"
import Recapitulatif from "../../components/bonDeCommande/Recapitulatif"
import TableQuantites from "../../components/bonDeCommande/TableQuantites"
import commandeService from "../../services/commande.service"
import { Commande, Commande as CommandeModel } from "../../model/Commande.model"
import InsightsCommandes from "../../features/insightsCommandes"
import { SessionContext } from "../../contexts/SessionContext"
import ChangerStatut from "./ChangerStatut"


    export default function BonDeCommande(props) {
        const { commande,client, loading, error,brasserie,catalogue } = useCommande()
        const dispatch = useCommandeDispatch()
        const { session } = useContext(SessionContext)
        const isAdmin = session.isAdmin

        useEffect(() => {
            if(client){
                if (props.id) {
                    dispatch({ type: 'fetchCommande' })
                    commandeService.getById(client, props.id)
                        .then(res => {
                            const commande =  CommandeModel.initFromJson(res.data,brasserie,catalogue)

                            dispatch({ type: 'fetchCommandeSuccess', payload: commande })
                        })
                        .catch(err => {
                            dispatch({ type: 'fetchCommandeError', payload: err })
                        })
                }else{
                    const commande = CommandeModel.initNew(brasserie,client,catalogue)
                    dispatch({ type: 'fetchCommandeSuccess', payload: commande })
                }
            }
           

        }, [])

        console.log("error",error)
        return (
            <>
                {loading && <Loader />}
                {error && <div>{error}</div>}
                {commande && <>
                    {isAdmin && <InsightsCommandes commandes={[commande]} brasserie={brasserie}/>}
                    <div className="row" >
                        <div className={commande.statut > Commande.STATUT_OUVERTE ? "col-md-12 col-sm-12 col-lg-7" : "col-md-12 col-sm-12 col-lg-9"}  >
                            {commande.statut < Commande.STATUT_FACTUREE &&
                                <div style={{ position: 'sticky', top: 0 }}>
    
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 bcommande-bottom">
                                        <h1 className="h2">Bon de commande</h1>
                                    </div>
    
                                    <Filters />
                                    
    
                                    <TableQuantites />
                                </div>
                            }
                            {commande.statut >= Commande.STATUT_FACTUREE &&
                                <PDFViewer fileUrl={commande.facture} />
                            }
                        </div>
                        <div className="col-md-12 col-sm-12 col-lg-3">
                            <div className="row" style={{ position: 'sticky', top: '0em', height: 'calc(100vh - 1rem)', overflowY: 'auto' }}>
                                <div className="col-12">
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 bcommande-bottom">
                                            <h1 className="h2">Récapitulatif</h1>
                                        </div>
                                       {  <Recapitulatif brasserie={brasserie} client={client} commande={commande} catalogue={catalogue}/>}
                                       {<ChangerStatut/> }
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        {commande.statut > Commande.STATUT_OUVERTE && <div className="col-md-12 col-sm-12 col-lg-2" style={{ position: 'sticky', top: 0 }}>
                            { <Historique/> }
                        </div>}
                    </div>
                </>}
            </>
    
    
        )
    }