import _ from "lodash";
import React, { useState } from "react";

import { useLoaderData, useOutletContext } from "react-router-dom";
import { Link } from "react-router-dom";
import catalogueService from "../../services/catalogue.service";
import clientService from "../../services/client.service";
import productService from "../../services/product.service";

export const loader = async ({ params }) => {
    try {
        let products;

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        products = respProducts.data;

        let clients;

        const respClients = await clientService.getAll(params.brasserieId);
        clients = respClients.data;

        return { products: products,clients:clients }

    } catch (e) {
        console.log(e)
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export function RemiseNew() {

    const { brasserie } = useOutletContext();

    const { clients,products } = useLoaderData();

    const [remise,setRemise] = useState({scope:{catalogues:[],pricings:[],conditionnements:[],clients:[],produits:{}}})

    const handleChangeScope = (e,scope,conditionnementId) => {
        const newRemise = _.cloneDeep(remise);

        switch (scope) {
            case 'produits':
                if (e.target.checked) {
                    if (newRemise.scope[scope]) {
                        newRemise.scope[scope].push(e.target.value)
                    } else {
                        newRemise.scope[scope] = [e.target.value];
                    }
                } else {
                    newRemise.scope[scope] = _.pull(newRemise.scope[scope], e.target.value)
                }
                break;
           
        
            default:
                if (e.target.checked) {
                    if (newRemise.scope[scope] && _.isArray(newRemise.scope[scope])) {
                        newRemise.scope[scope].push(e.target.value)
                    } else {
                        newRemise.scope[scope] = [e.target.value];
                    }
                } else {
                    newRemise.scope[scope] = _.pull(newRemise.scope[scope], e.target.value)
                }
        }
        

        //sanitize

        switch (scope) {
            case 'produits':
                newRemise.scope['catalogues'] = []
                break;
            case 'catalogues':
                newRemise.scope['produits'] = []
                const productsToAddOrRemove = products.map(produit => {

                })
                break;
        
            default:
                break;
        }


        setRemise(newRemise)
    }

    return <div className="row">
        <div className="col">

            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Créer une remise</h1>
            </div>
        </div>
        <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h4">Définition de l'offre</h1>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h4">Où s'applique la remise</h1>
                </div>
                <div>
                            
                            <ul className="list-group my-2">
                                <li className="list-group-item list-group-item-primary">Catalogue(s)</li>
                                {
                                    Object.keys(brasserie.catalogues).map((catalogueId) => {
                                        return <li className="list-group-item" key={catalogueId}>
                                            <input className="form-check-input me-1" type="checkbox" value={catalogueId} id={catalogueId} checked={(_.indexOf(remise.scope.catalogues, catalogueId) >= 0) ? "checked" : ""} onChange={(e) => handleChangeScope(e,'catalogues')} />
                                            <label className="form-check-label stretched-link" htmlFor={catalogueId}>{brasserie.catalogues[catalogueId].nom}</label>
                                        </li>
                                    })
                                }
                            </ul>
                            <ul className="list-group my-2">
                                <li className="list-group-item list-group-item-primary">Grille(s) tarifaire(s)</li>
                                {
                                    Object.keys(brasserie.pricings).map((itemId) => {
                                        return <li className="list-group-item" key={itemId}>
                                            <input className="form-check-input me-1" type="checkbox" value={itemId} id={itemId} checked={(_.indexOf(remise.scope.pricings, itemId) >= 0) ? "checked" : ""} onChange={(e) => handleChangeScope(e,'pricings')} />
                                            <label className="form-check-label stretched-link" htmlFor={itemId}>{brasserie.pricings[itemId].nom}</label>
                                        </li>
                                    })
                                }
                            </ul>
                            <ul className="list-group my-2">
                                <li className="list-group-item list-group-item-primary">Conditionnements(s)</li>
                                {
                                    Object.keys(brasserie.conditionnements).map((itemId) => {
                                        return <li className="list-group-item" key={itemId}>
                                            <input className="form-check-input me-1" type="checkbox" value={itemId} id={itemId} checked={(_.indexOf(remise.scope.conditionnements, itemId) >= 0) ? "checked" : ""} onChange={(e) => handleChangeScope(e,'conditionnements')} />
                                            <label className="form-check-label stretched-link" htmlFor={itemId}>{brasserie.conditionnements[itemId].nom}</label>
                                        </li>
                                    })
                                }
                            </ul> 
                            <ul className="list-group my-2">
                                <li className="list-group-item list-group-item-primary">Produit(s)</li>
                                {
                                    products.map((product) => {
                                        return <li className="list-group-item" key={product.id}>
                                            <input className="form-check-input me-1" type="checkbox" value={product.id} id={product.id} checked={(_.indexOf(remise.scope.produits, product.id) >= 0) ? "checked" : ""} onChange={(e) => handleChangeScope(e,'produits')} />
                                            <label className="form-check-label stretched-link" htmlFor={product.id}>{product.nom}</label>
                                        </li>
                                    })
                                }
                            </ul> 
                        
                  

                </div>
            </div>
        </div>
    </div>
}
