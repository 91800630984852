import React, { useState, useContext } from "react";

import Form from "react-bootstrap/Form";
import { redirect, useNavigate } from "react-router-dom";
import { SessionContext } from "../contexts/SessionContext";

import Button from "react-bootstrap/Button";


export default function Login() {

    const navigate = useNavigate()
    const { session, updateSession } = useContext(SessionContext)


    const handleSubmitMail = (e, email) => {
        e.preventDefault();
        /*  clientService.getByContactEmail(email).then(resp => {
           console.log(resp)
           if (resp.data !== null) {
             navigate('/demande-acces/'+email,{replace:true})
           } else {
             //redirect to /demande-de-prix
             navigate('/demande-de-tarif/'+email,{replace:true})
             //store prospect status in session
           }
         }); */
    }

    const [email, setEmail] = useState("");

    const [password, setPassword] = useState("");

    function validateForm() {

        return email.length > 0 && password.length > 0;

    }

    function handleSubmit(event) {

        event.preventDefault();
        console.log(process.env.REACT_APP_LOGIN, process.env.REACT_APP_PASSWORD)
        //si l'email et le password correspond à process.env.REACT_APP_LOGIN et process.env.REACT_APP_PASSWORD rediriger vers l'admin
        if (email === process.env.REACT_APP_LOGIN && password === process.env.REACT_APP_PASSWORD) {
            //creer un object en session avec isAdminLoggedIn = true
            //rediriger vers /admin

            session.isAdmin = true
            session.brasserie = null
            session.client = null
            session.contact = null
            session.isAdminLoggedIn = true
            updateSession(session)

            navigate('/admin/LJIxsOUtFOOQN7Voy6me', { replace: true })
        } else {
            alert('Identifiant ou mot de passe incorrect')
        }

    }

    return <>
        <div className="Login">
            <Form onSubmit={handleSubmit}>

                <Form.Group size="lg" controlId="email">

                    <Form.Label>Email</Form.Label>

                    <Form.Control

                        autoFocus

                        type="email"

                        value={email}

                        onChange={(e) => setEmail(e.target.value)}

                    />

                </Form.Group>

                <Form.Group size="lg" controlId="password">

                    <Form.Label>Password</Form.Label>

                    <Form.Control

                        type="password"

                        value={password}

                        onChange={(e) => setPassword(e.target.value)}

                    />
                </Form.Group>

                <Button block size="lg" type="submit" disabled={!validateForm()}>

                    Login

                </Button>

            </Form>

        </div >

    </>





}
