
import { useState } from "react";

import { Formik, Form, Field, useField } from 'formik';
import * as Yup from 'yup';
import clientService from "../../services/client.service";
import _ from "lodash";
import { useSnackbar } from "notistack";
import { MyTextInput } from ".";
import sireneService from "../../services/sirene.service";

const LivraisonForm = ({ source, brasserie, client, setClient, closeModal, ...props }) => {



  const { enqueueSnackbar } = useSnackbar();

  const [localClient, setLocalClient] = useState(_.cloneDeep(client))
  const [etablissements, setEtablissements] = useState([])
  const [etablissementsLookedUp, setEtablissementsLookedUp] = useState(false)


  const getEtablissements = (siren) => {
    sireneService.getEtablissementsBySiren(siren).then(resp => {
      console.log(resp)
      if (resp.status === 200) {
        const etablissements = resp.data.records.map(record => {
          const et = { ...sireneService.extractDataFromRecord(record), siren: siren }
          return et
        })
        setEtablissements(etablissements)

        setEtablissementsLookedUp(true)

      }
    })
  }

  const handleSelectionEtablissement = (e) => {

    let newLocalClient = _.cloneDeep(client)
    newLocalClient.livraison.siret = etablissements[e.target.value].siret
    newLocalClient.livraison.nom = (etablissements[e.target.value].nom) ? etablissements[e.target.value].nom : etablissements[e.target.value].nomCommercial
    newLocalClient.livraison.adresse = etablissements[e.target.value].adresse
    newLocalClient.livraison.codePostal = etablissements[e.target.value].codePostal
    newLocalClient.livraison.ville = etablissements[e.target.value].ville
    newLocalClient.siren = etablissements[e.target.value].siren
    setLocalClient(newLocalClient)
  }

  if (!etablissementsLookedUp) {
    let siren = '';
    if (client?.siren && client.siren !== '') {
      siren = client.siren
      getEtablissements(siren)
    } else if (client.facturation.siret !== '') {
      siren = _.truncate(client.facturation.siret, { length: 9, omission: '' })
      getEtablissements(siren)
    } else if (client.livraison.siret !== '') {
      siren = _.truncate(client.livraison.siret, { length: 9, omission: '' })
      getEtablissements(siren)
    } else {
      setEtablissements([])
      setEtablissementsLookedUp(true)
    }

  }
  console.log("local client", localClient)


  const activeStyle = { "border": '2px solid black' }
  const inactiveStyle = {  opacity: "0.5" }



  return (
    <Formik
      initialValues={localClient || clientService.DEFAULT_CLIENT}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
        clientService.updateClientPartial(brasserie, values, 'livraison').then(resp => {
          if (resp.status === 200) {
            enqueueSnackbar('Données de livraison mises à jour', { variant: 'success' })
            const event = {
              timestamp: Date.now(),
              nom: 'modification',
              source: source,
              context: 'livraison'
            };
            clientService.addEvent(brasserie, values, event)
            const newClient = _.cloneDeep(localClient)
            newClient.livraison = values.livraison
            setClient(newClient)
            closeModal(false)
          } else {
            enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
          }
        }).catch(error => {
          enqueueSnackbar(error.message, { variant: 'error' })
        });
      }}
    >
      {(formik) => {
        console.log(formik)
     return  <Form>
        {(client.siren || client.livraison.siret) &&
          <select className="form-select mb-2" onChange={handleSelectionEtablissement}>
            <option>Selectionner un établissement</option>
            {etablissements.map((etablissement, index) => {
              return <option key={index} value={index}>{etablissement.nom} - {etablissement.adresse} - {etablissement.codePostal} {etablissement.ville}</option>
            })}
          </select>
        }
        <MyTextInput
          label="N° SIRET"
          name="livraison.siret"
          type="number"
        />
        <MyTextInput
          label="Nom d'enseigne"
          name="livraison.nom"
          type="text"
        />
        <MyTextInput
          label="Adresse"
          name="livraison.adresse"
          type="text"
        />
        <MyTextInput
          label="Code Postal"
          name="livraison.codePostal"
          type="text"
        />
        <MyTextInput
          label="Ville"
          name="livraison.ville"
          type="text"
        />
        <MyTextInput
          label="Horaires de livraison / informations importantes"
          name="livraison.horaires"
          type="text"
        />

        <div>
          <p>Plus gros camion utilisable pour une livraison. Une information erronée peut entrainer des surcouts lors d'une expédition par transporteur.</p>
          {

            clientService.LIVRAISONS.map((livraison, index) => {
              return <div key={livraison.key} role="group" aria-labelledby="my-radio-group">
                <label htmlFor={"livraison-" + index} style={formik.values.livraison.accessibilite === "" + index ? activeStyle : inactiveStyle} className="p-1 d-flex">

                  <Field type="radio" name="livraison.accessibilite" value={"" + index} id={"livraison-" + index} className="invisible"/>
                  <img src={livraison.url} alt={livraison.nom} />
                </label>


              </div>
            })
          }
        </div>
        <div class="d-grid gap-2">
          <button className="btn btn-primary mt-3" type="submit">Valider</button>
        </div>
      </Form>
      }}
    </Formik >
  );
}

export { LivraisonForm }

