import http from "../http-common";

class StatistiqueService {

  getStockSnaphot(brasserieId,date) {
    
    date.setHours(0, 0, 0, 0);
    const timestamp = date.getTime()
    return http.get('/brasseries/'+brasserieId+'/statistiques/stock/'+timestamp);
  }
  

}

export default new StatistiqueService();