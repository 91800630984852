import _ from "lodash";
import http from "../http-common";
import clientService from "./client.service";


class CommandeDataService {


  STATUT_EN_CREATION = -1
  STATUT_OUVERTE = 0
  STATUT_MODIFIEE = 1
  STATUT_VALIDEE = 2
  STATUT_EXPEDIEE = 3
  STATUT_FACTUREE = 4
  STATUT_ANNULEE = 5

  STATUTS = ['ouverte', 'modifiée', 'validée', 'expédiée', 'facturée', 'annulée']

  getVolume(commande, brasserie) {
    return _.reduce(commande.quantities, (result, quantite, productId) => {
      return result + _.reduce(quantite, (result, conditionnement, conditionnementId) => {
        if (conditionnement.quantite === 0) {
          return result
        } else {
          return result + brasserie.conditionnements[conditionnementId].volume * conditionnement.quantite
        }

      }, 0)
    }, 0)
  }

  getMargeBrute(commande) {
    return _.reduce(commande.quantities, (result, quantite, productId) => {
      return result + _.reduce(quantite, (result, conditionnement, conditionnementId) => {
        if (conditionnement.quantite === 0) {
          return result
        } else {
          return result + (commande.tarifs[productId][conditionnementId].prix - conditionnement.prix) * conditionnement.quantite

        }
      }, 0)
    }, 0)
  }

  getPort(commande) {
    let port = 0;
    const dataPort = commande.port;
    if (dataPort.retrait) {
      port = -dataPort.remise
    } else if (dataPort.expedition) {
      port = dataPort.cout - dataPort.participation
    } else if (dataPort.tournee) {
      port = dataPort.coutTournee - dataPort.participationTournee
    } else if (dataPort.offert) {
      port = 0
    }
    return port;
  }

  //compute and return all statistics for a given order
  /**
   * 
   * @param {*} commande 
   * @param {*} brasserie 
   * @returns  s: {
            //volume
            v: {
                total: 0,
                conditionnements: {},
                catalogues: {}
            },
            //chiffre d'affaire
            ca: {
                total: 0,
                conditionnements: {},
                catalogues: {},
                port: 0,
            },
            //poids
            poids: {
                total: 0,
                conditionnements: {},
                catalogues: {}
            },
            //marge
            m: {
                total: 0,
                conditionnements: {},
                catalogues: {},
                port: 0,
            },
            //nombre de produits
            nb: {
                total: 0,
                conditionnements: {},
                catalogues: {}
            },
        },
   */

  getStatistiques(commande, brasserie,produits) {
    const s = {
      //volume
      v: {
        total: 0,
        conditionnements: {},
        catalogues: {}
      },
      //chiffre d'affaire
      ca: {
        total: 0,
        conditionnements: {},
        catalogues: {},
        port: 0,
      },
      //poids
      poids: {
        total: 0,
        conditionnements: {},
        catalogues: {}
      },
      //marge
      m: {
        total: 0,
        conditionnements: {},
        catalogues: {},
        port: 0,
      },
      //nombre de produits
      nb: {
        total: 0,
        conditionnements: {},
        catalogues: {}
      },
    }
    _.forEach(commande.quantities, (quantite, productId) => {
      _.forEach(quantite, (conditionnement, conditionnementId) => {
        if (conditionnement.quantite === 0) {
          return
        } else {
          
          const conditionnementData = brasserie.conditionnements[conditionnementId]
          const tarif = commande.tarifs[productId][conditionnementId]
          const catalogue = produits[_.findIndex(produits,{ 'id': productId })].stocks[conditionnementId].catalogue
          const nb = conditionnement.quantite
          const volume = conditionnementData.volume * nb
          const poids = conditionnementData.poids * nb
          const marge = (tarif.prix - conditionnement.prix) * nb
          const ca = tarif.prix * nb
          s.v.total += volume
          s.v.conditionnements[conditionnementId] = (s.v.conditionnements[conditionnementId] || 0) + volume
          s.v.catalogues[catalogue] = (s.v.catalogues[catalogue] || 0) + volume
          s.ca.total += ca
          s.ca.conditionnements[conditionnementId] = (s.ca.conditionnements[conditionnementId] || 0) + ca
          s.ca.catalogues[catalogue] = (s.ca.catalogues[catalogue] || 0) + ca
          s.poids.total += poids
          s.poids.conditionnements[conditionnementId] = (s.poids.conditionnements[conditionnementId] || 0) + poids
          s.poids.catalogues[catalogue] = (s.poids.catalogues[catalogue] || 0) + poids
          s.m.total += marge
          s.m.conditionnements[conditionnementId] = (s.m.conditionnements[conditionnementId] || 0) + marge
          s.m.catalogues[catalogue] = (s.m.catalogues[catalogue] || 0) + marge
          s.nb.total += nb
          s.nb.conditionnements[conditionnementId] = (s.nb.conditionnements[conditionnementId] || 0) + nb
          s.nb.catalogues[catalogue] = (s.nb.catalogues[catalogue] || 0) + nb
        }
      })
    })
    const dataPort = commande.port;
    if (dataPort.retrait) {
        s.ca.port = dataPort.remise
        s.m.port = dataPort.remise
    } else if (dataPort.expedition) {
        s.ca.port = dataPort.participation
        s.m.port =  - dataPort.cout + dataPort.participation
    } else if (dataPort.tournee) {
        s.ca.port = dataPort.participationTournee
        s.m.port =  - dataPort.coutTournee + dataPort.participationTournee
    } else if (dataPort.offert) {
        s.ca.port = 0
        s.m.port = 0
    }
    //round to 3 decimals
    _.forEach(s, (value, key) => {
      _.forEach(value, (value2, key2) => {
        if (typeof value2 === 'number') {
          s[key][key2] = Math.round(value2 * 1000) / 1000
        }
        //if type is object, round to 3 decimals
        if (typeof value2 === 'object') {
          _.forEach(value2, (value3, key3) => {
            s[key][key2][key3] = Math.round(value3 * 1000) / 1000
          })
        }
      })
    })

    return s
  }


  /**
   * 
   * @param {*} client 
   * @returns 
   */

  async getByBrasserieId(brasserieId) {
    const resp = await http.get(`/brasseries/` + brasserieId + '/commandes');
    return resp;
  }
  /**
   * 
   * @param {UUID} brasserieId 
   * @param {Array} status 
   * @returns 
   */
    async getByBrasserieIdAndStatus(brasserieId, status) {
    const resp = await http.get(`/brasseries/` + brasserieId + '/commandes/status', { params: { status: status } });
    return resp;
    }

  async getByContactUUID(uuid) {
    const resp = await http.get(`/clients/` + uuid + '/commandes');
    return resp;
  }

  async getById(client, id) {
    return await http.get(`/clients/` + client.id + '/commandes/' + id);
  }

  create(client, order) {
    return http.post("/clients/" + client.id + '/commandes', { order: order })
  }

  update(client, order) {
    client = clientService.cleanClient(client)
    order.client = client
    return http.put("/clients/" + client.id + '/commandes/' + order.id, { client: client, order: order })
  }
  cancel(client, order) {
    client = clientService.cleanClient(client)
    order.client = client
    return http.put("/clients/" + client.id + '/commandes/' + order.id + '/annuler', { client: client, order: order })
  }
  validate(client, order) {
    client = clientService.cleanClient(client)
    order.client = client
    return http.put("/clients/" + client.id + '/commandes/' + order.id, { client: client, order: order })
  }

  

}

export default new CommandeDataService();