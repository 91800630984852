import { useContext, useState } from "react"
import { SessionContext } from "../../contexts/SessionContext";
import { useCommande, useCommandeDispatch } from "../../contexts/CommandeProvider";


export default function FiltreModeDeLivraison() {
    const {commande,client} = useCommande();
    const dispatch = useCommandeDispatch();

    const {session} = useContext(SessionContext)
    const isAdmin = session.isAdmin

    const initModeLivraison = () => {
        if (commande.port.retrait) {
            return "retrait"
        } else if (commande.port.tournee) {
            return "tournee"
        } else if (commande.port.expedition) {
            return "expedition"
        } else if (commande.port.offert) {
            return "offert"
        } else {
            if (commande.client.livraison.codePostal === "") {
                return "retrait"
            } else {
                return "expedition"
            }

        }
    }
    const [modeLivraison, setModeLivraison] = useState(initModeLivraison());
    const handleChangeModeLivraison = (e, mode) => {
        console.log("modeLivraison", modeLivraison)
        setModeLivraison(mode);
        dispatch({ type: 'changeTransport', payload: mode })
    }

    return <div className="btn-group mx-2">
        <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
            Mode de livraison ({modeLivraison})
        </button>
        <form className="dropdown-menu p-0">
            <ul className="list-group" id="pills-tab" role="tablist">
                {isAdmin && <li className="list-group-item flex-fill">
                    <div className="nav-link position-relative">
                        <div className="form-check form-switch">
                            <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(modeLivraison === "offert" ? "checked" : "")} id="offert" onChange={(e) => handleChangeModeLivraison(e, "offert")} />
                            <label className="form-check-label stretched-link text-nowrap" htmlFor="offert">
                                Livraison offerte (admin)
                            </label>
                        </div>
                    </div>
                </li>}
                
                <li className="list-group-item flex-fill">
                    <div className="nav-link position-relative">
                        <div className="form-check form-switch">
                            <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(modeLivraison === "retrait" ? "checked" : "")} id="retrait" onChange={(e) => handleChangeModeLivraison(e, "retrait")} />
                            <label className="form-check-label stretched-link text-nowrap" htmlFor="retrait">
                                Retrait à la brasserie
                            </label>
                        </div>
                    </div>
                </li>


                {client?.tournee &&
                    <li className="list-group-item flex-fill">
                        <div className="nav-link position-relative">
                            <div className="form-check form-switch">
                                <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(modeLivraison === "tournee" ? "checked" : "")} id="tournee" onChange={(e) => handleChangeModeLivraison(e, "tournee")} />
                                <label className="form-check-label stretched-link" htmlFor="tournee">
                                    Tournée de livraison
                                </label>
                            </div>
                        </div>
                    </li>
                }
                {commande.client.livraison.codePostal !== "" && <li className="list-group-item flex-fill">
                    <div className="nav-link position-relative">
                        <div className="form-check form-switch">
                            <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(modeLivraison === "expedition" ? "checked" : "")} id="expedition" onChange={(e) => handleChangeModeLivraison(e, "expedition")} />
                            <label className="form-check-label stretched-link text-nowrap" htmlFor="expedition">
                                Transporteur
                            </label>
                        </div>
                    </div>
                </li>}
                {commande.client.livraison.codePostal === "" && <li className="list-group-item flex-fill">
                    <div className="nav-link position-relative">
                        <div className="form-check form-switch">
                            <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={false} disabled />
                            <label className="form-check-label stretched-link text-nowrap" htmlFor="expedition">
                                Transporteur (fiche client incomplète)
                            </label>
                        </div>
                    </div>
                </li>
                }
            </ul>
        </form>
    </div>
}
