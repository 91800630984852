import React from "react";
import { useState } from 'react';

import { Link, useLoaderData, useLocation, useOutletContext } from "react-router-dom";

import { formatDistance, toDate } from 'date-fns'
import { fr } from 'date-fns/locale'

import { useSnackbar } from 'notistack';
import _ from 'lodash'


import clientService from "../../services/client.service";
import productService from "../../services/product.service";
import OrderStatut from "../../components/OrderStatut";
import { AddCommentaire, AddCommunication, AddContact, DeleteContact, EditContact, EditFacturation, EditLivraison } from "../../components/actions/Client";
import catalogueService from "../../services/catalogue.service";


import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Dropdown,
    ListGroup,
    Row,
    Badge
} from "react-bootstrap";

import { Calendar, Filter, RefreshCw, ArrowUpRight, Clock, Users, MoreHorizontal, ShoppingCart, DollarSign, Truck } from "react-feather";
import { VenteRepartition } from "../../components/charts/VenteRepartition";
import commandeService from "../../services/commande.service";
import { Catalogue } from "../../model/Catalogue.model";
import Recapitulatif from "../../components/bonDeCommande/Recapitulatif";

const Navigation = ({ hash }) => {
    return (

        <Card>
            <Card.Header>
                <Card.Title className="mb-0">Paramétrage client</Card.Title>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item tag="a" href="#fiche" action active={(hash === "#fiche" || hash === "") ? true : false}>
                    Fiche client
                </ListGroup.Item>
                <ListGroup.Item tag="a" href="#configuration" action active={(hash === "#configuration") ? true : false}>
                    Configurations
                </ListGroup.Item>
                <ListGroup.Item tag="a" href="#activite" action active={(hash === "#activite") ? true : false}>
                    Activité
                </ListGroup.Item>
                <ListGroup.Item tag="a" href="#commandes" action active={(hash === "#commandes") ? true : false}>
                    Commandes
                </ListGroup.Item>
                <ListGroup.Item tag="a" href="#statistiques" action active={(hash === "#statistiques") ? true : false}>
                    Statistiques
                </ListGroup.Item>
            </ListGroup>
        </Card>
    )
};


export const loader = async ({ params }) => {
    try {
        let client;

        const respClient = await clientService.getById(params.brasserieId, params.clientId);
        client = respClient.data;

        client.commandes.sort(
            function (a, b) {

                return b.updated._seconds - a.updated._seconds
            }
        )

        let products;

        const respProducts = await productService.getByBrasserieId(params.brasserieId);
        products = respProducts.data;

        return { dataClient: client, products: products }

    } catch (error) {
        console.log(error)
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function ClientEdit(props) {

    const { hash } = useLocation();
    const { brasserie } = useOutletContext();
    const { dataClient, products } = useLoaderData();

    const { enqueueSnackbar } = useSnackbar();

    const [client, setClient] = useState(dataClient);
    const [order, setOrder] = useState((client?.commandes.length > 0) ? client.commandes[0] : null);

    const catalogue = new Catalogue(brasserie)
    catalogue.setRawProducts(products)
    catalogue.setClient(client)

    const handleShowCommande = (order) => {
        setOrder(order)
    }
    const handleChangeStatut = (e) => {

        const newClient = _.cloneDeep(client);
        const newStatut = e.target.value
        console.log(newStatut, clientService.STATUT_CLIENT)
        if (newStatut == clientService.STATUT_CLIENT) {
            console.log(newClient.catalogues.length)
            console.log(newClient.pricing)
            if (newClient.catalogues.length < 1 && (!newClient.pricing || newClient.pricing == "")) {
                enqueueSnackbar('Ce statut nécessite d\'assigner au moins un catalogue et une grille tarifaire.', { variant: 'error' })
                return;
            }
        }


        newClient.statut = newStatut
        const event = {
            timestamp: Date.now(),
            nom: 'modification',
            source: 'admin',
            context: 'statut'
        };
        if (newClient.events) {
            newClient.events.unshift(event)
        } else {
            newClient.events = [event]
        }
        clientService.updateClientPartial(brasserie, newClient, 'statut').then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Nouveau statut.', { variant: 'success' })

                clientService.addEvent(brasserie, newClient, event)
                setClient(newClient)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour du statut.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
        setClient(newClient)
    }
    //handle change form
    const handleChangeCatalogue = (e) => {
        const newClient = _.cloneDeep(client);
        if (e.target.checked) {
            if (newClient.catalogues && _.isArray(newClient.catalogues)) {
                newClient.catalogues.push(e.target.value)
            } else {
                newClient.catalogues = [e.target.value];
            }
        } else {
            newClient.catalogues = _.pull(newClient.catalogues, e.target.value)
        }
        const event = {
            timestamp: Date.now(),
            nom: 'modification',
            source: 'admin',
            context: 'catalogue'
        };
        if (newClient.events) {
            newClient.events.unshift(event)
        } else {
            newClient.events = [event]
        }

        setClient(newClient)


        clientService.updateClientPartial(brasserie, newClient, 'catalogues').then(resp => {
            if (resp.status === 200) {
                if (e.target.checked) {
                    enqueueSnackbar('Le catalogue est ajouté.', { variant: 'success' })


                    clientService.addEvent(brasserie, newClient, event)
                } else (

                    enqueueSnackbar('Le catalogue est retiré.', { variant: 'success' })
                )
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }
    const handleChangePricing = (e) => {
        const newClient = _.cloneDeep(client);

        newClient.pricing = e.target.value;
        const event = {
            timestamp: Date.now(),
            nom: 'modification',
            source: 'admin',
            context: 'Grille tarifaire'
        };
        if (newClient.events) {
            newClient.events.unshift(event)
        } else {
            newClient.events = [event]
        }

        clientService.updateClientPartial(brasserie, newClient, 'pricing').then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Nouvelle grille tarifaire associée.', { variant: 'success' })

                clientService.addEvent(brasserie, newClient, event)
                setClient(newClient)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }
    const handleChangeTournee = (e) => {
        const newClient = _.cloneDeep(client);

        newClient.tournee = (e.target.value === "null") ? null : e.target.value;
        const event = {
            timestamp: Date.now(),
            nom: 'modification',
            source: 'admin',
            context: 'tournée'
        };
        if (newClient.events) {
            newClient.events.unshift(event)
        } else {
            newClient.events = [event]
        }
        clientService.updateClientPartial(brasserie, newClient, 'tournee').then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Nouvelle tournée associée.', { variant: 'success' })

                clientService.addEvent(brasserie, newClient, event)
                setClient(newClient)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }

    const handleSendMailButton = (e, contact) => {
        e.preventDefault()
        clientService.sendCatalogueTo(contact.mail).then((resp) => {
            if (resp.status === 200) {
                enqueueSnackbar('Accès au catalogue envoyé par mail.', { variant: 'success' })


            } else {
                enqueueSnackbar('Erreur lors de l\'envoi du catalogue', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }


    return (
        <>
            <h1 className="h3 mb-3">{client.livraison?.nom} ({client.facturation?.nom})</h1>
            <Row>
                <Col md="3" xl="2">
                    <Navigation hash={hash} />
                </Col>
                <Col md="9" xl="10">
                    {(hash === "#fiche" || hash === "") &&
                        <>
                            <Row>
                                <Col md={6}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    Contacts
                                                    <div className="btn-group">
                                                        <AddContact brasserie={brasserie} client={client} setClient={setClient} />
                                                    </div>
                                                </div>
                                                </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                {
                                                    client.contacts.map((contact) => {
                                                        return <Col key={contact.mail} md={6}>
                                                            <div className="card mb-2" >
                                                                <div className="card-header d-flex justify-content-between align-items-center">
                                                                    {contact.nom} - {contact.prenom}
                                                                    <div className="btn-group">
                                                                        <EditContact brasserie={brasserie} client={client} setClient={setClient} contact={contact} />
                                                                        <DeleteContact brasserie={brasserie} client={client} setClient={setClient} contact={contact} />
                                                                    </div>
                                                                </div>
                                                                <div className="card-body">
                                                                    <p className="card-text"><i className="fa-solid fa-phone me-1"> : </i> <a href={"tel:" + contact.telephone}>{contact.telephone}</a></p>
                                                                    <p className="card-text"><i className="fa-solid fa-at me-1"> : </i><a href={"mailto:"+contact.mail}>{contact.mail}</a></p>
                                                                    <p className="card-text"><i className="fa-solid fa-clock me-1"> : </i>{contact.horaires}</p>
                                                                    <p className="card-text"><i className="fa-solid fa-key me-1"> : </i><Link to={"/client/" + contact.uuid}>Accès client</Link>{client.pricing === "" && <i className="fa-solid fa-triangle-exclamation ms-1 text-warning" alt="Grille tarifaire introuvable"></i>}</p>
                                                                    <p className="card-text"><i className="fa-solid fa-file-import me-1"> : </i><button className="btn btn-primary btn-sm" onClick={(e) => handleSendMailButton(e, contact)}>Envoyer le mail</button>{client.pricing === "" && <i className="fa-solid fa-triangle-exclamation ms-1 text-warning" alt="Grille tarifaire introuvable"></i>}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    })
                                                }

                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={3}>

                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0"><div className="d-flex justify-content-between align-items-center">
                                                Facturation

                                                <div className="btn-group">
                                                    <EditFacturation brasserie={brasserie} client={client} setClient={setClient} />
                                                </div>
                                            </div></Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">{client.facturation.nom}</li>
                                                <li className="list-group-item">{client.facturation.adresse}</li>
                                                <li className="list-group-item">{client.facturation.codePostal} {client.facturation.ville}</li>
                                                <li className="list-group-item">SIRET : {client.facturation.siret}</li>
                                                <li className="list-group-item">TVA : {client.facturation.tva}</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={3}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0"> <div className=" d-flex justify-content-between align-items-center">
                                                Livraison
                                                <div className="btn-group">
                                                    <EditLivraison brasserie={brasserie} client={client} setClient={setClient} />
                                                </div>
                                            </div></Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">{client.livraison.nom}</li>
                                                <li className="list-group-item">{client.livraison.adresse}</li>
                                                <li className="list-group-item">{client.livraison.codePostal} {client.livraison.ville}</li>
                                                <li className="list-group-item">{client.livraison.horaires}</li>
                                                <li className="list-group-item">{client.livraison.accessibilite}</li>
                                                <li className="list-group-item">SIRET : {client.livraison.siret}</li>
                                                <li className="list-group-item">EA : {client.facturation.ea}</li>
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                    {(hash === "#configuration") &&
                        <>
                            <Row>
                                <Col md={12}>
                                    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-2">
                                        <div className="btn-toolbar">
                                            {
                                                clientService.STATUTS.map((statut, index) => {
                                                    return <span key={index} htmlFor={index}>
                                                        <input type="radio" className="btn-check" name="btnradio" id={index} value={index} autoComplete="off" checked={(client.statut * 1 === index) ? "checked" : ""} onChange={(e) => handleChangeStatut(e)} />
                                                        <label className={"me-2 btn btn-outline-" + statut.color} htmlFor={index}>{statut.nom}
                                                        </label>
                                                    </span>
                                                })
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0"> <div className="d-flex justify-content-between align-items-center">
                                                Catalogues

                                            </div></Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <Row>
                                                <ul className="list-group">
                                                    {
                                                        Object.keys(brasserie.catalogues).map((catalogueId) => {
                                                            return <li className="list-group-item" key={catalogueId}>
                                                                <input className="form-check-input me-1" type="checkbox" value={catalogueId} id={"catalogue" + catalogueId} checked={(_.indexOf(client.catalogues, catalogueId) >= 0) ? "checked" : ""} onChange={(e) => handleChangeCatalogue(e)} />
                                                                <label className="form-check-label stretched-link" htmlFor={"catalogue" + catalogueId}>{brasserie.catalogues[catalogueId].nom}</label>
                                                            </li>
                                                        })
                                                    }
                                                </ul>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    Grille tarifaire
                                                </div>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className="list-group">
                                                {
                                                    Object.keys(brasserie.pricings).map((pricingId) => {
                                                        return <li className="list-group-item" key={pricingId}>
                                                            <input className="form-check-input me-1" type="radio" name="pricing" value={pricingId} id={"pricing" + pricingId} checked={(client.pricing === pricingId) ? "checked" : ""} onChange={(e) => handleChangePricing(e)} />
                                                            <label className="form-check-label stretched-link" htmlFor={"pricing" + pricingId}>{brasserie.pricings[pricingId].nom}</label>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className=" d-flex justify-content-between align-items-center">
                                                    Tournée
                                                </div>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <ul className="list-group">
                                                <li className="list-group-item">
                                                    <input className="form-check-input me-1" type="radio" name="tournee" value="null" id="noTournee" checked={(client.tournee === null) ? "checked" : ""} onChange={(e) => handleChangeTournee(e)} />
                                                    <label className="form-check-label stretched-link" htmlFor="noTournee">Pas de tournée</label>
                                                </li>
                                                {
                                                    Object.keys(brasserie.tournees).map((tourneeId) => {
                                                        return <li className="list-group-item" key={tourneeId}>
                                                            <input className="form-check-input me-1" type="radio" name="tournee" value={tourneeId} id={tourneeId} checked={(client.tournee === tourneeId) ? "checked" : ""} onChange={(e) => handleChangeTournee(e)} />
                                                            <label className="form-check-label stretched-link" htmlFor={tourneeId}>{brasserie.tournees[tourneeId].nom}</label>
                                                        </li>
                                                    })
                                                }
                                            </ul>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                    {(hash === "#activite") &&
                        <>
                            <Row>
                                <Col md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    Historique
                                                    <div className="btn-group me-2">
                                                        <AddCommunication brasserie={brasserie} client={client} communication={{ type: "Mail", icon: "fa-envelope-circle-check" }} setClient={setClient} />
                                                        <AddCommunication brasserie={brasserie} client={client} communication={{ type: "Téléphone", icon: "fa-phone" }} setClient={setClient} />
                                                    </div>
                                                </div>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="list-group w-auto overflow-scroll" style={{ "maxHeight": "800px" }}>
                                                {
                                                    client.events.map((event, index) => {
                                                        return <div key={index} href="#" className="list-group-item list-group-item-action d-flex gap-3 py-3 align-items-center" aria-current="true">


                                                            {event.nom === "création" ? <i className="fa-solid fa-play text-success"></i> : ""}
                                                            {event.nom === "modification" ? <i className="fa-solid fa-pen text-secondary"></i> : ""}
                                                            {event.nom === "commande" ? <i className="fa-solid fa-cart-shopping text-primary"></i> : ""}
                                                            {event.nom === "contacts" ? <i className="fa-solid fa-user text-primary"></i> : ""}
                                                            {event.nom === "communication" ? <i className="fa-solid fa-tower-cell text-warning"></i> : ""}
                                                            <div className="d-flex gap-2 w-100 justify-content-between">
                                                                <div>
                                                                    <h6 className="mb-0">{event.nom}</h6>
                                                                    {(event.context) ? <small className="text-muted">{event.context}</small> : ""}

                                                                </div>
                                                                <small className="opacity-50 text-nowrap">{(event.source) ? <> <i className="fa-solid fa-user pe-1"></i>{event.source}<br /></> : ""}{formatDistance(toDate(event.timestamp), new Date(), { addSuffix: true, locale: fr })} </small>
                                                            </div>
                                                        </div>
                                                    })
                                                }

                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    Commentaires
                                                    <div className="btn-group me-2">
                                                        <AddCommentaire brasserie={brasserie} client={client} setClient={setClient} />
                                                    </div>
                                                </div>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <div className="list-group w-auto overflow-scroll" style={{ "maxHeight": "800px" }}>
                                                {
                                                    client.commentaires && client.commentaires.map((commentaire, index) => {
                                                        return <div key={index} className="list-group-item list-group-item-action d-flex gap-3 py-3 align-items-center" aria-current="true">
                                                            <div className=" gap-2 w-100">
                                                                <div>
                                                                    {commentaire.commentaire}
                                                                </div>
                                                                <small className="opacity-50 text-nowrap">{formatDistance(toDate(commentaire.timestamp), new Date(), { addSuffix: true, locale: fr })} </small>
                                                            </div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                    {(hash === "#commandes") &&
                        <>
                            <Row>
                                <Col md={8}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    Commandes
                                                    <div className="btn-group me-2">
                                                        <div className="btn btn-sm btn-outline-primary">
                                                            <Link to={"/admin/" + brasserie.id + "/clients/"+client.id+"/commandes/creer"}>
                                                                <i className="fa-solid fa-cart-plus"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>

                                            <table className="table table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Quand</th>
                                                        <th scope="col" className="d-none d-md-table-cell">Bière HT</th>
                                                        <th scope="col" className="d-none d-lg-table-cell">Transport HT</th>
                                                        <th scope="col">Total HT</th>
                                                        <th scope="col">Statut</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (client.commandes.length > 0 &&
                                                            client.commandes.map((orderLoop, index) => {
                                                                return <tr key={index} onClick={(e) => { handleShowCommande(orderLoop) }} className={(order?.id === orderLoop.id) ? "table-secondary" : ""}>
                                                                    <td>
                                                                        {formatDistance(toDate((orderLoop.updated) ? orderLoop.updated._seconds * 1000 : orderLoop.created._seconds * 1000), new Date(), { addSuffix: true, locale: fr })}
                                                                    </td>
                                                                    <td>
                                                                        {orderLoop.sousTotal}€
                                                                    </td>
                                                                    <td>
                                                                        {orderLoop.port.sousTotal}€
                                                                    </td>
                                                                    <td>
                                                                        {orderLoop.sousTotal + orderLoop.port.sousTotal}€
                                                                    </td>
                                                                    <td>
                                                                        <OrderStatut statut={orderLoop.statut} />
                                                                    </td>
                                                                </tr>

                                                            }))
                                                    }
                                                </tbody>
                                            </table>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card>
                                        <Card.Header>
                                            <Card.Title className="mb-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    Apperçu
                                                </div>
                                            </Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            {order &&
                                                <>
                                                    <div className="col-12">
                                                        <div className="d-grid gap-2">

                                                        <Recapitulatif brasserie={brasserie} client={client} commande={order} catalogue={catalogue}/>
                                                            <button className="btn btn-primary " type="sumbit">
                                                                <Link to={"/admin/" + brasserie.id + "/clients/"+client.id+"/commandes/" + order.id} className="nav-link">
                                                                    Modifier
                                                                </Link>
                                                            </button>
                                                        </div>
                                                    </div>

                                                </>}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    }
                    {(hash === "#statistiques") &&
                        <>
                            {/* <HeaderStatistiques /> */}
                            <Row>
                                <Col lg="6" xl="5" className="d-flex">
                                    <div className="w-100">
                                        <Row>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body >
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">Commandes</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <ShoppingCart className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">{client.commandes.length}</span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +3.65%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">Volume</h5>
                                                            </Col>
                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <Truck className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">
                                                            {   //the same as the margin but for volumes
                                                                //volumes are located at brasserie.conditionnements[conditionnementId].volume
                                                                Math.round(_.reduce(client.commandes, (result, commande) => {
                                                                    return result + commandeService.getVolume(commande, brasserie)
                                                                }, 0) * 100) / 100
                                                            }l
                                                        </span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-danger me-2">
                                                                -1.25%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">CA €HT</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <DollarSign className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">{
                                                            Math.round(_.reduce(client.commandes, (result, commande) => {
                                                                return result + commande.sousTotal
                                                            }, 0) * 100) / 100
                                                        }€</span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +3.65%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">CA/l €HT</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <DollarSign className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">{
                                                            Math.round(_.reduce(client.commandes, (result, commande) => {
                                                                return result + commande.sousTotal
                                                            }, 0) / _.reduce(client.commandes, (result, commande) => {
                                                                return result + _.reduce(commande.quantities, (result, quantite, productId) => {
                                                                    return result + _.reduce(quantite, (result, conditionnement, conditionnementId) => {
                                                                        console.log("conditionnement.quantite", conditionnement.quantite)
                                                                        if (conditionnement.quantite === 0) {
                                                                            return result
                                                                        } else {
                                                                            return result + brasserie.conditionnements[conditionnementId].volume * conditionnement.quantite
                                                                        }

                                                                    }, 0)
                                                                }, 0)
                                                            }, 0) * 100) / 100
                                                        }€/l</span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +3.65%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">Marge brute €HT</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <Filter className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">
                                                            {//get the margin for each order
                                                                //the margin is the difference between the price of the product located at commande.quantities[productId][conditionnementId].prix and the price of the product located at catalogue[productId][conditionnementId].prix
                                                                //the margin is the sum of all the margins for each product of the order by quantites (quantities[productId][conditionnementId].quantite)

                                                                Math.round(_.reduce(client.commandes, (result, commande) => {
                                                                    return result + commandeService.getMargeBrute(commande)
                                                                }, 0) * 100) / 100
                                                            }€
                                                        </span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +2.25%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">Marge brute €HT/l</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <Filter className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">
                                                            {//get the margin for each order
                                                                //the margin is the difference between the price of the product located at commande.quantities[productId][conditionnementId].prix and the price of the product located at catalogue[productId][conditionnementId].prix
                                                                //the margin is the sum of all the margins for each product of the order by quantites (quantities[productId][conditionnementId].quantite)

                                                                Math.round(_.reduce(client.commandes, (result, commande) => {
                                                                    return result + commandeService.getMargeBrute(commande)
                                                                }, 0) / _.reduce(client.commandes, (result, commande) => {
                                                                    return result + commandeService.getVolume(commande, brasserie)
                                                                }, 0)
                                                                    * 100) / 100
                                                            }€/l
                                                        </span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +2.25%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">Livraison €HT</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <Filter className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">
                                                            {//get the margin for each order
                                                                //the margin is the difference between the price of the product located at commande.quantities[productId][conditionnementId].prix and the price of the product located at catalogue[productId][conditionnementId].prix
                                                                //the margin is the sum of all the margins for each product of the order by quantites (quantities[productId][conditionnementId].quantite)

                                                                Math.round(_.reduce(client.commandes, (result, commande) => {
                                                                    return result + commandeService.getPort(commande);
                                                                }, 0) * 100) / 100
                                                            }€
                                                        </span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +2.25%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                            <Col sm="6" lg="12" className="d-flex col-xxl-6">
                                                <Card className="flex-fill">
                                                    <Card.Body>
                                                        <Row>
                                                            <Col className="mt-0">
                                                                <h5 className="card-title">Marge nette €HT/l</h5>
                                                            </Col>

                                                            <Col xs="auto">
                                                                <div className="stat stat-sm">
                                                                    <Filter className="align-middle text-success" />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <span className="h1 d-inline-block mt-1 mb-4">
                                                            {//get the margin for each order
                                                                //the margin is the difference between the price of the product located at commande.quantities[productId][conditionnementId].prix and the price of the product located at catalogue[productId][conditionnementId].prix
                                                                //the margin is the sum of all the margins for each product of the order by quantites (quantities[productId][conditionnementId].quantite)

                                                                Math.round(
                                                                    (_.reduce(client.commandes, (result, commande) => {
                                                                        return result + commandeService.getMargeBrute(commande)
                                                                    }, 0) 
                                                                    - _.reduce(client.commandes, (result, commande) => {
                                                                        return result + commandeService.getPort(commande);
                                                                    }, 0)) 
                                                                    / _.reduce(client.commandes, (result, commande) => {
                                                                            return result + commandeService.getVolume(commande,brasserie)
                                                                    }, 0) 
                                                                * 100) / 100
                                                            }€
                                                        </span>
                                                        {/*  <div className="mb-0">
                                                            <Badge bg="" className="badge-soft-success me-2">
                                                                +2.25%
                                                            </Badge>
                                                            <span className="text-muted">Since last week</span>
                                                        </div> */}
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg="6" xl="7">
                                    <Card>
                                        <Card.Header>
                                            <div className="card-actions float-end">
                                                <Dropdown align="end">
                                                    <Dropdown.Toggle as="a" bsPrefix="-">
                                                        <MoreHorizontal />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item>Action</Dropdown.Item>
                                                        <Dropdown.Item>Another Action</Dropdown.Item>
                                                        <Dropdown.Item>Something else here</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <Card.Title className="mb-0">Ventilation des ventes</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            <VenteRepartition brasserie={brasserie} produits={products} data={client.commandes} initZeroLevel={[{
                                                name: client.livraison.nom,
                                                id: client.id,
                                                color: "#fff",
                                            }]} />
                                        </Card.Body>
                                    </Card>
                                </Col>

                            </Row>
                        </>
                    }
                </Col>
            </Row>

        </>
    );
}
