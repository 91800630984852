import _ from 'lodash';

export class Catalogue {
    constructor(brasserie, products = null, client = null) {
        this.client = client;
        this.products = products;
        this.brasserie = brasserie;
        this.rawProducts = products;
        this.productsCleaned = false;
    }

    //this method will be used to create a new catalogue object for a brasserie

    createCatalogueForBrasserie(brasserie) {
        return new Catalogue(brasserie);
    }

    getRawProducts() {
        return this.rawProducts;
    }
    setRawProducts(rawProducts) {
        this.rawProducts = rawProducts;
    }

    getProducts() {
        if (!this.productsCleaned) {
            this._filterCatalogueForClient()
            this._reorganizeProductsList()
            this._removeEmptyConditionnements()
            this.productsCleaned = true;
        }
        return this.products;
    }

    getProductListOrgannizedByCatalogueName() {
        const catalogues = _.mapValues(this.brasserie.catalogues, () => { return [] })

        this._filterCatalogueForClient()
        for (let index = 0; index < this.rawProducts.length; index++) {
            const produit = this.rawProducts[index];
            for (const stock in produit.stocks) {

                const catalogueName = produit.stocks[stock].catalogue
                if (catalogueName !== '') {
                    if (Object.hasOwnProperty.call(catalogues, catalogueName)) {
                        catalogues[catalogueName].push(produit)
                    } else {
                        catalogues[catalogueName] = [produit]
                    }
                }
            }
        }

        return catalogues;
    }

    getProductById(id) {
        return this.rawProducts.find(product => product.id === id)
    }

    setProducts(products) {
        this.products = products;
    }

    getClient() {
        return this.client;
    }

    setClient(client) {
        this.client = client;
    }

    //get a product list then build a proper catalogue organized by conditionnement and catalogue
    _reorganizeProductsList() {
        const catalogues = _.mapValues(this.brasserie.conditionnements, () => { return _.mapValues(this.brasserie.catalogues, () => { return [] }) })

        for (let index = 0; index < this.rawProducts.length; index++) {
            const produit = this.rawProducts[index];
            for (const stock in produit.stocks) {
                const catalogueName = produit.stocks[stock].catalogue
                const conditionnementId = stock
                if (Object.hasOwnProperty.call(catalogues, conditionnementId)) {
                    if (catalogueName !== "") {
                        if (Object.hasOwnProperty.call(catalogues[conditionnementId], catalogueName)) {
                            catalogues[conditionnementId][catalogueName].push(produit)
                        } else {
                            catalogues[conditionnementId][catalogueName] = [produit]
                        }
                    }
                } else {
                    catalogues[conditionnementId] = [produit]
                }
            }
        }

        /*  //remove empty conditionnements
         for (const conditionnement in catalogues) {
             if (Object.hasOwnProperty.call(catalogues, conditionnement)) {
                 const element = catalogues[conditionnement];
                 let allValuesLengthsAreZero = true
 
                 for (const catalogue in element) {
                     if (Object.hasOwnProperty.call(element, catalogue)) {
                         const elementCatalogue = element[catalogue];
                         if (elementCatalogue.length !== 0) {
                             allValuesLengthsAreZero = false
                         }
                     }
                 }
                 if (allValuesLengthsAreZero) {
                     delete catalogues[conditionnement]
                 }
             }
         } */
        this.products = catalogues;
    }

    //remove stock from products from the catalogue that are not in the client's catalogues list 
    _filterCatalogueForClient() {
        if (this.client) {

            this.rawProducts = this.rawProducts.map(produit => {
                if (produit?.stocks && Object.keys(produit.stocks).length > 0) {
                    Object.keys(produit.stocks).map(stock => {
                        if (this.client.catalogues.indexOf(produit.stocks[stock].catalogue) === -1 || !produit.stocks[stock].visibilite) {
                            delete produit.stocks[stock]
                        }
                    })
                }
                return produit
            }).filter(produit => produit.stocks && Object.keys(produit.stocks).length > 0)
        }
    }

    //remove conditionnements that have no stock available
    _removeEmptyConditionnements() {
        const catalogues = _.cloneDeep(this.products)
        //if all values lengths are 0 for a conditionnement, remove it
        for (const conditionnement in catalogues) {
            if (Object.hasOwnProperty.call(catalogues, conditionnement)) {
                const element = catalogues[conditionnement];
                let allValuesLengthsAreZero = true
                for (const catalogue in element) {
                    if (Object.hasOwnProperty.call(element, catalogue)) {
                        const elementCatalogue = element[catalogue];
                        if (elementCatalogue.length !== 0) {
                            allValuesLengthsAreZero = false
                            const products = _.uniq(elementCatalogue).sort((a, b) => {
                                return a.abv - b.abv
                            })
                            catalogues[conditionnement][catalogue] = products
                        } else {
                            delete catalogues[conditionnement][catalogue]
                        }
                    }
                }
                if (allValuesLengthsAreZero) {
                    delete catalogues[conditionnement]
                }
            }
        }
        this.products = catalogues
    }

    updateCatalogueStocksWithCommande(commande) {
        this.productsCleaned = false;

        const updatedCatalogue = _.cloneDeep(this.rawProducts);
        for (const productId in commande.quantities) {
            for (const conditionnementId in commande.quantities[productId]) {
                const productIndex = _.findIndex(this.rawProducts, { id: productId })
                for (let index = 0; index < commande.quantities[productId][conditionnementId].lots.length; index++) {
                    const numeroDeLot = commande.quantities[productId][conditionnementId].lots[index].numero
                    updatedCatalogue[productIndex].lots[numeroDeLot].stocks[conditionnementId].stock = parseInt(this.rawProducts[productIndex].lots[numeroDeLot].stocks[conditionnementId].stock) + parseInt(commande.quantities[productId][conditionnementId].lots[index].quantite)
                }


                updatedCatalogue[productIndex].stocks[conditionnementId].stock = parseInt(this.rawProducts[productIndex].stocks[conditionnementId].stock) + parseInt(commande.quantities[productId][conditionnementId].quantite)
                if (parseInt(commande.quantities[productId][conditionnementId].quantite) > 0) {

                    updatedCatalogue[productIndex].stocks[conditionnementId].visibilite = true;
                }
            }
        }
        this.rawProducts = updatedCatalogue;
    }


}