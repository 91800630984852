
import React from "react";
import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"

import { useState } from 'react';
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";

import { useSnackbar } from 'notistack';
import _ from "lodash"
import { format, fromUnixTime } from 'date-fns'

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import frLocale from 'date-fns/locale/fr';

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";

import getUnixTime from 'date-fns/getUnixTime'
import productService from "../../../services/product.service";
import StatistiquesStockProduit from "./StatistiquesStockProduit";
import StatistiquesVentesProduit from "./StatistiquesVentesProduit";
import StatistiquesVelociteProduit from "./StatistiquesVelociteProduit";
import InsightsProduit from "./InsightsProduit";

const Navigation = ({ hash }) => {
  return (

    <Card>
      <Card.Header>
        <Card.Title className="mb-0">Paramétrage produit</Card.Title>
      </Card.Header>
      <ListGroup variant="flush">
        <ListGroup.Item tag="a" href="#fiche" action active={(hash === "#fiche" || hash === "") ? true : false}>
          Fiche produit
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#tarifs" action active={(hash === "#tarifs") ? true : false}>
          Tarifs et disponibilités
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#lots" action active={(hash === "#lots") ? true : false}>
          Gestion des lots
        </ListGroup.Item>
        <ListGroup.Item tag="a" href="#statistiques" action active={(hash === "#statistiques") ? true : false}>
         Statistiques
        </ListGroup.Item>
      </ListGroup>
    </Card>
  )
};

export default function EditProduct(props) {

  //navigation hooks
  const { hash } = useLocation();
  const { brasserie } = useOutletContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();



  //form states
  const [id, setId] = useState(props.product?.id || '');
  const [nom, setNom] = useState(props.product?.nom || '');
  const [style, setStyle] = useState(props.product?.style || '');
  const [abv, setAbv] = useState(props.product?.abv || '')
  const [bio, setBio] = useState(props.product?.bio || false)
  const [description, setDescription] = useState(props.product?.description || '');
  const [anecdote, setAnecdote] = useState(props.product?.anecdote || '');
  const [degustation, setDegustation] = useState(props.product?.degustation || '');
  const [malts, setMalts] = useState(props.product?.malts || '');
  const [houblons, setHoublons] = useState(props.product?.houblons || '');
  const [levures, setLevures] = useState(props.product?.levures || '');
  const [autres, setAutres] = useState(props.product?.autres || '');
  const [imgUrl, setImgUrl] = useState(props.product?.image || '');
  const [imgEtiquetteUrl, setImgEtiquetteUrl] = useState(props.product?.etiquette || '');
  const [imgEtiquetteFaceUrl, setImgEtiquetteFaceUrl] = useState(props.product?.etiquetteFace || '');
  /**
   * @todo add loader for file upload
   */
  const [progresspercent, setProgresspercent] = useState(0);
  const [stocks, setStocks] = useState(props.product?.stocks || {})
  const [lots, setLots] = useState(props.product?.lots || [])

  const handleChangeImage = (e, fieldSetter) => {
    const file = e.target.files[0]

    if (!file) return;

    const storageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on("state_changed",
      (snapshot) => {
        const progress =
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setProgresspercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          fieldSetter(downloadURL)
        });
      }
    );
  }

  const updateProductStock = (conditionnementId, stocks) => {
    productService.editStock(brasserie, id, conditionnementId, stocks[conditionnementId]).then(resp => {
      if (resp.status === 200) {
        enqueueSnackbar('Le produit a été mis à jour.', { variant: 'success' })
      } else {
        enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
      }
    })
  }

  const handleSetPrix = (e, conditionnementKey) => {
    const newStock = { ...stocks[conditionnementKey], prix: e.target.value }
    const newStocks = _.cloneDeep(stocks);
    newStocks[conditionnementKey] = newStock;
    setStocks(newStocks)
    /**
     * @todo update firebase sur le bon event
     */

  }

  const handleSetStock = (e, conditionnementKey) => {
    const newStock = { ...stocks[conditionnementKey], stock: e.target.value, stockUpdated: getUnixTime(new Date()) }
    const newStocks = _.cloneDeep(stocks);
    newStocks[conditionnementKey] = newStock;
    setStocks(newStocks)

  }
  const handleSetCatalogue = (e, conditionnementKey) => {
    const newStock = { ...stocks[conditionnementKey], catalogue: e.target.value }
    const newStocks = _.cloneDeep(stocks);
    newStocks[conditionnementKey] = newStock;
    setStocks(newStocks)

    updateProductStock(conditionnementKey, newStocks)
  }
  const handleSetVisibilite = (e, conditionnementKey) => {
    const newStock = { ...stocks[conditionnementKey], visibilite: e.target.checked }
    const newStocks = _.cloneDeep(stocks);
    newStocks[conditionnementKey] = newStock;
    console.log(stocks[conditionnementKey].prix)
    if ((stocks[conditionnementKey].prix && stocks[conditionnementKey].prix !== '') || !e.target.checked) {
      setStocks(newStocks)

      updateProductStock(conditionnementKey, newStocks)
    } else {
      enqueueSnackbar('Vous devez définir un prix pour ce conditionnement avant de le rendre visible', { variant: 'error' })
    }

  }


  //submit event
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      nom: nom,
      style: style,
      abv: Math.round(abv * 100) / 100,
      bio: bio,
      description: description,
      anecdote: anecdote,
      degustation: degustation,
      malts: malts,
      houblons: houblons,
      levures: levures,
      autres: autres,
      image: imgUrl,
      etiquette: imgEtiquetteUrl,
      etiquetteFace: imgEtiquetteFaceUrl,
      id: id
    }
    if (id) {
      data.id = id
      productService.update(brasserie, data).then(resp => {
        if (resp.status === 200) {
          enqueueSnackbar('Le produit a été mis à jour.', { variant: 'success' })
        } else {
          enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
        }
      })
    } else {
      productService.create(brasserie, data).then(resp => {
        if (resp.status === 200) {
          enqueueSnackbar('Le produit a été créé.', { variant: 'success' })
          setId(resp.data.id)
          navigate('/admin/' + brasserie.id + '/produits/' + resp.data.id)
        } else {
          enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
        }
      })
    }
  }

  /**
   * 
   *  Gestion des lots
   * 
   */

  const defaultLot = {
    numero: "",
    dateConditionnement: getUnixTime(new Date()),
    ddm: getUnixTime(new Date()),
    stocks: {}
  }
  const [lot, setLot] = useState(defaultLot)

  const setNumeroDeLot = (numero) => {
    const newLot = _.cloneDeep(lot);
    newLot.numero = numero;
    setLot(newLot)
  }

  const setDateConditionnement = (date) => {
    const newLot = _.cloneDeep(lot);
    newLot.dateConditionnement = getUnixTime(new Date(date));
    setLot(newLot)
  }
  const setDDM = (date) => {
    const newLot = _.cloneDeep(lot);
    newLot.ddm = getUnixTime(new Date(date));
    setLot(newLot)
  }

  const handleSetStockLot = (e, conditionnementKey) => {
    console.log(e.target.value)
    const stock = e.target.value === 0 ? 0 : parseInt(e.target.value)
    console.log(stock)
    const newLot = _.cloneDeep(lot);
    newLot.stocks[conditionnementKey] = {
      stockInitial: stock,
      stock: stock
    }
    setLot(newLot)
  }

  const handleSubmitLot = (e, lot) => {
    e.preventDefault()
    const produit = {
      nom: nom,
      style: style,
      abv: Math.round(abv * 100) / 100,
      bio: bio,
      description: description,
      anecdote: anecdote,
      image: imgUrl,
      etiquette: imgEtiquetteUrl,
      etiquetteFace: imgEtiquetteFaceUrl,
      stocks: stocks,
      id: id
    }
    productService.addLot(brasserie, produit, lot).then(resp => {
      if (resp.status === 200) {
        enqueueSnackbar('Le lot a été ajouté.', { variant: 'success' })
        /**
         * @todo modifier le produit affiché (ou le state stocks) pour refleter l'ajout
         */
        setStocks(resp.data.stocks)
        setLots(resp.data.lots)
        setLot(defaultLot)
      } else {

        enqueueSnackbar('Erreur lors de la création du lot.', { variant: 'error' })
      }
    }).catch(error => {
      if (error.response.data.error.name === "LotError") {
        const lotError = error.response.data.error.data.lot
        enqueueSnackbar("Le numéro de lot " + lotError.numero + " existe déjà pour " + error.response.data.error.data.produit.nom, { variant: 'error' })
      } else {
        enqueueSnackbar(error.message, { variant: 'error' })
      }
    })
  }

  return (<>

<h1 className="h3 mb-3">{nom}</h1>
    <Row>
      <Col md="3" xl="2">
        <Navigation hash={hash} />
      </Col>
      <Col md="9" xl="10">
        {(hash === "#fiche" || hash === "") && <>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">Fiche produit</Card.Title>
            </Card.Header>
            <Card.Body>
              <form onSubmit={(e) => handleSubmit(e)}>
                <fieldset className="row g-2 align-items-center">
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="nom">Nom</label>
                    <input type="text" className="form-control" id="nom" placeholder="Le nom de la bière" required onChange={(e) => setNom(e.target.value)} value={nom} />
                  </div>
                  <div className="col-md-9">
                    <label className="form-label" htmlFor="style">Style</label>
                    <input type="text" className="form-control" id="style" placeholder="Le style de la bière" required onChange={(e) => setStyle(e.target.value)} value={style} />
                  </div>
                  <div className="col-md-3">
                    <label className="form-label" htmlFor="name">% d'alcool</label>
                    <input type="number" className="form-control" id="abv" placeholder="ABV" required onChange={(e) => setAbv(e.target.value)} value={abv} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="description">Description</label>
                    <textarea type="number" className="form-control" id="description" placeholder="Une courte description" required onChange={(e) => setDescription(e.target.value)} value={description} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="anecdote">Anecdote</label>
                    <textarea type="number" className="form-control" id="anecdote" placeholder="Une anecdote à partager" required onChange={(e) => setAnecdote(e.target.value)} value={anecdote} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="degustation">Dégustation</label>
                    <textarea type="number" className="form-control" id="degustation" placeholder="Une note de dégustation" required onChange={(e) => setDegustation(e.target.value)} value={degustation} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="malts">Malts</label>
                    <textarea type="number" className="form-control" id="malts" placeholder="La liste des malts" onChange={(e) => setMalts(e.target.value)} value={malts} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="houblons">Houblons</label>
                    <textarea type="number" className="form-control" id="houblons" placeholder="La liste des houblons" onChange={(e) => setHoublons(e.target.value)} value={houblons} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="levures">Levures</label>
                    <textarea type="number" className="form-control" id="levures" placeholder="La liste des levures" onChange={(e) => setLevures(e.target.value)} value={levures} />
                  </div>
                  <div className="col-md-12">
                    <label className="form-label" htmlFor="autres">Autres</label>
                    <textarea type="number" className="form-control" id="autres" placeholder="La liste des Autres ingrédients" onChange={(e) => setAutres(e.target.value)} value={autres} />
                  </div>
                  <div className="col form-check form-check-inline">
                    <label className="form-label" htmlFor="bio">La bière est bio</label>
                    <input type="checkbox" className="form-check-input" id="bio" onChange={(e) => setBio(!bio)} checked={(bio) ? "checked" : ''} />
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="formFile" className="form-label">Image catalogue</label>
                    <div className="input-group">
                      <input className="form-control" type="file" id="formFile" onChange={(e) => handleChangeImage(e, setImgUrl)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <img alt="" src={imgUrl} className="img-fluid" />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="formFileEtiquette" className="form-label">Etiquette</label>
                    <div className="input-group">
                      <input className="form-control" type="file" id="formFileEtiquette" onChange={(e) => handleChangeImage(e, setImgEtiquetteUrl)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <img alt="" src={imgEtiquetteUrl} className="img-fluid" />
                  </div>

                  <div className="col-md-12">
                    <label htmlFor="formFileEtiquetteFace" className="form-label">Face étiquette</label>
                    <div className="input-group">
                      <input className="form-control" type="file" id="formFileEtiquetteFace" onChange={(e) => handleChangeImage(e, setImgEtiquetteFaceUrl)} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <img alt="" src={imgEtiquetteFaceUrl} className="img-fluid" />
                  </div>
                </fieldset>
                <button type="submit" className="btn btn-primary">{(id) ? 'Modifier le produit' : 'Créer le produit'}</button>
              </form>
            </Card.Body>
          </Card>
        </>
        }
        {(hash === "#tarifs") && <>
          {id !== '' && lots && Object.keys(lots).length > 0
            && <>
            <Row>
              <Col md={12}  >
              <Card>
                <Card.Header>
                  <Card.Title className="mb-0">Catalogues</Card.Title>
                </Card.Header>
                <Card.Body>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Stock Actuel</th>
                        <th scope="col">Coût €HTDI</th>
                        <th scope="col">Catalogue</th>
                        <th scope="col">Visibilité</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(brasserie.conditionnements).map((conditionnementKey) => {
                        return stocks[conditionnementKey]?.stock >= 0 && <tr key={conditionnementKey}>
                          <td>
                            {brasserie.conditionnements[conditionnementKey].nom}
                          </td>
                          <td>
                            <div className="input-group ">
                              <input type="text" className="form-control" id={"stockActuel-" + conditionnementKey} placeholder="Le stock actuel" disabled onChange={(e) => handleSetStock(e, conditionnementKey)} value={(stocks[conditionnementKey]) ? stocks[conditionnementKey].stock : ''} />
                            </div>
                          </td>
                          <td>
                            <div className="input-group ">
                              <input type="number" className="form-control text-end" id={"prix-" + conditionnementKey} placeholder="Le prix d'une unité" onChange={(e) => handleSetPrix(e, conditionnementKey)} value={(stocks[conditionnementKey]) ? stocks[conditionnementKey].prix : ''} />
                            </div>
                          </td>
                          <td>
                            <div className="input-group">
                              <select className="form-select" id={"catalogue-" + conditionnementKey} onChange={(e) => handleSetCatalogue(e, conditionnementKey)} value={(stocks[conditionnementKey]) ? stocks[conditionnementKey].catalogue : ''}>
                                <option key="default" value="-1">Choisir</option>
                                {Object.keys(brasserie.catalogues).map((catalogueKey) => {
                                  return <option key={catalogueKey} value={catalogueKey}>{brasserie.catalogues[catalogueKey].nom}</option>
                                })}
                              </select>
                            </div>
                          </td>
                          <td>
                            <input type="checkbox" className="form-check-input" id={"visible-" + conditionnementKey} onChange={(e) => handleSetVisibilite(e, conditionnementKey)} checked={(stocks[conditionnementKey] && stocks[conditionnementKey].visibilite) ? true : false} />
                          </td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
              </Col>
              <Col md={12} >
              <Card>
                <Card.Header>
                  <Card.Title className="mb-0">Grilles tarifaires HTDI</Card.Title>
                </Card.Header>
                <Card.Body>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        {Object.keys(brasserie.pricings).map((pricingKey) => {
                          return <th scope="col" key={pricingKey}>{brasserie.pricings[pricingKey].nom}</th>
                        })
                        }

                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(brasserie.conditionnements).map((conditionnementKey) => {
                        return stocks[conditionnementKey]?.stock >= 0 && <tr key={conditionnementKey}>
                          <td className="">
                            <strong>{brasserie.conditionnements[conditionnementKey].nom}</strong>
                          </td>
                          {Object.keys(brasserie.pricings).map((pricingKey) => {
                            if (brasserie.pricings[pricingKey].coefs[stocks[conditionnementKey].catalogue]) {
                              return <td key={pricingKey}>{productService.formatPrice(stocks[conditionnementKey].prix, brasserie.pricings[pricingKey].coefs[stocks[conditionnementKey].catalogue][conditionnementKey])}</td>

                            } else {
                              return <td key={pricingKey}>A définir</td>
                            }
                          })
                          }
                        </tr>
                      })
                      }
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
              </Col>
            </Row>
             
              
            </>
          }</>
        }
        {(hash === "#lots") && <>
          {id !== ''
            &&
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">Lots</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className="accordion" id="accordionLot">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Créer un lot
                      </button>
                    </h2>
                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionLot">
                      <div className="accordion-body">
                        <form onSubmit={(e) => handleSubmitLot(e, lot)}>

                          <fieldset className="row g-2 align-items-center">
                            <div className="col-md-12">
                              <label className="form-label" htmlFor="nom">Numéro de lot</label>
                              <input type="text" className="form-control" id="nom" placeholder="Le numéro qui identifie le lot. Ne pourra pas être modifié !" required onChange={(e) => setNumeroDeLot(e.target.value)} value={lot.numero} />
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="style">Date de conditionnement</label>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                <DatePicker
                                  views={['day', 'month', 'year']}
                                  value={lot.dateConditionnement * 1000}
                                  onChange={(newDate) => {
                                    setDateConditionnement(newDate);
                                  }}
                                  renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                              </LocalizationProvider>
                            </div>
                            <div className="col-md-6">
                              <label className="form-label" htmlFor="name">DDM</label>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
                                <DatePicker
                                  views={['day', 'month', 'year']}
                                  value={lot.ddm * 1000}
                                  onChange={(newDate) => {
                                    setDDM(newDate);
                                  }}
                                  renderInput={(params) => <TextField fullWidth size="small" {...params} />}
                                />
                              </LocalizationProvider>
                            </div>
                          </fieldset>

                          <table className="table mt-3">
                            <tbody>
                              {Object.keys(brasserie.conditionnements).map((conditionnementId) => {
                                return <tr key={conditionnementId}>
                                  <td>
                                    {brasserie.conditionnements[conditionnementId].nom}
                                  </td>
                                  <td>
                                    <div className="input-group ">
                                      <input type="text" className="form-control" id={"stockActuel-" + conditionnementId} placeholder={"Le stock de " + brasserie.conditionnements[conditionnementId].nom} onChange={(e) => handleSetStockLot(e, conditionnementId)} value={(lot.stocks[conditionnementId] && lot.stocks[conditionnementId].stockInitial >= 0) ? lot.stocks[conditionnementId].stockInitial : ''} />
                                    </div>
                                  </td>
                                </tr>
                              })}
                            </tbody>
                          </table>
                          <div className="d-grid gap-2 mt-3">
                            <button className="btn btn-primary" type="submit">Créer le lot</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  {
                    Object.keys(lots).map((lotId, index) => {
                      const lot = lots[lotId]
                      return <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#id" + index} aria-expanded="false" aria-controls={"#" + index + lot.numero}>
                            N° de lot : {lot.numero}
                          </button>
                        </h2>
                        <div id={"id" + index} className="accordion-collapse collapse" aria-labelledby={"#" + index + lot.numero} data-bs-parent="#accordionLot">
                          <div className="accordion-body">

                            <table className="table mt-3">
                              <tbody>
                                <tr>
                                  <td>Conditionné le : </td><td>{format(fromUnixTime(lot.dateConditionnement), 'dd/MM/yyyy')}</td>
                                </tr>
                                <tr>
                                  <td>DDM le : </td><td>{format(fromUnixTime(lot.ddm), 'dd/MM/yyyy')}</td>
                                </tr>

                                {Object.keys(lot.stocks).map((conditionnementId, indexStock) => {
                                  return <tr key={indexStock}>

                                    <td>{brasserie.conditionnements[conditionnementId].nom} :</td>
                                    <td>{lot.stocks[conditionnementId].stock}/{lot.stocks[conditionnementId].stockInitial} unités</td>
                                  </tr>


                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    })
                  }

                </div>
              </Card.Body>
            </Card>
          }
        </>
        }
        {(hash === "#statistiques") && <>
        {id !== ''
          && <>
          {<InsightsProduit product={props.product} />}
          {<StatistiquesStockProduit product={props.product} />}
          <StatistiquesVentesProduit productId={id} />
          <StatistiquesVelociteProduit produits={[props.product]} />
          </>
        }
      </>
      }
      </Col>
    </Row>


  </>

  );





}
