import React, { useCallback, useContext, useRef, useState } from "react";

import _ from 'lodash'
import { useSnackbar } from 'notistack';
import commandeService from "../../../services/commande.service";
import { Alert, Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";

import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import Webcam from "react-webcam";
import { useCommande, useCommandeDispatch } from "../../../contexts/CommandeProvider";
import { SessionContext } from "../../../contexts/SessionContext";
import clientService from "../../../services/client.service";
import { useNavigate } from "react-router-dom";
import { Commande } from "../../../model/Commande.model";
import SignatureCanvas from 'react-signature-canvas'

export default function Validation() {

    const dispatch = useCommandeDispatch()
    const { changementStatutLoading, commande } = useCommande();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin

    const handleClick = (e) => {
        e.preventDefault()
        dispatch({ type: 'changementStatutLoading' })

        const oldStatut = commande.statut
        commande.statut = Commande.STATUT_VALIDEE
        const event = {
            timestamp: new Date().getTime(),
            nom: 'Validation',
            source: (isAdmin) ? 'Admin' : session.contact.prenom + ' ' + session.contact.nom.charAt(0),
            context: 'commande'
        };
        commande.addEvent(event)

        commande.update().then(resp => {
            if (resp.data.id) {
                
                clientService.addEvent(commande.brasserie, commande.client, event)
                enqueueSnackbar('La commande est validee.', { variant: 'success' })
                if (isAdmin) {
                } else {
                    navigate('/client/' + session.contact.uuid + '/merci')
                }
            } else {
                commande.statut = oldStatut
                enqueueSnackbar('Un truc n\'a pas marché :(.', { variant: 'error' })
            }

            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })

        }).catch(e => {
            console.log(e)
            commande.statut = oldStatut
            enqueueSnackbar(e.response.data.error, { variant: 'error' })


            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })
        })
    }

    return <>
        <div className="d-flex mb-3">
            <Button variant="success" className="flex-fill" onClick={(e) => handleClick(e)} disabled={changementStatutLoading}>{!changementStatutLoading ? "Valider la commande" : <Spinner />}</Button>
        </div>
    </>

}
