import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
} from 'chart.js';
import { Bar } from "react-chartjs-2";

import { Card, Dropdown } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";
import { useOutletContext } from "react-router-dom";
import productService from "../../../services/product.service";
import { format, sub } from "date-fns";
import Loader from "../../../components/Loader";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Colors
);

const ChartRevenuMarge = ({dataVentes,loadingDataVentes}) => {

  const [statistiques, setStatistiques] = useState({})

  useEffect(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);

    const daysago30 = sub(date, { days: 29 });
    const daysago60 = sub(date, { days: 59 });
      //filter for the last 60 days
      const dataForDateRange = dataVentes.filter((item) => {
        return item.timestamp >= daysago60;
      });


    const data = dataForDateRange.map((item) => {
      return {
        timestamp: item.timestamp,
        week: item.month,
        day: item.day,
        CA: item.CA,
        marge: item.marge,
        volume: item.volume
      }
    })

    //init acc with an object where keys are each days for the past 30 days and values are an object with CA, marge and volume at 0
   
    const accInit = {
      'currentPeriod': {},
      'previousPeriod': {},
    };

    [...new Array(30)].map((i, idx) => {
      
      accInit['currentPeriod'][format(sub(date, { days: 29-idx }), "dd-MMM-yyyy")] = {
        CA: 0,
        marge: 0,
        volume: 0,
      }
      accInit['previousPeriod'][format(sub(date, { days: 59-idx }), "dd-MMM-yyyy")] = {
        CA: 0,
        marge: 0,
        volume: 0,
      }
    })

    const totaux = {
      'currentPeriod': {
        CA: 0,
        marge: 0,
        volume: 0,
      },
      'previousPeriod': {
        CA: 0,
        marge: 0,
        volume: 0,
      }
    }


    const dataByWeek = data.reduce((acc, item) => {
       const period = new Date(item.timestamp).setHours(0,0,0,0) >= daysago30 ? "currentPeriod" : "previousPeriod";
        console.log("period", period,acc)
        console.log("item", format(new Date(item.timestamp).setHours(0,0,0,0),'dd-MMM-yyyy'),item.day,format(daysago30, "dd-MMM-yyyy"),format(daysago60, "dd-MMM-yyyy"))
        totaux[period].CA += item.CA
        totaux[period].marge += item.marge
        totaux[period].volume += item.volume

    

        acc[period][item.day].CA += item.CA
        acc[period][item.day].marge += item.marge
        acc[period][item.day].volume += item.volume
     
      return acc
    }, accInit)

    console.log("totaux"  , dataByWeek)

    setStatistiques(dataByWeek)
  }, [dataVentes])
  

  if(loadingDataVentes && Object.keys(statistiques).length<1) return <Loader/>

  console.log("statistiques", statistiques)
  const data = {
    labels: Object.keys(statistiques['currentPeriod']),
    datasets: [
      {
        label: "CA en €",
        data: Object.values(statistiques['currentPeriod']).map((item) => item.CA),
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      }, {
        label: "Période précédente",
        data: Object.values(statistiques['previousPeriod']).map((item) => item.CA),
        barPercentage: 0.325,
        categoryPercentage: 0.5,
      }
    ],
  };

  const options = {
    maintainAspectRatio: false,
    cornerRadius: 15,
    legend: {
      display: false,
    }, 
    plugins: {
      title: {
        display: false,
        text: 'Chart.js Bar Chart - Stacked',
      },
      colors : {
        enabled: true,
        forceOverride: true,

      }
    },
    responsive: true,
    scales: {
      x: {
        stacked: false,
      },
      y: {
        stacked: false,
      },
    },
   
    
  };

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <div className="card-actions float-end">
          <Dropdown align="end">
            <Dropdown.Toggle as="a" bsPrefix="-">
              <MoreHorizontal />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>Action</Dropdown.Item>
              <Dropdown.Item>Another Action</Dropdown.Item>
              <Dropdown.Item>Something else here</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Card.Title className="mb-0">Evolution des 30 derniers jours</Card.Title>
      </Card.Header>
      <Card.Body className="d-flex">
        <div className="align-self-center w-100">
          <div className="chart chart-lg">
            <Bar data={data} options={options} />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default ChartRevenuMarge;
