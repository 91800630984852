import React, { useEffect } from "react";


import { Link, useOutletContext } from "react-router-dom";

import { Button, Stack } from "@mui/material";


export default function Merci(props) {

  const {client} = useOutletContext()
  

    return (
      <>
      <div className="col-12">
                        <h2>Merci pour votre commande ♡</h2>
                        <p>Nous reviendrons vers vous très rapidement pour une confirmation et organiser la livraison. </p>
                        <p>Si vous avez très envi de nous parler (et maintenant que la partie la plus fastidieuse est faite) vous pouvez nous appeler au : </p>
                        <ul>
                            <li>Céline (local, dordogne, limoges) : 06 49 81 58 13</li>
                            <li>Antoine (le reste) : 06 48 64 18 88</li>
                        </ul>
        </div>
       
      </>
    )
  
}