import React, { useEffect } from "react";

import { Alert, Badge, Button, Card, Spinner } from "react-bootstrap";

import avatar1 from "../../../assets/img/avatars/avatar.jpg";
import avatar4 from "../../../assets/img/avatars/avatar-4.jpg";
import avatar5 from "../../../assets/img/avatars/avatar-5.jpg";
import clientService from "../../../services/client.service";
import { Link, useOutletContext } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faPen, faPlus, faCheck, faPhone, faEnvelope, faUser, faShoppingCart, faWifi, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { formatDistance, formatRelative, toDate } from 'date-fns'
import { fr } from 'date-fns/locale'
import _ from "lodash";
import Loader from "../../../components/Loader";

const pictosEvents = {
  "creation":<FontAwesomeIcon icon={faPlus} className="text-success rounded-circle fs-2 me-2" />,
  "modification":  <FontAwesomeIcon icon={faPen} className="text-warning rounded-circle fs-2 me-2" />,
  "validation": <FontAwesomeIcon icon={faCheck} className="text-success rounded-circle fs-2 me-2" />,
  "connexion": <FontAwesomeIcon icon={faPlus} className="text-success rounded-circle fs-2 me-2" />,
  "communication": <FontAwesomeIcon icon={faPlus} className="text-success rounded-circle fs-2 me-2" />,
  "facture": <FontAwesomeIcon icon={faFileInvoiceDollar} className="text-info rounded-circle fs-2 me-2" />,
  "suppression": <i className="fa-solid fa-user-minus text-danger rounded-circle fs-2 me-2"></i>,
  "commande": <i className="fa-solid fa-shopping-cart text-primary rounded-circle fs-2 me-2"></i>,
  "livraison": <i className="fa-solid fa-truck text-info rounded-circle fs-2 me-2"></i>,
  "paiement": <i className="fa-solid fa-credit-card text-success rounded-circle fs-2 me-2"></i>,
  "annulation": <i className="fa-solid fa-xmark text-danger rounded-circle fs-2 me-2"></i>,
  "retour": <i className="fa-solid fa-undo text-warning rounded-circle fs-2 me-2"></i>,
  "remboursement": <i className="fa-solid fa-credit-card text-success rounded-circle fs-2 me-2"></i>,
  "contacts": <i className="fa-solid fa-user text-primary rounded-circle fs-2 me-2"></i>,

}
const pictosContext = {
  "": faUser,
  "client": faUser,
  "commande": faShoppingCart,
  "contact": faUser,
  "livraison":faUser,
  "statut": faUser,
  "facturation":faUser,
  "grille tarifaire": faUser,
  "tournee": faUser,
  "catalogue": faUser,
  "session": faWifi,
  "mail": faEnvelope,
  "telephone": faPhone,

}

const ErrorState = () => {
  return (
    <Alert variant={"danger"} dismissible>
      <div className="alert-icon">
        <FontAwesomeIcon icon={faBell} fixedWidth />
      </div>
      <div className="alert-message">
        <strong>Erreur!</strong> Un problème est survenue. Contacter Antoine.
      </div>
    </Alert>
  );
};

const Feed = () => {
  const { brasserie } = useOutletContext();
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);
  const [data, setData] = React.useState(null);

  useEffect(() => {
    clientService.getEvents(brasserie.id).then(
      (response) => {
        setData(response.data);
        setIsLoading(false);
      }
    ).catch((error) => {
      setIsError(true);
    })
  }, [])


  if (isError) {
    return <Card className="flex-fill w-100"><ErrorState /></Card>;
  }

  if (isLoading && !data) {
    return <Loader/>;
  }

  return (
    <Card className="flex-fill w-100">
      <Card.Header>
        <Badge bg="info" className="float-end">
          Today
        </Badge>
        <Card.Title className="mb-0">Daily feed</Card.Title>
      </Card.Header>
      <Card.Body className="w-auto overflow-scroll" style={{ "maxHeight": "550px" }}>
        {data.map((event, index) => {
          if (event.source ) {
            console.log(event)
            return <div key={index}><div className="d-flex">
              <FontAwesomeIcon icon={pictosContext[_.lowerCase(event.context)]} className="fs-2 me-2 p-1" style={{width:"25px",color: _.lowerCase(event.source) === 'admin'?"white":"#495057",backgroundColor:_.lowerCase(event.source) !== 'admin'?"white":"#495057"}} />
            
              {pictosEvents[_.lowerCase(event.nom)]}
              <div className="flex-grow-1">
                <small className="float-end text-navy">{formatDistance(toDate(event.timestamp), new Date(), { addSuffix: true, locale: fr })}</small>
                <strong>{event.source}  (  <Link
                  underline="hover"
                  color="inherit"
                  to={"/admin/" + brasserie.id + "/clients/" + event.client.id}
                >
                  {event.client.livraison.nom}
                </Link>)</strong> 
                <br/>
                <strong> {_.upperFirst(event?.context)}</strong> <small className="text-muted">({_.upperFirst(event.nom)})</small>
                <br />
                <small className="text-muted">{formatRelative(toDate(event.timestamp),new Date(),{ weekStartsOn: 1, locale: fr })}</small>
                <br />
              </div>
            </div>
              <hr />
            </div>
          }
        })}
      </Card.Body>
    </Card>
  )
};

export default Feed;
