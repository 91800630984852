
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

import _ from "lodash"
import { useSnackbar } from 'notistack';

import { SketchPicker } from 'react-color';

import LinearProgress from '@mui/material/LinearProgress';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import brasserieService from "../../services/brasserie.service";


export default function Tournees(props) {

    const defaultTournee = {
        franco: 0,
        participation:0,
        couleur: "#ffffff",
        date: null,
        nom: ''
    }

    const { brasserie } = useOutletContext();
    const { enqueueSnackbar } = useSnackbar();

    const [tournee, setTournee] = useState(defaultTournee)
    const [key, setKey] = useState()
    const [displayColorPicker, setDisplayColorPicker] = useState(false)

    const handleColorClick = () => {
        setDisplayColorPicker(!displayColorPicker)
    }

    const handleCloseColorPicker = () => {
        setDisplayColorPicker(false)
    }

    const setNom = (nom) => {
        const newTournee = _.cloneDeep(tournee);
        newTournee.nom = nom;
        setTournee(newTournee)
    }
    const setCouleur = (couleur) => {
        const newTournee = _.cloneDeep(tournee);
        newTournee.couleur = couleur.hex;
        setTournee(newTournee)
    }
    const setDate = (date) => {
        const newTournee = _.cloneDeep(tournee);
        newTournee.date = date.getUnixTime();
        setTournee(newTournee)
    }
    const setFranco = (franco) => {
        const newTournee = _.cloneDeep(tournee);
        newTournee.franco = franco;
        setTournee(newTournee)
    }
    const setParticipation = (participation) => {
        const newTournee = _.cloneDeep(tournee);
        newTournee.participation = participation;
        setTournee(newTournee)
    }

    const editTournee = (tourneeKey) => {
        setTournee(_.cloneDeep(brasserie.tournees[tourneeKey]))
        setKey(tourneeKey)
    }

    const handleSubmitTournee = (e) => {
        e.preventDefault()
        const tournees = brasserie.tournees || {};
        if (key !== null && key !== undefined) {
            tournees[key] = tournee
        } else {
            tournees[_.kebabCase(tournee.nom)] = tournee
        }
        
        brasserie.tournees = tournees
        setTournee(defaultTournee)
        brasserieService.updateTournees(brasserie, tournees).then(resp => {
            if (resp.status === 200) {
                enqueueSnackbar('Liste des tournées mise à jour.', { variant: 'success' })
                setKey(undefined)
            } else {
                enqueueSnackbar('Erreur lors de la mise à jour.', { variant: 'error' })
            }
        }).catch(error => {
            enqueueSnackbar(error.message, { variant: 'error' })
        });
    }


    if (true) {
        console.log(brasserie.tournees)
        return (
            <div className="row">
                <div className="col-sm-12">
                    <form onSubmit={(e) => handleSubmitTournee(e)}>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th scope="col">Nom</th>
                                    <th scope="col">Couleur</th>
                                    <th scope="col">Prochaine tournée</th>
                                    <th scope="col">Franco</th>
                                    <th scope="col">Participation aux frais de port</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (brasserie.tournees && Object.keys(brasserie.tournees).map((tourneeKey) => {
                                        return <tr key={tourneeKey}>
                                            <td>
                                                {brasserie.tournees[tourneeKey].nom}
                                            </td>
                                            <td>
                                                <div>
                                                    <div style={{
                                                        padding: '5px',
                                                        background: '#fff',
                                                        borderRadius: '5px',
                                                        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                                        display: 'inline-block',
                                                        cursor: 'pointer',
                                                    }}>
                                                        <div style={{
                                                            width: '140px',
                                                            height: '28px',
                                                            borderRadius: '2px', backgroundColor: brasserie.tournees[tourneeKey].couleur
                                                        }} />
                                                    </div>


                                                </div>
                                            </td>
                                            <td>
                                                {brasserie.tournees[tourneeKey].date}
                                            </td>
                                            <td>
                                                {brasserie.tournees[tourneeKey].franco}
                                            </td>
                                            <td>
                                                {brasserie.tournees[tourneeKey]?.participation}
                                            </td>
                                            <td>
                                                <button type="button" className="btn btn-primary" onClick={(e) => editTournee(tourneeKey)} >Editer</button>
                                            </td>
                                        </tr>

                                    }))
                                }
                                <tr >
                                    <td>
                                        <input type="text" className="form-control" id="nom" placeholder="Nom" required onChange={(e) => setNom(e.target.value)} value={tournee.nom} />
                                    </td>
                                    <td>
                                        <div>
                                            <div style={{
                                                padding: '5px',
                                                background: '#fff',
                                                borderRadius: '5px',
                                                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                                                display: 'inline-block',
                                                cursor: 'pointer',
                                            }} onClick={handleColorClick}>
                                                <div style={{
                                                    width: '140px',
                                                    height: '28px',
                                                    borderRadius: '2px', backgroundColor: tournee.couleur
                                                }} />
                                            </div>
                                            {displayColorPicker ? <div style={{
                                                position: 'absolute',
                                                zIndex: '2',
                                            }}>
                                                <div style={{
                                                    position: 'fixed',
                                                    top: '0px',
                                                    right: '0px',
                                                    bottom: '0px',
                                                    left: '0px',
                                                }} onClick={handleCloseColorPicker} />
                                                <SketchPicker color={tournee.couleur} onChangeComplete={setCouleur} />
                                            </div> : null}

                                        </div>
                                    </td>
                                    <td>

                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DatePicker
                                                label="Prochaine tournée"
                                                value={tournee.date}
                                                onChange={(newDate) => {
                                                    setDate(newDate);
                                                }}
                                                renderInput={(params) => <TextField size="small" {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" id="nom" placeholder="Minimum de commande" required onChange={(e) => setFranco(parseFloat(e.target.value) || "")} value={tournee.franco} />
                                    </td>
                                    <td>
                                        <input type="text" className="form-control" id="nom" placeholder="Si FRANCO non atteint" required onChange={(e) => setParticipation(parseFloat(e.target.value) || "")} value={tournee.participation} />
                                    </td>

                                    <td>
                                        <button type="submit" className="btn btn-primary" >{(key) ? "Editer la grille" : "Créer la grille"}</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>

                </div>
            </div>

        )
    } else {
        return <LinearProgress />
    }

}