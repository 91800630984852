
import React, { useState } from "react";


import { matchSorter } from "match-sorter";
import _, { set } from "lodash"


import { Link, useLoaderData, useOutletContext } from "react-router-dom";
import DropdownMultiselect from "../../components/MultiSelectCheckbox";
import clientService from "../../services/client.service";

export const loader = async ({ params }) => {
    try {
        let clients;

        const respClients = await clientService.getAll(params.brasserieId);
        clients = respClients.data;

        const newClients = clients.map((client) => {
            client.statut = clientService.STATUTS[client.statut].nom
            return client
        })

        return { clients: newClients }

    } catch {
        throw new Response(null, {
            status: 500,
            statusText: "Internal Server Error",
        });
    }
}

export default function Prospects(props) {

    const { brasserie } = useOutletContext();
    const { clients } = useLoaderData();


    const defaultColumns = [
        {
            nom: "statut",
            path: "statut",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "entreprise",
            path: "facturation.nom",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        },
        {
            nom: "nom",
            path: "contacts.0.nom",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "prénom",
            path: "contacts.0.prenom",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "téléphone",
            path: "contacts.0.telephone",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "mail",
            path: "contacts.0.mail",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "Code Postal",
            path: "livraison.codePostal",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "catalogues",
            path: "catalogues",
            type: "array",
            refPath: "catalogues",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "tarifs",
            path: "pricing",
            type: "ref",
            refPath: "pricings",
            visible: false,
            searchable: true,
            search: ""
        }, {
            nom: "Ville",
            path: "livraison.ville",
            type: "direct",
            visible: true,
            searchable: true,
            search: ""
        }, {
            nom: "tournées",
            path: "tournee",
            type: "ref",
            refPath: "tournees",
            visible: true,
            searchable: true,
            search: ""
        },
    ]
   

    const [clientList, setClientList] = useState(clients)
    const [columns, setColumns] = useState(defaultColumns)

   
    const handleSearch = (searchTerm, index) => {
        const newColumns = _.cloneDeep(columns)
        newColumns[index].search = searchTerm

        let filteredClients = clients;
        for (let i = 0; i < newColumns.length; i++) {
            const terms = newColumns[i].search.split(' ')
            if (!terms || !newColumns[i].search.length) {
            } else {
                // reduceRight will mean sorting is done by score for the _first_ entered word.
                filteredClients = terms.reduceRight(
                    (results, term) => matchSorter(results, term, { keys: [newColumns[i].path] }),
                    filteredClients,)
            }
        }

        setColumns(newColumns)
        setClientList(filteredClients)
    }

    const handleChangeColumnVisibility = (e, index) => {

        const newColumns = _.cloneDeep(columns)
        newColumns[index].visible = !columns[index].visible

        setColumns(newColumns)
    }
    const handleFocusColumnVisibility = (e, index) => {

        const newColumns = _.cloneDeep(columns)
        newColumns[index].visible = true

        setColumns(newColumns)
    }

    return <>
        <div className="row">
            <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Filtres</h1>
                </div>
                <div className="position-sticky pt-3">
                    <ul className="nav flex-column">
                        {columns.map((column, index) => {
                            return (
                                <li className="nav-item" key={index}>

                                    {column.searchable && !column.searchType
                                        && <div className="input-group mb-3 ">
                                            <div className="input-group-text form-switch  ps-5 pe-0">
                                                <input className="form-check-input float-none me-2" role="switch" type="checkbox" checked={(column.visible ? "checked" : "")} id={index} onChange={(e) => handleChangeColumnVisibility(e, index)} />
                                            </div>
                                            <div className="form-floating">
                                                <input type="text" className="form-control" id={index} aria-label="Text input with checkbox" onChange={(e) => handleSearch(e.target.value, index)} onFocus={(e) => handleFocusColumnVisibility(e, index)} value={column.search} />
                                                <label forhtml={index}>{column.nom}</label>
                                            </div>
                                        </div>
                                    }
                                   
                                    {!column.searchable && column.nom}
                                </li>
                            )
                        })}

                    </ul>
                </div>
            </nav>
            <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="h2">Prospects</h1>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        <div className="btn-group me-2">
                            <Link to={"/admin/" + brasserie.id + "/prospects/creer"} className="btn btn-sm btn-outline-primary">
                                Créer un prospect
                            </Link>
                        </div>
                    </div>
                </div>
                <table className="table table-sm">
                    <thead>
                        <tr>
                            {
                                columns.map((column, index) => {
                                    if (column.visible) {
                                        return <th scope="col" key={index}>{_.capitalize(column.nom)}</th>
                                    } else {
                                        return null;
                                    }
                                })
                            }
                            <th scope="col" key="action">Action</th>
                        </tr>
                    </thead>
                    <tbody className="table-group-divider">
                        {clientList.map((client, k) => {
                            return <tr key={client.id}>
                                {columns.map((column, indexC) => {
                                    if (column.visible) {
                                        switch (column.type) {
                                            case "direct":
                                                /** if(column.translate){
                                                    return <td key={indexC}>{column.translate[_.get(client, column.path)].nom}</td>
                                                }*/

                                                return <td key={indexC}>{_.get(client, column.path)}</td>
                                            case "array":
                                                return <td key={indexC}>
                                                    {_.get(client, column.path).length && _.get(client, column.path).map(catalogueId => {
                                                        return <span key={catalogueId} className="badge text-bg-secondary mx-1">{brasserie[column.refPath][catalogueId].nom}</span>
                                                    })}
                                                </td>
                                            case "ref":
                                                return <td key={indexC}>
                                                    {
                                                        brasserie[column.refPath][_.get(client, column.path)]?.nom
                                                    }
                                                </td>

                                            default:
                                                return null;
                                        }
                                    } else {
                                        return null;
                                    }
                                })
                                }
                                <td key="action">
                                    <Link to={"/admin/" + brasserie.id + "/prospects/" + client.id} className="btn btn-primary">
                                        <i className="fa-solid fa-pen-to-square"></i>
                                    </Link>
                                </td>
                            </tr>

                        })
                        }
                    </tbody>
                </table>
            </main>
        </div>
    </>


}