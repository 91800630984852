import React from "react";
import { useOutletContext } from "react-router-dom";

export default function ColoredProductListCell(props) {

   const stock = props.stock

   const { brasserie } = useOutletContext();

   let className = "table-secondary"

   if (parseInt(stock.stock) > 0.8 * stock.stockInitial) {
      className = "table-success"
   } else if (parseInt(stock.stock) > 0.2 * stock.stockInitial && parseInt(stock.stock) < 0.8 * stock.stockInitial) {
      className = "table-warning"
   } else if (parseInt(stock.stock) > 0 && parseInt(stock.stock) < 0.2 * stock.stockInitial) {
      className = "table-danger"
   } else if (parseInt(stock.stock) === 0) {
      className = "table-secondary"
   }

   let catalogueString = ""
   if (stock.catalogue) {
      catalogueString = " (" + brasserie.catalogues[stock.catalogue].nom + ")"
   }

   return (<>
      <td key={stock.id} className={className}>
         {(stock.visibilite) ? stock.stock + catalogueString : <s>{stock.stock + catalogueString}</s>}
      </td>
   </>
   )

}