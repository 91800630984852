import _ from "lodash";
import http from "../http-common";

class ProductDataService {
    
  formatPrice(cout,coef) {
    return Math.round(cout*coef*100)/100
  }

  getCurrentLotForProduct(product,conditionnementId){
    const lots = product.lots;
    const lotsParDateDeConditionnement = Object.values(lots).sort(
        function (a, b) {

            return a.dateConditionnement - b.dateConditionnement
        }
    )
    let lotsTriesFiltres = lotsParDateDeConditionnement.map(lot => {
      if(lot.stocks[conditionnementId]?.stock>0){
        return lot
      }else{
        return null
      }
    })
    lotsTriesFiltres = _.compact(lotsTriesFiltres)
    return lotsTriesFiltres[0]
  }

  async getByBrasserieId(brasserieId) {


    const resp = await http.get('/brasseries/'+brasserieId+'/produits');

    
    return resp;
    
  }
  async getById(brasserieId,produitId) {


    const resp = await http.get('/brasseries/'+brasserieId+'/produits/'+produitId);

    
    return resp;
    
  }

  async create(brasserie,product){
    const resp = await http.post('/brasseries/'+brasserie.id+'/produits',{brasserie:brasserie,product:product});
    return resp;
  }

  async update(brasserie,product){
    const resp = await http.post('/brasseries/'+brasserie.id+'/produits/'+product.id,{brasserie:brasserie,product:product});
    return resp;
  }

  async addLot(brasserie,produit,lot){
    const resp = await http.post('/brasseries/'+brasserie.id+'/produits/'+produit.id+'/lots',{produit:produit,lot:lot});
    return resp;
  }
  async editStock(brasserie,produitId,conditionnementId,stock){
    const resp = await http.put('/brasseries/'+brasserie.id+'/produits/'+produitId+'/stocks/'+conditionnementId,{stock:stock});
    return resp;
  }

  async getStatistiques(brasserieId, productId){
    const resp = await http.get('/brasseries/'+brasserieId+'/produits/'+productId+'/statistiques');
    return resp;
  }
  async getStatistiquesVentes(brasserieId, productId){
    const resp = await http.get('/cron/order-daily');
    return resp;
  }

}

export default new ProductDataService();