import _ from "lodash";
import http from "../http-common";

class EventDataService {

    TYPE_CONTACT = 0
    TYPE_COMMANDE = 1
    TYPE_TOURNEE = 2

    TYPES = ['contact','commande','tournee']

    EVENT = {
        id: '',
        title: '',
        start: null,
        end: null,
        data:{},
        allDay:false,
        type : null,
        desc:''
    }

  
  /**
  * 
  * @param {*} brasserie 
  * @returns null if no client, clients object if found
  */
  getAll(brasserieId) {
    return http.get('/brasseries/' + brasserieId + '/events');
  }

  /**
 * 
 * @param {*} brasserie 
 * @returns null if no client, clients object if found
 */
  getById(brasserieId, eventId) {
    return http.get('/brasseries/' + brasserieId + '/events/' + eventId);
  }

  

    addEvent(brasserie, event) {
      return http.post('/brasseries/' + brasserie.id + '/events', { event: event });
    }
   
}

export default new EventDataService();