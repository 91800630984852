
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsSunburst from 'highcharts/modules/sunburst';
import HighchartsMore from 'highcharts/highcharts-more';
import _ from "lodash";

HighchartsSunburst(Highcharts);
HighchartsMore(Highcharts);

export function createDataSeries(data,brasserie,produits){
    const zeroLevel = [{
        name:brasserie.nom,
        id: brasserie.id,
        color: "#fff",
    }]
    const firstLevel = []
    const secondLevel = []
    const thirdLevel = []
    for (let index = 0; index < data.length; index++) {
        const item = data[index];
        const catalogueName = brasserie.catalogues[item.catalogue]?.nom || "Sans catalogue"
        const catalogueId = item.catalogue || "Sans catalogue"
        const productName = produits.find((produit) => produit.id === item.productId)?.nom || "Sans nom"
        const productId = item.productId+""+catalogueName
        const conditionnementName = brasserie.conditionnements[item.conditionnementId]?.nom || "Sans conditionnement"
        const value = Math.round(item.stock*item.conditionnementVolume*100)/100
        //check if first level exist
        if (!firstLevel.find((catalogue) => catalogue.id === catalogueId)) {
            firstLevel.push({
                name:catalogueName,
                id: catalogueId,
                parent:brasserie.id,
            })
            secondLevel.push({
                name:productName,
                id: productId,
                parent: catalogueId
            })
            thirdLevel.push({
                name: conditionnementName,
                id: item.conditionnementId,
                parent: productId,
                value: value
            })
        }else{
            if(!secondLevel.find((produit) => produit.id === productId)){
                secondLevel.push({
                    name:productName,
                    id: productId,
                    parent: catalogueId
                })
                thirdLevel.push({
                    name: conditionnementName,
                    id: item.conditionnementId,
                    parent: productId,
                    value:value
                })
            }else{
                const thirdLevelItem = thirdLevel.find((conditionnement) => (conditionnement.id === item.conditionnementId && conditionnement.parent === productId ))
                if(!thirdLevelItem){
                    thirdLevel.push({
                        name: conditionnementName,
                        id: item.conditionnementId,
                        parent:productId,
                        value: value
                    })
                }else{
                    thirdLevelItem.value += value
                }
            }
        }
    }
    
    return _.concat(zeroLevel,firstLevel.sort((a,b)=>((a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0)),secondLevel,thirdLevel)
}

export function getOptions(data,brasserie,produits){
    const series = createDataSeries(data,brasserie,produits)
    console.log(series)
    return {
        chart: {
            type: 'sunburst',
            height: '600px'
        },
        title: {
            text: 'Répartition du stock'
        },
        series: [{
            type: 'sunburst',
            data: series,
            name: 'Root',
            allowDrillToNode: true,
            cursor: 'pointer',
            dataLabels: {
                format: '{point.name}',
                filter: {
                    property: 'innerArcLength',
                    operator: '>',
                    value: 16
                },
                rotationMode: 'circular'
            },
            levels: [{
                level: 1,
                levelIsConstant: false,
                dataLabels: {
                    filter: {
                        property: 'outerArcLength',
                        operator: '>',
                        value: 64
                    }
                }
            }, {
                level: 2,
                colorByPoint: true
            },
            {
                level: 3,
                colorVariation: {
                    key: 'brightness',
                    to: -0.5
                }
            }, {
                level: 4,
                colorVariation: {
                    key: 'brightness',
                    to: 0.5
                }
            }]

        }],
        tooltip: {
            headerFormat: '',
            valueDecimals:2,
            pointFormat: '{point.name}: <b>{point.value}l</b>'
        }
    }
}

export function StockRepartition ({ brasserie,produits,data }) {

    const chart = <HighchartsReact highcharts={Highcharts} options={getOptions(data, brasserie, produits)} allowChartUpdate={false} />
    return chart
}