import {Outlet } from "react-router-dom";

function PublicLayout() {

  //form states

  return (
<>
    <div className="">
      <nav className="navbar navbar-dark navbar-expand-lg bg-dark">
        <div className="container-md">
          <a className="navbar-brand" href="/">Le Père l'Amer - Pro </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
             
            </ul>
          </div>
        </div>
      </nav>
      <div key="show-pricing" className="container-md py-4">
        <Outlet></Outlet>
      </div>
    </div>
 <footer className="text-muted py-5 mt-auto">
 <div className="container">
   <p className="float-end mb-1">
     /o/ LPLA \o\
   </p>
   <p className="mb-1">Le Père l'Amer - SAS LPLA, 16 rue bertrand de born, 24800 Thiviers</p>
   <p className="mb-0">Question/Commande - pro@leperelamer.fr</p>
 </div>
</footer>
</>
  );
}

export default PublicLayout;
