import React, { useCallback, useContext, useRef, useState } from "react";

import _ from 'lodash'
import { useSnackbar } from 'notistack';
import commandeService from "../../../services/commande.service";
import { Alert, Button, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";

import { storage } from '../../../firebase';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage"
import Webcam from "react-webcam";
import { useCommande, useCommandeDispatch } from "../../../contexts/CommandeProvider";
import { SessionContext } from "../../../contexts/SessionContext";
import clientService from "../../../services/client.service";
import { useNavigate } from "react-router-dom";
import { Commande } from "../../../model/Commande.model";
import SignatureCanvas from 'react-signature-canvas'
import Annulation from "./Annulation";
import Validation from "./Validation";
import Modification from "./Modification";
import Creation from "./Creation";
import Suppression from "./Suppression";
import Facturation from "./Facturation";
import Livraison from "./Livraison";
import PhotoLivraison from "./PhotoLivraison";


const Signature = () => {
    const styles = () => ({
        container: {
            margin: '0 auto',
            height: '400px'
        },
        pad: {
            width: '100%',
            height: '100%'
        }
    });

    const { commande, client, changementStatutLoading } = useCommande();
    const dispatch = useCommandeDispatch()
    const [show, setShow] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin
    let signaturePad = {}
    const webcamRef = useRef(null);
    const [imgSrc, setImgSrc] = useState(null);


    const handleUpload = (e) => {
        e.preventDefault()
        commande.signature = signaturePad.getTrimmedCanvas().toDataURL('image/png')
        commande.update().then(resp => {
            if (resp.data.id) {
                const event = {
                    timestamp: new Date().getTime(),
                    nom: 'signature',
                    source: 'Admin',
                    context: 'commande'
                };
                clientService.addEvent(commande.brasserie, commande.client, event)
                if (commande.events) {
                    commande.events.push(event)
                } else {
                    commande.events = [event]
                }
                enqueueSnackbar('La signature est ajoutée.', { variant: 'success' })
            } else {
                enqueueSnackbar('Un truc n\'a pas marché :(.', { variant: 'error' })
            }
            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })

        }).catch(e => {
            console.log(e)
            enqueueSnackbar(e.response.data.error, { variant: 'error' })

            dispatch({ type: 'changementStatutLoaded', payload: { commande: commande } })
        })
    }

    return <>
        <div className="d-flex mb-3">
            <Button variant="warning" className="flex-fill" onClick={(e) => setShow(!show)} disabled={changementStatutLoading}>{!changementStatutLoading ? "Signature client" : <Spinner />}</Button>

            <Modal show={show} onHide={(e) => setShow(false)} className={"modal-colored modal-warning"} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter la signature du client</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center m-3">
                    <p className="mb-2 text-white">
                        Livraison/retrait en main propre : demander une signature au client.
                    </p>
                    <p></p>
                    <div style={{ background: '#FFFFFF' }} className="signaturePadContainer"><SignatureCanvas penColor='black' canvasProps={{ className: "signaturePad" }} ref={(ref) => { signaturePad = ref }} /></div>


                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={(e) => setShow(false)}>Fermer</Button>
                    <Button variant="light" onClick={(e) => handleUpload(e)}>Ajouter la signature</Button>
                </Modal.Footer>

            </Modal>
        </div>
    </>
}


export default function ChangerStatut() {


    const { commande, client } = useCommande();

    const { session } = useContext(SessionContext)
    const isAdmin = session.isAdmin
    const statutActuel = commande.statut
    const isClientCompleted = clientService.isClientIncomplet(client)


    console.log("statutActuel", statutActuel)


    switch (statutActuel) {
        case Commande.STATUT_EN_CREATION:
            return <>
                {(isAdmin || (!isAdmin && isClientCompleted)) &&
                    <Creation />}
            </>
        case Commande.STATUT_OUVERTE:
            return <>
                {(isAdmin || (!isAdmin && isClientCompleted)) &&
                    <Modification />}
                {isAdmin && isClientCompleted && <Validation />}
                {isAdmin && !isClientCompleted && <Validation />}
                <Annulation />
            </>
        case Commande.STATUT_MODIFIEE:
            return <>
                {(isAdmin || (!isAdmin && isClientCompleted)) && <Modification />}
                {isAdmin && isClientCompleted && <Validation />}
                {isAdmin && !isClientCompleted && <Validation />}
                <Annulation />
            </>

        case Commande.STATUT_VALIDEE:

            return <>

                {commande.photoLivraison && <img src={commande.photoLivraison} alt="img" />}
                {commande.signature && <img src={commande.signature} alt="img" />}
                {isAdmin &&
                    <>
                        {!commande.photoLivraison && <PhotoLivraison />}
                        {!commande.signature && <Signature />}
                        <Livraison />

                        <Annulation />
                    </>}
            </>

        case Commande.STATUT_EXPEDIEE:

            return <>
                {commande.photoLivraison && <img src={commande.photoLivraison} alt="img" />}
                {commande.signature && <img src={commande.signature} alt="img" />}
                {isAdmin && <>
                    {commande.facture && <Button >Supprimer la facture</Button>}
                    {!commande.facture && <Facturation />}
                </>}
            </>

        case Commande.STATUT_FACTUREE:

            return <> 
            {commande.photoLivraison && <img src={commande.photoLivraison} alt="img" />}
            {commande.signature && <img src={commande.signature} alt="img" />}
            {isAdmin && (<> 
               
                <Annulation /></>)
            }
            </>

        case Commande.STATUT_ANNULEE:

            return <> {isAdmin && <>
                <Suppression />
            </>}
            </>

        default:
            return <>

            </>
    }



}